import { PettyCashDifferenceByPaymentType } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface PettyCashDifferenceByPaymentTypeDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: PettyCashDifferenceByPaymentTypeDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<
    PettyCashDifferenceByPaymentType,
    PettyCashDifferenceByPaymentTypeDataGridContext
  >(initialContext, locale);

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
  });

  handler.addColumn({
    accessor: 'transferId',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.transfer_id'),
  });

  handler.addColumn({
    accessor: 'transferTypeId',
    type: 'string',
    title: ({ t }) => t('petty-cash-difference.columns.transfer_type_id'),
  });

  handler.addColumn({
    accessor: 'transferTime',
    type: 'string',
    title: ({ t }) => t('petty-cash-difference.columns.transfer_time'),
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'number',
    title: ({ t }) => t('table.columns.pos_num'),
  });

  handler.addColumn({
    accessor: 'shiftNum',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.shift_num'),
  });

  handler.addColumn({
    accessor: 'userId',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.user_id'),
  });

  handler.addColumn({
    accessor: 'username',
    type: 'string',
    title: ({ t }) => t('petty-cash-difference.columns.username'),
  });

  handler.addColumn({
    accessor: 'employeeId',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.employee_id'),
  });

  handler.addColumn({
    accessor: 'employeeName',
    type: 'string',
    title: ({ t }) => t('petty-cash-difference.columns.employee_name'),
  });

  handler.addColumn({
    accessor: 'totalAmt',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.total_amt'),
  });

  handler.addColumn({
    accessor: 'tenderName',
    type: 'string',
    title: ({ t }) => t('table.columns.tender_name'),
  });

  handler.addColumn({
    accessor: 'amount',
    type: 'number',
    title: ({ t }) => t('table.columns.amount'),
  });

  handler.addColumn({
    accessor: 'foreignAmount',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.foreign_amount'),
  });

  handler.addColumn({
    accessor: 'fcRate',
    type: 'number',
    title: ({ t }) => t('petty-cash-difference.columns.fc_rate'),
  });

  return handler;
};
