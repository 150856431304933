import Grid from '@mui/material/Grid';

import { reportGcsPaymentTypesLoadingSelector, findGcsByPaymentTypesRequested } from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  CountryFilter,
  HourRangeFilter,
  PosFilter,
  SegmentsFilter,
  StoreFilter,
  BusinessDateRangeFilter,
  OperationTypeFilter,
  PaymentTypesFilter,
  AccountingDateRangeFilter,
  OperationDateRangeFilter,
} from './ReportFilters';

import { AppPermissions } from '../types';
import { PermissionsProvider } from './Permissions';

const dialogFilters = (
  <>
    <OperationDateRangeFilter />
    <AccountingDateRangeFilter />
  </>
);

const onedriveAction = () => findGcsByPaymentTypesRequested(true);

const GcsPaymentTypesSearch = () => (
  <ReportFilters
    loadingSelector={reportGcsPaymentTypesLoadingSelector}
    applyAction={findGcsByPaymentTypesRequested}
    onedriveAction={onedriveAction}
    dialogFilters={dialogFilters}
    dialogFiltersApplied={[
      'startOperationDate',
      'endOperationDate',
      'startAccountingDate',
      'endAccountingDate',
    ]}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.GCS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <OperationTypeFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <PaymentTypesFilter labelTranslationId="table.columns.tender_name" />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default GcsPaymentTypesSearch;
