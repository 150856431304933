import { CircularProgress } from '@mui/material';
import { RegisteredStores, RemoteResource } from '../../../../../types';

interface RegisteredStoresCellProps {
  country: string;
  registeredStores: RemoteResource<RegisteredStores[]>;
}

const RegisteredStoresCell = ({ country, registeredStores }: RegisteredStoresCellProps) => (
  <div>
    {!registeredStores.loading && registeredStores.data.length === 0 && <div>0%</div>}
    {!registeredStores.loading && registeredStores.data.length > 0 && (
      <div>{registeredStores.data.filter(i => i.country === country)[0].stores}%</div>
    )}
    {registeredStores.loading && <CircularProgress size={15} />}
  </div>
);

export default RegisteredStoresCell;
