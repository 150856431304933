import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TableToolbar from './TableToolbar';

interface TableFlexSalesProps {
  title: string;
  children: ReactNode;
  loading?: boolean;
  error?: boolean;
  totalRows?: number;
  actions?: ReactNode;
}

const TableStyled = styled(Table)<{ hidebody?: number }>`
  ${props =>
    props.hidebody &&
    `
  tbody {
    display: none;
  }
  `}
`;

const OverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
`;

export const TableBodyLoading = () => (
  <OverlayContainer>
    <CircularProgress />
  </OverlayContainer>
);
export const TableBodyError = () => {
  const { t } = useTranslation();
  return (
    <OverlayContainer>
      <Typography>{t('components.tableFlexSales.error')}</Typography>
    </OverlayContainer>
  );
};

export const TableBodyEmpty = () => {
  const { t } = useTranslation();

  return (
    <OverlayContainer>
      <Typography>{t('components.tableFlexSales.noRows')}</Typography>
    </OverlayContainer>
  );
};

const TableFlexSales = ({
  title,
  children,
  loading,
  error,
  actions,
  totalRows,
}: TableFlexSalesProps) => {
  const hideBody = Number(Boolean(loading || error));
  return (
    <>
      <TableToolbar title={title} actions={actions} />
      <TableContainer>
        <TableStyled hidebody={hideBody} size="small">
          {children}
        </TableStyled>
        {!loading && error && <TableBodyError />}
        {!loading && !error && totalRows === 0 && <TableBodyEmpty />}
        {loading && <TableBodyLoading />}
      </TableContainer>
    </>
  );
};

export default TableFlexSales;
