import { select, takeLatest } from 'redux-saga/effects';
import { ActionType, State } from './types';
import { AppStorage } from '../../services';
import { preferencesSelector } from '../selectors';

function* changePreferences() {
  const preferences: State = yield select(preferencesSelector);
  AppStorage.setUserPreferences(preferences);
}

export default [
  takeLatest(ActionType.changeTheme, changePreferences),
  takeLatest(ActionType.changeShowSaveDialog, changePreferences),
];
