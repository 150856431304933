import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../config/routes';

interface PettyCashDifferenceSwitchProps {
  checked?: boolean;
}

const PettyCashDifferenceSwitch = ({ checked }: PettyCashDifferenceSwitchProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () =>
    navigate(
      checked
        ? routes.protectedRoutes.pettyCashDifference.path
        : routes.protectedRoutes.PettyCashDifferenceByPaymentType.path
    );
  return (
    <FormGroup>
      <FormControlLabel
        onClick={handleClick}
        control={<Switch checked={checked} />}
        label={t('petty-cash-difference-by-payment-type.title') as string}
      />
    </FormGroup>
  );
};

export default PettyCashDifferenceSwitch;
