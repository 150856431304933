import styled from '@emotion/styled';
import { OakBrookSearch, OakBrookDataGrid } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const OakBrook = () => (
  <Container>
    <OakBrookSearch />
    <OakBrookDataGrid />
  </Container>
);

export default OakBrook;
