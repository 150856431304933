import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { MastersStatusDataGridContext } from './handlerFactory';
import { MasterStatusDetail, RemoteResource } from '../../types';

interface DataGridConfigProps {
  resource: RemoteResource<MasterStatusDetail[]>;
}

const DataGridConfig = ({ resource }: DataGridConfigProps) => {
  const { handler } = useDataGridFlexSales<MasterStatusDetail, MastersStatusDataGridContext>();

  const { t } = useTranslation();

  const { data, loading } = resource;

  const gridContext = useMemo(
    () => ({
      t,
    }),
    [t]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setLoading(loading);

  return null;
};

const MastersStatusDataGrid = ({ resource }: DataGridConfigProps) => (
  <DataGridFlexSales handlerFactory={handlerFactory} hideToolbar>
    <DataGridConfig resource={resource} />
  </DataGridFlexSales>
);

export default MastersStatusDataGrid;
