import { AxiosResponse } from 'axios';
import { addMinutes } from 'date-fns';
import { all, call, put, PutEffect, select, takeLatest } from 'redux-saga/effects';
import { Dashboards } from '../../components';
import {
  findClosedMonthsRequestMap,
  findClosingStatusRequestMap,
  findClosingStatusResponseMap,
  findDailySalesRequestMap,
  findDailySalesResponseMap,
  findDelayedStoresRequestMap,
  findDelayedStoresResponseMap,
  findMastersStatusDetailResponseMap,
  findMastersStatusRequestMap,
  findMastersStatusResponseMap,
  findMonthClosingResponseMap,
  findMonthlySalesRequestMap,
  findMonthlySalesResponseMap,
  findRegisteredStoresResponseMap,
  getCountriesForPermissions,
  handleClosingDashboardViewMode,
  hasPermissions,
} from '../../helpers';
import { AppStorage, CollectionsApi, SalesApi } from '../../services';
import {
  FindDailySalesResponse,
  FindDelayedStoresResponse,
  FindMastersStatusDetailsResponse,
  FindMastersStatusResponse,
  FindMonthClosingResponse,
  FindMonthlySalesResponse,
} from '../../services/SalesApi';
import {
  AppParameters,
  AppPermissions,
  ClosingDashboardViewMode,
  FindClosingStatusResponse,
  HttpRequestError,
  Permission,
} from '../../types';
import {
  activeCountriesSelector,
  dashboardClosedMonthsAccountingMonthSelector,
  dashboardCollectionsViewModeSelector,
  dashboardMonthlySalesMonthSelector,
  dashboardSalesViewModeSelector,
  filterParametersDataSelector,
  userPermissionsDataSelector,
} from '../selectors';
import {
  findClosedMonthsFailure,
  findClosedMonthsSuccess,
  findClosingCollectionsStatusFailure,
  findClosingCollectionsStatusSuccess,
  findClosingSalesStatusFailure,
  findClosingSalesStatusSuccess,
  findDailySalesFailure,
  findDailySalesSuccess,
  findDelayedStoresFailure,
  findDelayedStoresRequested,
  findDelayedStoresSuccess,
  findMastersStatus2Failure,
  findMastersStatus2Success,
  findMastersStatusFailure,
  findMastersStatusMastersStatusDetailsFailure,
  findMastersStatusMastersStatusDetailsSuccess,
  findMastersStatusSuccess,
  findMonthlySalesFailure,
  findMonthlySalesSuccess,
  findRegisteredStoresFailure,
  findRegisteredStoresRequested,
  findRegisteredStoresSuccess,
} from './actionCreators';
import { ActionType, FindMastersStatusMastersStatusDetailsRequestedAction } from './types';

function* findClosingSalesStatus() {
  try {
    const viewMode: ClosingDashboardViewMode = yield select(dashboardSalesViewModeSelector);
    const storagedData = AppStorage.getDashboardClosingStatusData('sales', viewMode);
    if (!storagedData) {
      const appParameters: AppParameters | undefined = yield select(filterParametersDataSelector);
      const permissions: Permission[] = yield select(userPermissionsDataSelector);
      const { data: countries } = yield select(activeCountriesSelector);
      const authorizedCountries = getCountriesForPermissions(
        [AppPermissions.DASHBOARD_MISSINGS],
        permissions,
        countries
      ).map(i => i?.countryCode);
      if (!authorizedCountries) return;

      const { from, to } = handleClosingDashboardViewMode(viewMode);

      const { data: response }: AxiosResponse<FindClosingStatusResponse> = yield call(
        SalesApi.findClosingSalesStatus,
        findClosingStatusRequestMap(from, to, authorizedCountries)
      );

      const data = findClosingStatusResponseMap(response);

      yield put(findClosingSalesStatusSuccess(data));

      AppStorage.setDashboardClosingStatusData(
        'sales',
        viewMode,
        data,
        addMinutes(new Date(), appParameters?.dashboardTimer || 10)
      );
    } else {
      yield put(findClosingSalesStatusSuccess(storagedData));
    }
  } catch (error) {
    yield put(findClosingSalesStatusFailure(error as HttpRequestError));
  }
}

function* findClosingCollectionsStatus() {
  try {
    const viewMode: ClosingDashboardViewMode = yield select(dashboardCollectionsViewModeSelector);
    const storagedData = AppStorage.getDashboardClosingStatusData('collections', viewMode);
    if (!storagedData) {
      const appParameters: AppParameters | undefined = yield select(filterParametersDataSelector);
      const permissions: Permission[] = yield select(userPermissionsDataSelector);
      const { data: countries } = yield select(activeCountriesSelector);
      const authorizedCountries = getCountriesForPermissions(
        [AppPermissions.DASHBOARD_MISSINGS],
        permissions,
        countries
      ).map(i => i?.countryCode);
      if (!authorizedCountries) return;

      const { from, to } = handleClosingDashboardViewMode(viewMode);

      const { data: response }: AxiosResponse<FindClosingStatusResponse> = yield call(
        CollectionsApi.findClosingCollectionsStatus,
        findClosingStatusRequestMap(from, to, authorizedCountries)
      );

      const data = findClosingStatusResponseMap(response);

      yield put(findClosingCollectionsStatusSuccess(data));

      AppStorage.setDashboardClosingStatusData(
        'collections',
        viewMode,
        data,
        addMinutes(new Date(), appParameters?.dashboardTimer || 10)
      );
    } else {
      yield put(findClosingCollectionsStatusSuccess(storagedData));
    }
  } catch (error) {
    yield put(findClosingCollectionsStatusFailure(error as HttpRequestError));
  }
}

function* findClosedMonths() {
  try {
    const accountingDate: Date = yield select(dashboardClosedMonthsAccountingMonthSelector);
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const { data: countries } = yield select(activeCountriesSelector);
    const authorizedCountries = getCountriesForPermissions(
      [AppPermissions.DASHBOARD_MISSINGS],
      permissions,
      countries
    ).map(i => i?.countryCode);
    if (!authorizedCountries) return;
    const appParameters: AppParameters | undefined = yield select(filterParametersDataSelector);
    const closedMonths = AppStorage.getDashboardClosedMonthsData(accountingDate);

    if (!closedMonths) {
      const { data }: AxiosResponse<FindMonthClosingResponse> = yield call(
        SalesApi.findClosedMonth,
        findClosedMonthsRequestMap(authorizedCountries, accountingDate)
      );

      yield put(findClosedMonthsSuccess(findMonthClosingResponseMap(data)));

      AppStorage.setDashboardClosedMonthsData(
        accountingDate,
        data,
        addMinutes(new Date(), appParameters?.dashboardTimer || 10)
      );
    } else {
      yield put(findClosedMonthsSuccess(findMonthClosingResponseMap(closedMonths)));
    }
  } catch (error) {
    yield put(findClosedMonthsFailure(error as HttpRequestError));
  }
}

function* findRegisteredStores() {
  try {
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const { data: countries } = yield select(activeCountriesSelector);
    const authorizedCountries = getCountriesForPermissions(
      [AppPermissions.DASHBOARD_MISSINGS],
      permissions,
      countries
    ).map(i => i?.countryCode);
    if (!authorizedCountries) return;
    const month: Date = yield select(dashboardMonthlySalesMonthSelector);
    const fromDate = new Date(month);
    fromDate.setDate(1);

    const toDate =
      new Date(month).getMonth() === new Date().getMonth()
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date(month.getFullYear(), month.getMonth() + 1, 0);

    const { data }: AxiosResponse<FindClosingStatusResponse> = yield call(
      SalesApi.findClosingSalesStatus,
      findClosingStatusRequestMap(fromDate, toDate, authorizedCountries)
    );

    yield put(findRegisteredStoresSuccess(findRegisteredStoresResponseMap(data)));
  } catch (error) {
    yield put(findRegisteredStoresFailure(error as HttpRequestError));
  }
}

function* findMonthlySales() {
  try {
    yield put(findRegisteredStoresRequested());
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const { data: countries } = yield select(activeCountriesSelector);
    const authorizedCountries = getCountriesForPermissions(
      [AppPermissions.DASHBOARD_MISSINGS],
      permissions,
      countries
    ).map(i => i?.countryCode);
    if (!authorizedCountries) return;
    const appParameters: AppParameters | undefined = yield select(filterParametersDataSelector);
    const month: Date = yield select(dashboardMonthlySalesMonthSelector);
    const fromDate = new Date(month);
    fromDate.setDate(1);
    const toDate =
      new Date(month).getMonth() === new Date().getMonth()
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date(month.getFullYear(), month.getMonth() + 1, 0);

    const storagedData = AppStorage.getDashboardMonthlySalesData(month);
    if (!storagedData) {
      const { data }: AxiosResponse<FindMonthlySalesResponse> = yield call(
        SalesApi.findSales,
        findMonthlySalesRequestMap(authorizedCountries, fromDate, toDate)
      );

      yield put(findMonthlySalesSuccess(findMonthlySalesResponseMap(data)));

      AppStorage.setDashboardMonthlySalesData(
        month,
        data,
        addMinutes(new Date(), appParameters?.dashboardTimer || 10)
      );
    } else {
      yield put(findMonthlySalesSuccess(findMonthlySalesResponseMap(storagedData)));
    }
  } catch (error) {
    yield put(findMonthlySalesFailure(error as HttpRequestError));
  }
}

function* findDelayedStores() {
  try {
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const { data: countries } = yield select(activeCountriesSelector);
    const authorizedCountries = getCountriesForPermissions(
      [AppPermissions.DASHBOARD_MISSINGS],
      permissions,
      countries
    ).map(i => i?.countryCode);
    if (!authorizedCountries) return;

    const { data }: AxiosResponse<FindDelayedStoresResponse> = yield call(
      SalesApi.findStoresLastSale,
      findDelayedStoresRequestMap(authorizedCountries)
    );

    yield put(findDelayedStoresSuccess(findDelayedStoresResponseMap(data)));
  } catch (error) {
    yield put(findDelayedStoresFailure(error as HttpRequestError));
  }
}

function* findDailySales() {
  try {
    yield put(findDelayedStoresRequested());
    const permissions: Permission[] = yield select(userPermissionsDataSelector);
    const { data: countries } = yield select(activeCountriesSelector);
    const authorizedCountries = getCountriesForPermissions(
      [AppPermissions.DASHBOARD_MISSINGS],
      permissions,
      countries
    ).map(i => i?.countryCode);
    if (!authorizedCountries) return;

    const storagedData = AppStorage.getDashboardDailySalesData();

    if (!storagedData) {
      const appParameters: AppParameters | undefined = yield select(filterParametersDataSelector);
      const { data }: AxiosResponse<FindDailySalesResponse> = yield call(
        SalesApi.findDailySales,
        findDailySalesRequestMap(authorizedCountries)
      );
      yield put(findDailySalesSuccess(findDailySalesResponseMap(data)));

      AppStorage.setDashboardDailySalesData(
        data,
        addMinutes(new Date(), appParameters?.dashboardTimer || 10)
      );
    } else {
      yield put(findDailySalesSuccess(findDailySalesResponseMap(storagedData)));
    }
  } catch (error) {
    yield put(findDailySalesFailure(error as HttpRequestError));
  }
}

function* findAll() {
  const permissions: Permission[] = yield select(userPermissionsDataSelector);
  const requestsMap: { [key: string]: PutEffect } = {};

  AppStorage.removeDashboardData();

  Dashboards.forEach(dashboard => {
    if (
      hasPermissions(
        permissions.map(i => i.permission),
        dashboard.permissions
      )
    ) {
      requestsMap[dashboard.name] = dashboard.request as PutEffect;
    }
  });

  const requestsCall = Object.keys(requestsMap).map(key => requestsMap[key]);

  yield all(requestsCall);
}

function* findMastersStatusDetails({ date }: FindMastersStatusMastersStatusDetailsRequestedAction) {
  try {
    const { data }: AxiosResponse<FindMastersStatusDetailsResponse> = yield call(
      SalesApi.findMastersStatusDetails,
      findMastersStatusRequestMap(date)
    );
    yield put(
      findMastersStatusMastersStatusDetailsSuccess(findMastersStatusDetailResponseMap(data))
    );
  } catch (error) {
    yield put(findMastersStatusMastersStatusDetailsFailure(error as HttpRequestError));
  }
}

function* findMastersStatus() {
  try {
    const { data }: AxiosResponse<FindMastersStatusResponse> = yield call(
      SalesApi.findMastersStatus
    );
    yield put(findMastersStatusSuccess(findMastersStatusResponseMap(data)));
  } catch (error) {
    yield put(findMastersStatusFailure(error as HttpRequestError));
  }
}

function* findMastersStatus2() {
  try {
    const { data }: AxiosResponse<FindMastersStatusResponse> = yield call(
      SalesApi.findMastersStatus2
    );
    yield put(findMastersStatus2Success(findMastersStatusResponseMap(data)));
  } catch (error) {
    yield put(findMastersStatus2Failure(error as HttpRequestError));
  }
}

export default [
  takeLatest(
    [ActionType.findClosingSalesStatusRequested, ActionType.changeSalesViewMode],
    findClosingSalesStatus
  ),
  takeLatest(
    [ActionType.findClosingCollectionsStatusRequested, ActionType.changeCollectionsViewMode],
    findClosingCollectionsStatus
  ),
  takeLatest(ActionType.findClosedMonthsRequested, findClosedMonths),
  takeLatest(ActionType.findMonthlySalesRequested, findMonthlySales),
  takeLatest(ActionType.findRegisteredStoresRequested, findRegisteredStores),
  takeLatest(ActionType.findDailySalesRequested, findDailySales),
  takeLatest(ActionType.findDelayedStoresRequested, findDelayedStores),
  takeLatest(ActionType.findAllRequest, findAll),
  takeLatest(ActionType.findMastersStatusMastersStatusDetailsRequested, findMastersStatusDetails),
  takeLatest(ActionType.findMastersStatusRequested, findMastersStatus),
  takeLatest(ActionType.findMastersStatus2Requested, findMastersStatus2),
];
