import { Action } from 'redux';

export interface State {
  isModeModal: boolean;
  isSidebarOpen: boolean;
}

export enum ActionType {
  modeModal = 'layout/modeModal',
  toggleSidebar = 'layout/toggleSidebar',
}

export type ModeModalAction = Action<ActionType.modeModal> & {
  isModeModal: boolean;
};

export type ToggleSidebarAction = Action<ActionType.toggleSidebar>;

export type Actions = ModeModalAction | ToggleSidebarAction;
