import { ReactNode } from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogFlexSales from './DialogFlexSales';

const DialogContentStyled = styled(DialogContent)<{ width?: string }>`
  ${({ width }) =>
    width &&
    `
  tbody {
    width: ${width};
  }
  `}
`;

const LoadingContainer = styled(DialogContent)`
  display: flex;
  justify-content: center;
`;

interface DialogFormProps {
  children: ReactNode;
  title: string;
  notFound?: boolean;
  loading?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  width?: string;
  fullScreen?: boolean;
}

// TODO: agrega lógica de Not Found
const DialogForm = ({
  children,
  title,
  notFound,
  loading,
  width,
  maxWidth,
  fullScreen,
}: DialogFormProps) => (
  <DialogFlexSales open maxWidth={maxWidth} fullScreen={fullScreen}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContentStyled width={width}>
      {!notFound && !loading && children}
      {loading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
    </DialogContentStyled>
  </DialogFlexSales>
);

export default DialogForm;
