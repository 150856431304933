import { Action } from 'redux';
import { HttpRequestError, ValidationError } from '../../types';

export interface State {
  httpError?: HttpRequestError;
  validationErrors?: ValidationError[];
}

export enum ActionType {
  httpError = 'error/httpError',
  clearHttpError = 'error/clearHttpError',
  validationErrors = 'error/validationErrors',
  clearValidationErrors = 'error/clearValidationErrors',
}

export type HttpErrorAction = Action<ActionType.httpError> & {
  httpError: HttpRequestError;
};

export type ClearHttpErrorAction = Action<ActionType.clearHttpError>;

export type ValidationErrorsAction = Action<ActionType.validationErrors> & {
  errors: ValidationError[];
};

export type ClearValidationErrorsAction = Action<ActionType.clearValidationErrors>;

export type Actions =
  | HttpErrorAction
  | ClearHttpErrorAction
  | ValidationErrorsAction
  | ClearValidationErrorsAction;
