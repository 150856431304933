import CountrySimpleSelect, { CountrySimpleSelectProps } from './CountrySimpleSelect';
import CountryMultiSelect, { CountryMultiSelectProps } from './CountryMultiSelect';

type CountrySelectProps =
  | (CountryMultiSelectProps & { simple?: false })
  | (CountrySimpleSelectProps & { simple: true });

const CountrySelect = (props: CountrySelectProps) => {
  const { simple, ...others } = props;
  if (simple) {
    return <CountrySimpleSelect {...(others as CountrySimpleSelectProps)} />;
  }
  return <CountryMultiSelect {...(others as CountryMultiSelectProps)} />;
};

export default CountrySelect;
