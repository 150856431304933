import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Company } from '../types';

const companiesSort = (a: Company, b: Company) => (a.companyName > b.companyName ? 1 : -1);

const useCompany = (countryCode: string) => {
  const masterData = useSelector(masterDataSelector);

  const companiesForCountry = useMemo(() => {
    const companies = masterData.loaded && masterData.data ? masterData.data.companies : [];
    return countryCode
      ? companies
          .filter(i => i.countries.some(j => j.countryCode === countryCode))
          .sort(companiesSort)
      : companies;
  }, [masterData, countryCode]);

  const disabled = !masterData.data;

  const options = companiesForCountry || [];

  return { options, disabled, loading: masterData.loading };
};

export default useCompany;
