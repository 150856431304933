import { useTranslation } from 'react-i18next';
import SimpleSelectFormInput, { SimpleSelectFormInputProps } from './SimpleSelectFormInput';
import { useFederativeEntitySelect } from '../../hooks';

export type FederativeEntitySelectFormInputProps = Omit<
  SimpleSelectFormInputProps,
  'options' | 'value'
> & {
  countryCode: string;
};

const FederativeEntitySelectFormInput = ({
  disabled,
  countryCode,
  ...rest
}: FederativeEntitySelectFormInputProps) => {
  const { t } = useTranslation();
  const { loading, disabled: disabledSelect, options } = useFederativeEntitySelect(countryCode);
  const isDisabled = disabled || disabledSelect;

  return (
    <SimpleSelectFormInput
      {...rest}
      label={t('toolbar.labels.federativeEntity')}
      options={options}
      disabled={isDisabled}
      hidden={isDisabled}
      loading={loading}
    />
  );
};

export default FederativeEntitySelectFormInput;
