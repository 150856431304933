import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClosingDashboardViewMode } from '../../../types';

interface ChipButtonProps<TValue> {
  label: ReactNode;
  currentValue: TValue;
  value: TValue;
  onClick: (value: TValue) => void;
  disabled: boolean;
}

const ChipButton = <TValue,>({
  label,
  value,
  currentValue,
  onClick,
  disabled,
}: ChipButtonProps<TValue>) => (
  <Chip
    label={label}
    color={currentValue === value ? 'primary' : 'default'}
    onClick={() => onClick(value)}
    disabled={disabled}
  />
);

type ItemMode = 'error' | 'ok';

export interface IncorporationActionsProps {
  onChangeIntervalMode?: (value: ClosingDashboardViewMode) => void;
  onChangeItemMode?: (value: ItemMode) => void;
  initValueIntervalMode: ClosingDashboardViewMode;
  initValueItemMode: ItemMode;
  disabled: boolean;
}

const IncorporationActions = ({
  onChangeIntervalMode,
  onChangeItemMode,
  initValueIntervalMode,
  initValueItemMode,
  disabled,
}: IncorporationActionsProps) => {
  const { t } = useTranslation();

  const [intervalMode, setIntervalMode] = useState<ClosingDashboardViewMode>(initValueIntervalMode);
  const [itemMode, setItemMode] = useState<ItemMode>(initValueItemMode);

  const handleChangeItemMode: NonNullable<typeof onChangeItemMode> = useCallback(
    value => {
      setItemMode(value);
      if (onChangeItemMode) onChangeItemMode(value);
    },
    [onChangeItemMode]
  );

  const handleChangeIntervalMode: NonNullable<typeof onChangeIntervalMode> = useCallback(
    value => {
      setIntervalMode(value);
      if (onChangeIntervalMode) onChangeIntervalMode(value);
    },
    [onChangeIntervalMode]
  );

  return (
    <Stack direction="column" spacing={1} justifyContent="center" marginTop={2}>
      <Stack direction="row" spacing={1} justifyContent="center">
        <ChipButton
          label={
            intervalMode === ClosingDashboardViewMode.today
              ? t('dashboard.salesCollectionsIncorporation.labels.storesTodayError')
              : t('dashboard.salesCollectionsIncorporation.labels.storesError')
          }
          value="error"
          currentValue={itemMode}
          onClick={handleChangeItemMode}
          disabled={disabled}
        />
        <ChipButton
          label={t('dashboard.salesCollectionsIncorporation.labels.storesOk')}
          value="ok"
          currentValue={itemMode}
          onClick={handleChangeItemMode}
          disabled={disabled || intervalMode === ClosingDashboardViewMode.today}
        />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="center">
        <ChipButton
          label={t('dashboard.time_tabs.today')}
          value={ClosingDashboardViewMode.today}
          currentValue={intervalMode}
          onClick={handleChangeIntervalMode}
          disabled={disabled}
        />
        <ChipButton
          label={t('dashboard.time_tabs.yesterday')}
          value={ClosingDashboardViewMode.yesteday}
          currentValue={intervalMode}
          onClick={handleChangeIntervalMode}
          disabled={disabled}
        />
        <ChipButton
          label={t('dashboard.time_tabs.current_month')}
          value={ClosingDashboardViewMode.currentMonth}
          currentValue={intervalMode}
          onClick={handleChangeIntervalMode}
          disabled={disabled}
        />
        <ChipButton
          label={t('dashboard.time_tabs.last_month')}
          value={ClosingDashboardViewMode.lastMonth}
          currentValue={intervalMode}
          onClick={handleChangeIntervalMode}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

export default IncorporationActions;
