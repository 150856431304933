import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Management, Region, RegionalManagement, Supervision } from '../types';

const regionsSort = (a: Region, b: Region) => (a.label > b.label ? 1 : -1);

const useRegion = (
  countryCode: string,
  regionalManagements: RegionalManagement[],
  management: Management[],
  supervision: Supervision[]
) => {
  const masterData = useSelector(masterDataSelector);

  const regionsForCountry = useMemo(() => {
    const regions = masterData.loaded && masterData.data ? masterData.data.regions : [];
    return countryCode
      ? regions.filter(i => i.countryCode === countryCode).sort(regionsSort)
      : regions;
  }, [masterData, countryCode]);

  const regionsForRegionalManagement = useMemo(() => {
    const regions = regionsForCountry;
    return regionalManagements.length > 0
      ? regions
          .filter(r => regionalManagements.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(regionsSort)
      : regions;
  }, [regionalManagements, regionsForCountry]);

  const regionsForManagement = useMemo(() => {
    const regions = regionsForRegionalManagement;
    return management.length > 0
      ? regions.filter(r => management.map(i => i.regionId).includes(r.regionId)).sort(regionsSort)
      : regions;
  }, [regionsForRegionalManagement, management]);

  const regionsForSupervision = useMemo(() => {
    const regions = regionsForManagement;
    return supervision.length > 0
      ? regions.filter(r => supervision.map(i => i.regionId).includes(r.regionId)).sort(regionsSort)
      : regions;
  }, [regionsForManagement, supervision]);

  const disabled = !masterData.data;

  const options = regionsForSupervision || [];

  return { options, disabled, loading: masterData.loading };
};

export default useRegion;
