import { Action } from 'redux';
import { Permission, HttpErrorBaseAction, RemoteResource } from '../../types';

export interface State {
  info: { username: string; name: string } | null;
  isAuthenticated: boolean;
  permissions: RemoteResource<Permission[]>;
}

export enum ActionType {
  msalSignInSuccess = 'user/msalSignInSuccess',
  permissionsRequested = 'user/permissionsRequested',
  permissionsSuccess = 'user/permissionsSuccess',
  permissionsFailure = 'user/permissionsFailure',
}

export type MsalSigninSuccessAction = Action<ActionType.msalSignInSuccess> & {
  username: string;
  name: string;
};

export type PermissionsRequestedAction = Action<ActionType.permissionsRequested>;

export type PermissionsSuccessAction = Action<ActionType.permissionsSuccess> & {
  permissions: Permission[];
};

export type PermissionsFailureAction = HttpErrorBaseAction<ActionType.permissionsFailure>;

export type Actions =
  | MsalSigninSuccessAction
  | PermissionsRequestedAction
  | PermissionsSuccessAction
  | PermissionsFailureAction;
