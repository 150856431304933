import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  reprocessChangeFilterFederativeEntity,
  reprocessChangeFilterStore,
  reprocessFilterCountryCodeSelector,
  reprocessFilterStoreAcronymSelector,
} from '../../../state';
import { SimpleSelectProps, StoreSelect } from '../../selects';

type StoreFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const StoreFilter = (props: StoreFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reprocessFilterCountryCodeSelector);
  const storeAcronym = useSelector(reprocessFilterStoreAcronymSelector);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(reprocessChangeFilterStore(value));
      dispatch(reprocessChangeFilterFederativeEntity(''));
    },
    [dispatch]
  );

  return (
    <StoreSelect
      {...props}
      label={t('toolbar.labels.store')}
      countryCode={countryCode}
      value={storeAcronym}
      onChange={handleChange}
      simple
      emptySelection=""
      clearable
    />
  );
};

export default StoreFilter;
