import { eachDayOfInterval, format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import { handleLoadByViewMode } from '../../helpers';
import { useMuiPickersLocale } from '../../hooks';
import {
  reportClosingCollectionsSelector,
  reportClosingCurrentSelector,
  reportClosingFilterEndBusinessDateSelector,
  reportClosingFilterStartBusinessDateSelector,
  reportClosingFilterViewModeSelector,
  reportClosingSalesSelector,
} from '../../state';
import { ClosingDetailGroup } from '../../types';
import handlerFactory, { ClosingDataGridContext } from './handlerFactory';

const getDatesHeaders = (start: Date | null, end: Date | null, locale: Locale) => {
  if (!start || !end) return [];

  return eachDayOfInterval({
    start,
    end,
  })
    .map(i => format(i, 'dd MMM', { locale }))
    .reverse();
};

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<ClosingDetailGroup, ClosingDataGridContext>();

  const { t } = useTranslation();
  const title = t('missing-closing-detail.title');
  const locale = useMuiPickersLocale();
  const salesDetail = useSelector(reportClosingSalesSelector);
  const collectionsDetail = useSelector(reportClosingCollectionsSelector);
  const viewMode = useSelector(reportClosingFilterViewModeSelector);
  const load = handleLoadByViewMode(viewMode, salesDetail, collectionsDetail);
  const dateFrom = useSelector(reportClosingFilterStartBusinessDateSelector).accept;
  const dateTo = useSelector(reportClosingFilterEndBusinessDateSelector).accept;
  const data = useSelector(reportClosingCurrentSelector);
  const headerDates = useMemo(
    () => getDatesHeaders(dateFrom, dateTo, locale),
    [dateFrom, dateTo, locale]
  );

  const gridContext = useMemo(
    () => ({
      t,
      viewMode,
    }),
    [t, viewMode]
  );

  useEffect(() => {
    handler.updateColumnIterator('dates', headerDates);
  }, [handler, headerDates]);

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(load[0]);

  return null;
};

const ClosingDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ClosingDataGrid;
