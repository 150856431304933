import { useTranslation } from 'react-i18next';
import SimpleSelectFormInput, { SimpleSelectFormInputProps } from './SimpleSelectFormInput';
import useReprocessTypeSelect from '../../hooks/useReprocessTypeSelect';

export type ReprocessTypeFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'>;

const ReprocessTypeFormInput = (props: ReprocessTypeFormInputProps) => {
  const { t } = useTranslation();
  const { options, loading } = useReprocessTypeSelect();

  return (
    <SimpleSelectFormInput
      {...props}
      label={t('reprocess.labels.type')}
      options={options}
      loading={loading}
    />
  );
};

export default ReprocessTypeFormInput;
