import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MonthClosing } from '../../types';
import { monthClosingSelector } from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { MonthClosingDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { t } = useTranslation();
  const { handler } = useDataGridFlexSales<MonthClosing, MonthClosingDataGridContext>();
  const { loading, data } = useSelector(monthClosingSelector);

  const gridContext = useMemo(() => ({ t }), [t]);

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(t('monthClosing.title'));
  handler.setLoading(loading);

  return null;
};

const MonthClosingDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default MonthClosingDataGrid;
