import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterOperationType,
  reportOperationTypeOptionsSelector,
  reportFilterOperationTypeSelector,
} from '../../../state';
import { OperationType } from '../../../types';
import { SelectFlexSales } from '../../selects';

const OperationTypeFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loaded, loading, data } = useSelector(reportOperationTypeOptionsSelector);
  const value = useSelector(reportFilterOperationTypeSelector);
  const disabled = !loaded;
  const options = data || [];

  const handleChange = useCallback(
    (value: OperationType[]) => dispatch(changeFilterOperationType(value)),
    [dispatch]
  );

  return (
    <SelectFlexSales
      label={t('table.columns.operation_type_name')}
      options={options}
      getOptionLabel="name"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      loading={loading}
      multiple
    />
  );
};

export default OperationTypeFilter;
