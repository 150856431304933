import { ReactNode } from 'react';
import { DataGridHandler } from './DataGridHandler';
import { Toolbar } from './Toolbar';
import DataGridFlexSalesProvider from './DataGridFlexSalesProvider';
import DataGrid from './DataGrid';

interface DataGridFlexSalesProps<TData, TContext> {
  handlerFactory: (locale: Locale) => DataGridHandler<TData, TContext>;
  children: ReactNode;
  hideToolbar?: boolean;
}

const DataGridFlexSales = <TData, TContext>({
  handlerFactory,
  children,
  hideToolbar,
}: DataGridFlexSalesProps<TData, TContext>) => (
  <DataGridFlexSalesProvider handlerFactory={handlerFactory}>
    {children}
    {!hideToolbar && <Toolbar />}
    <DataGrid />
  </DataGridFlexSalesProvider>
);

export default DataGridFlexSales;
