import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterPaymentTypes,
  findOperationTypesRequested,
  reportFilterPaymentTypesSelector,
  reportPaymentTypeOptionsSelector,
  reportFilterCountryCodeSelector,
} from '../../../state';
import { PaymentType } from '../../../types';
import { SelectFlexSales } from '../../selects';

interface PaymentTypesFilterProp {
  labelTranslationId?: string;
}

const PaymentTypesFilter = ({
  labelTranslationId = 'toolbar.labels.paymentType',
}: PaymentTypesFilterProp) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);

  const paymentTypesOptionsResource = useSelector(reportPaymentTypeOptionsSelector);
  const paymentTypes = useSelector(reportFilterPaymentTypesSelector);
  const disabled = !paymentTypesOptionsResource.loaded;
  const paymentTypesOptions = paymentTypesOptionsResource.data || [];

  const handleChange = useCallback(
    (value: PaymentType[]) => dispatch(changeFilterPaymentTypes(value)),
    [dispatch]
  );

  useEffect(() => {
    dispatch(findOperationTypesRequested());
  }, [dispatch]);

  return (
    <SelectFlexSales
      label={t(labelTranslationId)}
      options={paymentTypesOptions}
      getOptionLabel={countryCode === 'BR' ? 'tenderNameBr' : 'tenderId'}
      disabled={disabled}
      value={paymentTypes}
      onChange={handleChange}
      loading={paymentTypesOptionsResource.loading}
      multiple
    />
  );
};

export default PaymentTypesFilter;
