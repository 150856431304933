import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountingParametersAccountingParametersLoadingSelector,
  accountingParametersFindRequested,
} from '../../state';
import { LoadingButton } from '../buttons';
import { AccountingParameterFilters } from './AccountingParameterFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button {
    margin-right: ${props => props.theme.spacing(2)};
  }
  > button:last-child {
    margin-right: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(
    () => dispatch(accountingParametersFindRequested()),
    [dispatch]
  );
  const { t } = useTranslation();
  const loading = useSelector(accountingParametersAccountingParametersLoadingSelector);
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={loading}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const actions = (
  <ActionContainer>
    <ButtonApply />
  </ActionContainer>
);

const AccountingEntriesSearch = () => <AccountingParameterFilters actions={actions} />;

export default AccountingEntriesSearch;
