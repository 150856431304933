import styled from '@emotion/styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import { hasPermissions } from '../../helpers';
import {
  accountingEntriesCompanyCodesLoadingSelector,
  accountingEntriesFindRequested,
  userPermissionsDataSelector,
} from '../../state';
import { AppPermissions } from '../../types';
import { LoadingButton } from '../buttons';
import { AccountingEntriesFilters } from './AccountingEntriesFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button {
    margin-right: ${props => props.theme.spacing(2)};
  }
  > button:last-child {
    margin-right: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(
    () => dispatch(accountingEntriesFindRequested()),
    [dispatch]
  );
  const { t } = useTranslation();
  const loading = useSelector(accountingEntriesCompanyCodesLoadingSelector);
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={loading}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const ButtonRefresh = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(
    () => dispatch(accountingEntriesFindRequested()),
    [dispatch]
  );
  const loading = useSelector(accountingEntriesCompanyCodesLoadingSelector);
  return (
    <IconButton aria-label="refresh" size="small" onClick={handleClickApply} disabled={loading}>
      {!loading && <RefreshIcon color="primary" />}
      {loading && <CircularProgress size={15} />}
    </IconButton>
  );
};

const CreateAccountingEntries = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = `${routes.protectedNestedRoutes.accountingEntries.children.create.path}`;
  const handleClick = useCallback(() => navigate(path), [navigate, path]);

  return (
    <Button color="primary" variant="contained" onClick={handleClick}>
      {t('accountingEntries.labels.createAccountingEntries')}
    </Button>
  );
};

const AccountingEntriesSearch = () => {
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const actions = (
    <ActionContainer>
      <ButtonRefresh />
      {hasPermissions(userPermissions, [
        AppPermissions.ACCOUNTING_ENTRIES_COMPLETE,
        AppPermissions.ACCOUNTING_ENTRIES_EXECUTION,
      ]) && <CreateAccountingEntries />}
      <ButtonApply />
    </ActionContainer>
  );
  return <AccountingEntriesFilters actions={actions} />;
};

export default AccountingEntriesSearch;
