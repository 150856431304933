import { useEffect, useState } from 'react';

interface Props {
  time: number;
  updateTime: (updateState?: boolean) => void;
  render?: (counter: number) => JSX.Element;
  onComplete?: () => void;
}

const Countdown = ({ time, updateTime, render, onComplete }: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timer>();

  useEffect(() => {
    if (!timer) {
      const interval = setInterval(() => {
        updateTime();
      }, 1000);
      setTimer(interval);
    }
    return () => timer && clearInterval(timer);
  }, [timer]);

  useEffect(() => {
    if (time > 0) {
      updateTime(true);
    } else if (onComplete) {
      onComplete();
    }
  }, [time]);

  if (render) return render(time);
  return <span>{time}</span>;
};

export default Countdown;
