import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMastersImportFilterCountry,
  reportMastersImportFilterCountrySelector,
} from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';
import { Country } from '../../../types';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const country = useSelector(reportMastersImportFilterCountrySelector);

  const handleChange = useCallback(
    (value: Country[]) => dispatch(changeMastersImportFilterCountry(value)),
    [dispatch]
  );

  return (
    <CountrySelect
      {...props}
      label={t('toolbar.labels.country')}
      value={country}
      onChange={handleChange}
      propId="countryCode"
      translationKey="operativeStructure.countries"
      selectAll
    />
  );
};

export default CountryFilter;
