import { createRoot } from 'react-dom/client';
import App from './App';
import { register } from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container as never);

root.render(<App />);

register();
