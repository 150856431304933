import { Divider, Grid, List, ListItem, Skeleton } from '@mui/material';
import styled from '@emotion/styled';
import {
  MissingClosingCollectionsCauses,
  MissingClosingSalesCauses,
  RemoteResource,
  ValidationErrorCause,
} from '../../../types';
import ErrorDescription from './ErrorDescription';
import { TableBodyEmpty, TableBodyError } from '../../TableFlexSales/TableFlexSales';
import { DataGridFlexSalesHeader } from '../../DataGridFlexSales';

const StyledList = styled(List)`
  padding: 0 !important;
  max-height: 141px;
  overflow: scroll;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 36px;
`;

const ListLoading = () => (
  <>
    <StyledSkeleton />
    <StyledSkeleton />
    <StyledSkeleton />
  </>
);

interface ErrorItemProps {
  country: string | undefined;
  error: ValidationErrorCause;
}

const ErrorItem = ({ country, error }: ErrorItemProps) => (
  <>
    <ListItem>
      <ErrorDescription country={country} error={error} />
    </ListItem>
    <Divider />
  </>
);

interface ValidationErrorsListProps {
  header: string;
  resource: RemoteResource<MissingClosingSalesCauses | MissingClosingCollectionsCauses | undefined>;
}

const ValidationErrorsList = ({ header, resource }: ValidationErrorsListProps) => {
  const { loading, loaded, data, failure } = resource;
  const country = data?.country;
  const errors = (data?.details || []) as ValidationErrorCause[];

  return (
    <Grid item xs={12}>
      <DataGridFlexSalesHeader>{header}</DataGridFlexSalesHeader>
      {loaded && (
        <StyledList dense>
          {errors.map((i, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ErrorItem key={`error-${index}`} country={country} error={i} />
          ))}
        </StyledList>
      )}
      {!loading && failure && <TableBodyError />}
      {!loading && !failure && errors.length === 0 && <TableBodyEmpty />}
      {loading && <ListLoading />}
    </Grid>
  );
};

export default ValidationErrorsList;
