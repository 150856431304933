import { SaleType, Translation } from '../../types';
import { ColumnConfig } from './DataGridHandler';

interface ContextBase {
  t: Translation;
}
interface ContextWithContry extends ContextBase {
  country: string;
}

interface ContextWithSaleTypeOptions extends ContextBase {
  saleTypeOptions: SaleType[];
}

interface DataWithSaleTypeWh {
  saleTypeWh?: string | number;
}

export const countryId = {
  width: { px: 55, pt: 50 },
};

export const country = {
  width: { px: 55, pt: 50 },
};

export const saleType: ColumnConfig<
  DataWithSaleTypeWh,
  ContextWithSaleTypeOptions & ContextWithContry
> = {
  name: 'saleTypeWh',
  type: 'string',
  title: ({ t }) => t('table.columns.sale_type_wh'),
  valueGetter: (row, context) => {
    if (!row.saleTypeWh) return '-';
    return context.saleTypeOptions.find(i => Number(i.saleTypeId) === Number(row.saleTypeWh))
      ?.saleTypeDescription;
  },
};

export const store = {
  type: 'string' as const,
  title: ({ t, country }: ContextWithContry) =>
    t([`table.columns.${country}.store`, 'table.columns.store']),
};

export const storeWithGroupBy = {
  ...store,
  available: ({ groupBy }: { groupBy: string[] }) => groupBy.includes('storeAcronym'),
} as const;

export const accountingDate = {
  type: 'date' as const,
  title: ({ t }: ContextBase) => t('table.columns.accounting_date'),
};

export const accountingDateWithGroupBy = {
  ...accountingDate,
  available: ({ groupBy }: { groupBy: string[] }) => groupBy.includes('accountingDate'),
};

export const plu = {
  type: 'number' as const,
  title: ({ t }: ContextBase) => t('table.columns.plu'),
};

export const pluWithGroupBy = {
  ...plu,
  available: ({ groupBy }: { groupBy: string[] }) => groupBy.includes('plu'),
};
