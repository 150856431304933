import { ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  autocompleteClasses,
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AutocompleteValue } from '@mui/material/useAutocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { groupBy as makeGroupBy, onlyUnique } from '../../../helpers';

export type OnChange<TValue, TMultiple, TDisableClearable, TFreeSolo> = (
  value: AutocompleteValue<TValue, TMultiple, TDisableClearable, TFreeSolo>
) => void;

export type GetOptionLabelHandle<TValue> = (option: TValue) => string;

export type GetOptionLabel<TValue> = keyof TValue | GetOptionLabelHandle<TValue>;

export type RenderOption<TValue> = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TValue,
  state: AutocompleteRenderOptionState
) => ReactNode;

interface UseSelectFlexSalesProps<
  TValue,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined
> {
  label: ReactNode;
  onChange: OnChange<TValue, TMultiple, TDisableClearable, TFreeSolo>;
  getOptionLabel?: GetOptionLabel<TValue>;
  multiple?: boolean;
  renderTags: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['renderTags'];
  renderOption?: RenderOption<TValue>;
  groupBy: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['groupBy'];
  options: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['options'];
  value: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['value'];
  renderGroup: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['renderGroup'];
  groupByCheckboxOption?: boolean;
  loading?: boolean;
}

const TagContainer = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RenderGroupContainer = styled.li`
  > ul {
    padding: 0;
    > .${autocompleteClasses.option} {
      padding-left: ${props => props.theme.spacing(3)};
    }
  }
  > .${autocompleteClasses.option} {
    padding-left: ${props => props.theme.spacing(1)};

    &[aria-selected='false']:hover {
      background-color: ${props => props.theme.palette.action.hover};
    }

    &[aria-selected='true']:hover {
      background-color: ${props =>
        alpha(
          props.theme.palette.primary.main,
          props.theme.palette.action.selectedOpacity + props.theme.palette.action.hoverOpacity
        )};
    }
  }
`;

const SelectCheckbox = ({ checked }: { checked: boolean }) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    checkedIcon={<CheckBoxIcon fontSize="small" />}
    checked={checked}
    size="small"
  />
);

interface RenderGroupProps extends Omit<AutocompleteRenderGroupParams, 'key'> {
  onClick: (group: string, checked: boolean) => void;
  checked: boolean;
}

const RenderGroup = ({ group, children, onClick, checked }: RenderGroupProps) => (
  <RenderGroupContainer>
    <span
      className={autocompleteClasses.option}
      onClick={() => onClick(group, checked)}
      role="option"
      tabIndex={-1}
      onKeyPress={e => (e.key === 'Enter' ? onClick(group, checked) : undefined)}
      aria-selected={checked}
    >
      <SelectCheckbox checked={checked} />
      <span>{group}</span>
    </span>
    <ul>{children}</ul>
  </RenderGroupContainer>
);

const useSelectFlexSales = <
  TValue,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined
>({
  label,
  onChange,
  getOptionLabel,
  renderTags,
  multiple,
  renderOption,
  groupBy,
  options,
  value,
  renderGroup,
  groupByCheckboxOption,
  loading,
}: UseSelectFlexSalesProps<TValue, TMultiple, TDisableClearable, TFreeSolo>) => {
  const propsLoading = useMemo(
    () => (loading ? { popupIcon: <CircularProgress size={16} />, disabled: true } : {}),
    [loading]
  );

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={label} variant="standard" />
    ),
    [label]
  );

  const handleOnChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: AutocompleteValue<TValue, TMultiple, TDisableClearable, TFreeSolo>
    ) => onChange(value),
    [onChange]
  );

  const handleGetOptionLabel = useCallback(
    (option: TValue) => {
      if (typeof getOptionLabel === 'string') return option[getOptionLabel] as unknown as string;
      if (typeof getOptionLabel === 'function') return getOptionLabel(option);
      return option as unknown as string;
    },
    [getOptionLabel]
  );

  const handleRenderOption: RenderOption<TValue> | undefined = useMemo(() => {
    if (multiple && !renderOption)
      return (props, option, { selected }) => (
        <li {...props}>
          <SelectCheckbox checked={selected} />
          {handleGetOptionLabel(option)}
        </li>
      );
    return renderOption;
  }, [multiple, handleGetOptionLabel, renderOption]);

  const handleRenderTags = useMemo(() => {
    if (!multiple || renderTags) return renderTags;
    return (options: TValue[]) =>
      (<TagContainer>{options.map(handleGetOptionLabel).join()}</TagContainer>) as ReactNode;
  }, [multiple, handleGetOptionLabel, renderTags]);

  const groupedOptions = useMemo(
    () => (groupBy ? makeGroupBy(options, groupBy) : {}),
    [options, groupBy]
  );

  const handleRenderGroup = useMemo(() => {
    if (multiple && !renderGroup && groupBy && Array.isArray(value) && groupByCheckboxOption) {
      const checkedGroup = (group: string) =>
        groupedOptions[group].length === value.filter(i => groupBy(i) === group).length;

      const onClick = (group: string, checked: boolean) => {
        const optionsSelected = !checked
          ? onlyUnique([...value, ...groupedOptions[group]])
          : value.filter(i => groupBy(i) !== group);

        onChange(
          optionsSelected as AutocompleteValue<TValue, TMultiple, TDisableClearable, TFreeSolo>
        );
      };

      return ({ group, key, ...others }: AutocompleteRenderGroupParams) => {
        const checked = checkedGroup(group);
        return (
          <RenderGroup group={group} key={key} checked={checked} onClick={onClick} {...others} />
        );
      };
    }
    return renderGroup;
  }, [multiple, renderGroup, value, groupedOptions, groupBy, onChange, groupByCheckboxOption]);

  return {
    propsLoading,
    handleRenderInput,
    handleOnChange,
    handleGetOptionLabel,
    handleRenderOption,
    handleRenderTags,
    handleRenderGroup,
  };
};

export default useSelectFlexSales;
