import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Badge } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReportFilters } from '../ReportFiltersProvider';
import GroupBySelector from './GroupBySelector';

const GroupBySelectorWrapper = <TGroupBy extends string>() => {
  const { groupByConfig, loadingSelector } = useReportFilters<TGroupBy>();
  if (!groupByConfig) throw new Error('groupByConfig cannot be null or undefined');
  const { action, selector, options } = groupByConfig;
  const dispatch = useDispatch();
  const handleClickApply = useCallback(
    (values: TGroupBy[]) => dispatch(action(values)),
    [dispatch, action]
  );
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const values = useSelector(selector);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleClick = useCallback(() => setOpen(true), []);
  return (
    <>
      <Tooltip title={t('toolbar.labels.groupBy') as string}>
        <Badge badgeContent={values.length} color="primary">
          <Button color="primary" variant="contained" onClick={handleClick} disabled={loading}>
            <ViewModuleIcon />
          </Button>
        </Badge>
      </Tooltip>
      {open && (
        <GroupBySelector
          onClose={handleClose}
          onApply={handleClickApply}
          config={options}
          initialValues={values}
        />
      )}
    </>
  );
};

const GroupByDialog = <TGroupBy extends string>() => {
  const { groupByConfig } = useReportFilters<TGroupBy>();

  return groupByConfig ? <GroupBySelectorWrapper /> : null;
};

export default GroupByDialog;
