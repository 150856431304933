import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface SimpleSelectOption {
  id: string;
  description: string;
}

export interface SimpleSelectProps extends Omit<SelectProps, 'onChange' | 'IconComponent'> {
  onChange: (value: string, event?: SelectChangeEvent, child?: ReactNode) => void;
  options: SimpleSelectOption[];
  loading?: boolean;
  helperText?: string;
  clearable?: boolean;
}

export interface SimpleSelectOptionFormatter {
  sort?: 'asc' | 'desc';
  type: 'translation';
  translationIdBase: string;
}

const useFormat = (config: SimpleSelectOptionFormatter | undefined) => {
  const { t } = useTranslation();
  return useMemo(() => {
    if (!config) return (desc: string) => desc;
    switch (config.type) {
      case 'translation':
        return (desc: string) => t(`${config.translationIdBase}.${desc}`);
      default:
        throw new Error(`formato no contemplado: ${config.type}`);
    }
  }, [config, t]);
};

const sorts = {
  asc: (a: SimpleSelectOption, b: SimpleSelectOption) => (a.description > b.description ? 1 : -1),
  desc: (a: SimpleSelectOption, b: SimpleSelectOption) => (a.description <= b.description ? 1 : -1),
};

export const useArrayToSimpleSelectOption = (
  items: string[],
  formatter?: SimpleSelectOptionFormatter
): SimpleSelectOption[] => {
  const format = useFormat(formatter);
  const sort = formatter?.sort;
  return useMemo(() => {
    const data = items.map(i => ({ id: i, description: format(i) }));
    if (sort) data.sort(sorts[sort]);
    return data;
  }, [items, format, sort]);
};

export const useSimpleSelectOptionMapper = <TData,>(
  items: TData[],
  propId: keyof TData,
  propDesc: keyof TData,
  formatter?: SimpleSelectOptionFormatter
): SimpleSelectOption[] => {
  const format = useFormat(formatter);
  const sort = formatter?.sort;
  return useMemo(() => {
    const data = items.map(i => ({
      id: i[propId] as unknown as string,
      description: format(i[propDesc] as unknown as string),
    }));
    if (sort) data.sort(sorts[sort]);
    return data;
  }, [items, propId, propDesc, format, sort]);
};

const SimpleSelect = ({
  options,
  fullWidth,
  loading,
  label,
  onChange,
  required,
  helperText,
  error,
  clearable,
  ...others
}: SimpleSelectProps) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>, child: ReactNode) =>
      onChange(event.target.value as unknown as string, event as SelectChangeEvent<string>, child),
    [onChange]
  );

  const IconComponent = useCallback(() => <CircularProgress size={16} />, []);

  const propsLoading = useMemo(
    () => (loading ? { IconComponent, disabled: true } : {}) as SelectProps,
    [loading, IconComponent]
  );

  return (
    <FormControl variant="standard" fullWidth={fullWidth} required={required} error={error}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...others} onChange={handleChange} {...propsLoading}>
        {clearable && (
          <MenuItem key="none" value="">
            <em>{t('shared.labels.none')}</em>
          </MenuItem>
        )}
        {options.map(i => (
          <MenuItem key={i.id} value={i.id}>
            {i.description}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleSelect;
