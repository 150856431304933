import { Actions, State, ActionType } from './types';

const initialState: State = {
  downloadHandler: undefined,
  downloading: false,
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.downloadRequested:
      return {
        ...state,
        error: undefined,
        downloading: true,
        downloadHandler: action.downloadHandler,
      };
    case ActionType.downloadSuccess:
      return {
        ...state,
        downloading: false,
        downloadHandler: undefined,
      };
    case ActionType.downloadFailure:
      return {
        ...state,
        downloading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
