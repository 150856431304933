import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Management, Region, RegionalManagement, Supervision } from '../types';

const regionalManagementsSort = (a: RegionalManagement, b: RegionalManagement) =>
  a.label > b.label ? 1 : -1;

const useRegionalManagement = (
  countryCode: string,
  regions: Region[],
  management: Management[],
  supervision: Supervision[]
) => {
  const masterData = useSelector(masterDataSelector);

  const regionalManagementsForCountry = useMemo(() => {
    const regionalManagements =
      masterData.loaded && masterData.data ? masterData.data.regionalManagements : [];
    return countryCode
      ? regionalManagements.filter(i => i.countryCode === countryCode).sort(regionalManagementsSort)
      : regionalManagements;
  }, [masterData, countryCode]);

  const regionalManagementsForRegion = useMemo(() => {
    const regionalManagements = regionalManagementsForCountry;
    return regions.length > 0
      ? regionalManagements
          .filter(r => regions.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(regionalManagementsSort)
      : regionalManagements;
  }, [regionalManagementsForCountry, regions]);

  const regionalManagementsForManagement = useMemo(() => {
    const regionalManagements = regionalManagementsForRegion;
    return management.length > 0
      ? regionalManagements
          .filter(r => management.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(regionalManagementsSort)
      : regionalManagements;
  }, [regionalManagementsForRegion, management]);

  const regionalManagementsForSupervision = useMemo(() => {
    const regionalManagements = regionalManagementsForManagement;
    return supervision.length > 0
      ? regionalManagements
          .filter(r => supervision.map(i => i.regionalMgmtId).includes(r.regionalMgmtId))
          .sort(regionalManagementsSort)
      : regionalManagements;
  }, [regionalManagementsForManagement, supervision]);

  const disabled = !masterData.data;

  const options = regionalManagementsForSupervision || [];

  return { options, disabled, loading: masterData.loading };
};

export default useRegionalManagement;
