import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface UserNavigationErrorProps {
  code: number;
  description: string;
  actions?: ReactElement;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-top: 5%;
`;

const ErrorDescription = styled(Typography)`
  margin-top: ${props => props.theme.spacing(2)};
`;

const ShortDescription = styled.span`
  font-size: ${props => props.theme.typography.h4.fontSize};
`;

const ActionsContainer = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
`;

const UserNavigationError = ({ code, description, actions }: UserNavigationErrorProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <Typography variant="h3">
          {code}.
          <ShortDescription>
            {t('components.UserNavigationError.shortDescription')}
          </ShortDescription>
        </Typography>
        <ErrorDescription variant="h6" color="text.secondary">
          {description}
        </ErrorDescription>
        <ActionsContainer>{actions}</ActionsContainer>
      </div>
    </Container>
  );
};

export default UserNavigationError;
