import { useTranslation } from 'react-i18next';
import { DialogForm, MonthClosingForm } from '../components';

const MonthClosingNew = () => {
  const { t } = useTranslation();

  return (
    <DialogForm title={t('monthClosing.titles.closeMonth')} maxWidth="md">
      <MonthClosingForm />
    </DialogForm>
  );
};

export default MonthClosingNew;
