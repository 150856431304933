import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import {
  reportMastersImportLoadingSelector,
  findMastersImportRequested,
  reportMastersImportFilterEndImportDateSelector,
  reportMastersImportFilterStartImportDateSelector,
  reportMasterImportExecutionLoadingSelector,
  mastersImportExecutionRequested,
} from '../../state';

import { ReportFilterActions, ReportFilters } from '../ReportFilters';

import { AppPermissions } from '../../types';
import { PermissionsProvider } from '../Permissions';
import { CountryFilter, ImportDateRangeFilter, StateFilter } from './filters';
import { LoadingButton } from '../buttons';

const MastersDetailSearch = () => {
  const startImportDate = !useSelector(reportMastersImportFilterStartImportDateSelector).accept;
  const endImportDate = !useSelector(reportMastersImportFilterEndImportDateSelector).accept;
  const masterImportExecutionLoading = useSelector(reportMasterImportExecutionLoadingSelector);
  const applyDisabled = startImportDate && endImportDate ? false : startImportDate || endImportDate;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleMasterImportExecution = useCallback(
    () => dispatch(mastersImportExecutionRequested()),
    [dispatch]
  );
  return (
    <ReportFilters
      loadingSelector={reportMastersImportLoadingSelector}
      applyAction={findMastersImportRequested}
    >
      <PermissionsProvider resourcePermissions={[AppPermissions.DASHBOARD_MASTER_IMPORT]}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ImportDateRangeFilter spacing={1} />
          </Grid>
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <StateFilter />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions applyDisabled={applyDisabled}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleMasterImportExecution}
                loading={masterImportExecutionLoading}
              >
                {t('masters-import.execution.label')}
              </LoadingButton>
            </ReportFilterActions>
          </Grid>
        </Grid>
      </PermissionsProvider>
    </ReportFilters>
  );
};

export default MastersDetailSearch;
