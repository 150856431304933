import { defaultFormatters } from '../helpers';
import { TableColumnAlign, TableColumnExportPdf, TableExportPdf } from './types';

export interface Column<TData> {
  getValue: (row: TData) => string;
  getTitle: () => string;
  width: number;
  align: TableColumnAlign;
  isAvailable: () => boolean;
}

interface ColumnConfig<TData> {
  type: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'array';
  value: ((row: TData) => unknown) | keyof TData;
  format?: (value: unknown) => string;
  align?: TableColumnAlign;
  width?: number;
  title: (() => string) | string;
  available?: () => boolean;
}

interface FormattersConfig {
  locale: Locale;
}

type Formatters<TData> = Record<ColumnConfig<TData>['type'], (value: unknown) => string>;

const getFormatters = <TData>({ locale }: FormattersConfig): Formatters<TData> => ({
  string: value => (value ? (value as string) : ''),
  array: value => (Array.isArray(value) ? value.join(', ') : ''),
  boolean: value => defaultFormatters.boolean(value, ''),
  date: value => defaultFormatters.date(value, locale),
  dateTime: value => defaultFormatters.dateTime(value, locale),
  number: value => defaultFormatters.number(value, locale),
});

type ColumnConfigCallback = (
  headers: string[],
  rows: (string | null)[][]
) => TableColumnExportPdf[];

interface TableTransposeConfig {
  title: string;
  getColumnConfig: ColumnConfigCallback;
}

export class TableExportPdfAdapter<TData> {
  #columns: Column<TData>[] = [];

  #data: TData[] = [];

  #defaultFormatters: Formatters<TData>;

  constructor(data: TData[], locale: Locale) {
    this.#data = data;
    this.#defaultFormatters = getFormatters({ locale });
  }

  addColumn(columnOrConfig: ColumnConfig<TData> | Column<TData>) {
    if ('type' in columnOrConfig) {
      const {
        type,
        value,
        title,
        format = this.#defaultFormatters[type],
        align = 'left',
        width = 100,
        available = () => true,
      } = columnOrConfig;
      const getValue = (row: TData) =>
        format(typeof value === 'function' ? value(row) : row[value]);
      const getTitle = typeof title === 'function' ? title : () => title;

      this.#columns.push({ getValue, align, width, getTitle, isAvailable: available });
    } else {
      this.#columns.push(columnOrConfig);
    }
  }

  async getTable(title: string) {
    return new Promise<TableExportPdf>(resolve => {
      setTimeout(() => {
        const headers: string[] = [];
        const columnConfig: TableColumnExportPdf[] = [];
        const rows: (string | null)[][] = [];

        this.#data.forEach(item => {
          const row: (string | null)[] = [];
          this.#columns
            .filter(i => i.isAvailable())
            .forEach(col => {
              headers.push(col.getTitle());
              columnConfig.push({ width: col.width, align: col.align });
              row.push(col.getValue(item));
            });
          rows.push(row);
        });

        resolve({ title, headers, columnConfig, rows });
      });
    });
  }

  async getTableTranspose({ title, getColumnConfig }: TableTransposeConfig) {
    return new Promise<TableExportPdf>(resolve => {
      setTimeout(() => {
        const headers: string[] = [];
        const rows: (string | null)[][] = [];

        this.#columns
          .filter(i => i.isAvailable())
          .forEach((col, colIndex) => {
            const columTitle = col.getTitle();
            if (colIndex) rows.push([columTitle]);
            else headers.push(columTitle);
            this.#data.forEach(item => {
              const colValue = col.getValue(item);
              if (colIndex) rows[rows.length - 1].push(colValue);
              else headers.push(colValue);
            });
          });

        const columnConfig = getColumnConfig(headers, rows);

        resolve({ title, headers, columnConfig, rows });
      });
    });
  }
}
