import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete/Autocomplete';
import Stack from '@mui/material/Stack/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  DateView,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { ReactNode, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isEqual from 'date-fns/isEqual';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import { useMuiPickersLocale } from '../../hooks';

const AutocompleteStyled = styled(Autocomplete)`
  min-width: 10em;
` as typeof Autocomplete;

const TextFieldStyled = styled(TextField)`
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
    padding: 4px;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`;

interface DatePickerAutocompleteFieldProps
  extends UseDateFieldProps<Date>,
    BaseSingleInputFieldProps<Date | null, FieldSection, DateValidationError> {
  views?: DateView[];
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
  required?: boolean;
  variant?: TextFieldProps['variant'];
  helperText?: string;
  error?: boolean;
  name?: string;
}

const mergeAdornments = (dateAdornment: ReactNode, clearAdornment: ReactNode) => (
  <Stack direction="row" sx={{ paddingRight: clearAdornment ? 0 : 1, paddingLeft: 0 }}>
    {dateAdornment}
    {clearAdornment}
  </Stack>
);

const getOptions = <TDate,>(minDate: TDate, maxDate: TDate, views: DateView[] | undefined) => {
  const interval: Interval = {
    start: minDate as Date,
    end: maxDate as Date,
  };

  if (!views || views.length === 0 || views.includes('day'))
    return eachDayOfInterval(interval).reverse();
  if (views.includes('month')) return eachMonthOfInterval(interval).reverse();
  if (views.includes('year')) return eachYearOfInterval(interval).reverse();
  return [];
};

const getRenderInput =
  (
    label: ReactNode,
    endAdornment: ReactNode,
    inputProps: DatePickerAutocompleteFieldProps['inputProps'],
    required?: boolean,
    variant?: TextFieldProps['variant'],
    helperText?: string,
    error?: boolean,
    name?: string
  ) =>
  ({ inputProps: inputPropsParams, InputProps, ...rest }: AutocompleteRenderInputParams) =>
    (
      <TextFieldStyled
        {...rest}
        label={label}
        inputProps={{ ...inputPropsParams, ...inputProps }}
        variant={variant}
        name={name}
        required={required}
        helperText={helperText}
        error={error}
        InputProps={{
          ...InputProps,
          endAdornment: mergeAdornments(endAdornment, InputProps.endAdornment),
        }}
      />
    );

const DatePickerAutocompleteField = (props: DatePickerAutocompleteFieldProps) => {
  const {
    label,
    disabled,
    readOnly,
    id,
    value,
    onChange,
    InputProps: { ref, endAdornment } = {},
    inputProps,
    views,
    minDate,
    maxDate,
    required,
    variant,
    fullWidth,
    helperText,
    error,
    name,
  } = props;

  const locale = useMuiPickersLocale();
  const options = useMemo(() => getOptions(minDate, maxDate, views), [minDate, maxDate, views]);
  const getOptionLabel = useCallback(
    (date: Date) => {
      if (!isValid(date)) return '';
      if (!views || views.length === 0 || views.includes('day'))
        return format(date, 'ccccc P', { locale }).toUpperCase();
      if (views.includes('month')) return format(date, 'LLLL yyyy', { locale });
      if (views.includes('year')) return format(date, 'yyyy', { locale });
      return '';
    },
    [locale, views]
  );

  const renderInput = useMemo(
    () =>
      getRenderInput(label, endAdornment, inputProps, required, variant, helperText, error, name),
    [label, endAdornment, inputProps, required, variant, helperText, error, name]
  );

  const isOptionEqualToValue = useCallback(
    (date1: Date, date2: Date) => {
      if (!views || views.includes('day')) return isEqual(date1, date2);
      if (views.includes('month')) return isSameMonth(date1, date2);
      if (views.includes('year')) return isSameYear(date1, date2);
      return false;
    },
    [views]
  );

  const handleOnChange = useCallback(
    (_: unknown, value: Date | null) => onChange?.(value, { validationError: null }),
    [onChange]
  );

  return (
    <AutocompleteStyled
      id={id}
      options={options}
      disabled={disabled}
      readOnly={readOnly}
      ref={ref}
      fullWidth={fullWidth}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      value={value}
      forcePopupIcon={false}
      onChange={handleOnChange}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default DatePickerAutocompleteField;
