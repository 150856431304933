import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reprocessChangeFilterCountry,
  masterDataSelector,
  reprocessFilterCountryCodeSelector,
} from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const dispatch = useDispatch();
  const masterData = useSelector(masterDataSelector);
  const countryCode = useSelector(reprocessFilterCountryCodeSelector);

  const handleChange = useCallback(
    (value: string) => dispatch(reprocessChangeFilterCountry(value)),
    [dispatch]
  );

  useEffect(() => {
    const countries = masterData.loaded && masterData.data ? masterData.data.countries : [];
    if (countries.length === 1) handleChange(countries[0].countryCode);
  }, [handleChange, masterData.data, masterData.loaded]);

  return <CountrySelect {...props} onChange={handleChange} value={countryCode} simple />;
};

export default CountryFilter;
