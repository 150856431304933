import { useTranslation } from 'react-i18next';
import { GoHomeButton, UserNavigationError } from '../components';

const Actions = <GoHomeButton variant="contained" />;

const Http404 = () => {
  const { t } = useTranslation();
  return (
    <UserNavigationError
      code={404}
      description={t('pages.Http404.description')}
      actions={Actions}
    />
  );
};

export default Http404;
