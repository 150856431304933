import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useFlexSalesForm } from './FlexSalesForm';

interface ResetWhenFieldChangesProps {
  field: string;
  fieldToReset: string;
}

const ResetWhenFieldChanges = ({ field, fieldToReset }: ResetWhenFieldChangesProps) => {
  const { control, resetField } = useFlexSalesForm();
  const value = useWatch({ control, name: field });

  useEffect(() => {
    resetField(fieldToReset);
  }, [value, resetField, fieldToReset]);

  return null;
};

export default ResetWhenFieldChanges;
