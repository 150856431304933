import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { monthClosingChangeAnnul } from '../../state';
import { MonthClosing, MonthClosingStatus } from '../../types';
import { commonColProps, DataFlexSalesActionButton, DataGridHandler } from '../DataGridFlexSales';

export interface MonthClosingDataGridContext {
  t: (key: string | string[]) => string;
}

const initialContext: MonthClosingDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<MonthClosing, MonthClosingDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('monthClosing.columns.country'),
    ...commonColProps.country,
  });
  handler.addColumn({
    accessor: 'accountingMonth',
    type: 'date',
    title: ({ t }) => t('monthClosing.columns.accountingMonth'),
    width: { px: 95, pt: 85 },
    format: 'MMM-yy',
    align: 'left',
  });
  handler.addColumn({
    accessor: 'status',
    type: 'string',
    title: ({ t }) => t('monthClosing.columns.status'),
  });
  handler.addColumn({
    accessor: 'justification',
    type: 'string',
    title: ({ t }) => t('monthClosing.columns.justification'),
    width: { px: 440, pt: 430 },
  });
  handler.addColumn({
    accessor: 'createdBy',
    type: 'string',
    title: ({ t }) => t('monthClosing.columns.createdBy'),
    width: { px: 90, pt: 115 },
  });
  handler.addColumn({
    accessor: 'creationDate',
    type: 'date',
    title: ({ t }) => t('monthClosing.columns.creationDate'),
  });
  handler.addColumn({
    accessor: 'updatedBy',
    type: 'string',
    title: ({ t }) => t('monthClosing.columns.updatedBy'),
    width: { px: 90, pt: 115 },
  });
  handler.addColumn({
    accessor: 'updateDate',
    type: 'date',
    title: ({ t }) => t('monthClosing.columns.updatedDate'),
    width: { px: 140, pt: 130 },
  });

  handler.addAction({
    isVisible: ({ row }) => row.status === MonthClosingStatus.active,
    Button: ({ row }) => {
      const dispatch = useDispatch();
      const handleClick = useCallback(
        () => dispatch(monthClosingChangeAnnul(row)),
        [dispatch, row]
      );
      const icon = useCallback(() => <EventRepeatIcon fontSize="small" />, []);
      return <DataFlexSalesActionButton label="delete" onClick={handleClick} icon={icon} />;
    },
  });

  return handler;
};
