import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeClosingFilterViewMode,
  findCauseTypesRequested,
  reportClosingFilterViewModeSelector,
} from '../../../state';
import { ClosingViewMode } from '../../../types';
import {
  SimpleSelect,
  SimpleSelectOptionFormatter,
  SimpleSelectProps,
  useArrayToSimpleSelectOption,
} from '../../selects';

const viewModeOptions: ClosingViewMode[] = [
  ClosingViewMode.CONSOLIDATED,
  ClosingViewMode.SALES,
  ClosingViewMode.COLLECTIONS,
];

type ViewModeFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const viewModeFormatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'toolbar.labels',
};

const ViewModeFilter = (props: ViewModeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = useArrayToSimpleSelectOption(viewModeOptions, viewModeFormatter);
  const viewMode = useSelector(reportClosingFilterViewModeSelector);

  const handleChange = useCallback(
    (value: string) => {
      dispatch(changeClosingFilterViewMode(value as ClosingViewMode));
      dispatch(findCauseTypesRequested());
    },
    [dispatch]
  );

  return (
    <SimpleSelect
      {...props}
      label={t('toolbar.labels.viewMode')}
      value={viewMode}
      options={options}
      onChange={handleChange}
    />
  );
};

export default ViewModeFilter;
