/* eslint-disable no-console */
import { ReactNode } from 'react';
import { ExportFormat } from '../../../exports';
import {
  ActionComponentType,
  Column,
  ColumnAction,
  ColumnConfig,
  ColumnHandler,
  ColumnsEvents,
} from './ColumnHandler';
import { EventHandler } from './EventHandler';
import { ExportConfig, ExportHandler } from './ExportHandler';
import { Row, RowHandler, RowsEvents } from './RowHandler';

type Assignable<T, U> = U extends unknown ? (T extends U ? U : never) : never;

interface DataGridTitle {
  title: string;
  tableTitle: ReactNode;
}

export interface ChangeContextEvent<TData, TContext> {
  type: 'context';
  context: TContext;
  rows: Row<TData>[];
  columns: Column<TData>[];
}

export interface ChangeColumnConfigEvent<TData> {
  type: 'columnsConfig';
  rows: Row<TData>[];
  columns: Column<TData>[];
}
export interface ChangeTitleEvent {
  type: 'title';
  titles: DataGridTitle;
}

export interface ChangeLoadingEvent {
  type: 'loading';
  loading: boolean;
}

export type DataGridEvent<TData, TContext> =
  | RowsEvents<TData>
  | ColumnsEvents
  | ChangeContextEvent<TData, TContext>
  | ChangeTitleEvent
  | ChangeLoadingEvent
  | ChangeColumnConfigEvent<TData>;

export class DataGridHandler<TData, TContext> {
  #context: TContext;

  #columnHandler: ColumnHandler<TData, TContext>;

  #rowHandler: RowHandler<TData, TContext>;

  #exportHandler: ExportHandler<TData, TContext>;

  #eventHandler: EventHandler<DataGridEvent<TData, TContext>>;

  #titles: DataGridTitle;

  #loading = false;

  #locale: Locale;

  constructor(context: TContext, locale: Locale) {
    this.#context = context;
    this.#locale = locale;
    this.#titles = { tableTitle: '', title: '' };
    this.#eventHandler = new EventHandler<DataGridEvent<TData, TContext>>();
    this.#columnHandler = new ColumnHandler(this.#context, this.#eventHandler, locale);
    this.#rowHandler = new RowHandler([], this.#columnHandler, this.#eventHandler);
    this.#exportHandler = new ExportHandler(this.#columnHandler, this.#rowHandler);
  }

  get titles() {
    return this.#titles;
  }

  get columns() {
    return this.#columnHandler.columns;
  }

  get columnsHidden() {
    return this.#columnHandler.hidden;
  }

  get sorting() {
    return this.#rowHandler.sorting;
  }

  get totals() {
    return this.#rowHandler.totals;
  }

  get loading() {
    return this.#loading;
  }

  get actions() {
    return this.#columnHandler.actions;
  }

  get context() {
    return Object.freeze(this.#context);
  }

  get locale() {
    return this.#locale;
  }

  getVisibleColumns() {
    return this.#columnHandler.getVisibleColumns();
  }

  getRows(visibleColumns = false) {
    return this.#rowHandler.getRows(visibleColumns);
  }

  addColumn<TIterator = unknown>(config: ColumnConfig<TData, TContext, TIterator>) {
    this.#columnHandler.add(config);
  }

  addAction(action: ActionComponentType<TData, TContext> | ColumnAction<TData, TContext>) {
    if ('Button' in action) this.#columnHandler.addAction(action);
    else this.#columnHandler.addAction({ Button: action, isVisible: () => true });
  }

  setData(data: TData[]) {
    this.#rowHandler.setData(data);
  }

  debugConfig() {
    console.group('Column Config');
    console.log(this.#context);
    this.#columnHandler.columns.map(i =>
      console.log([i.id, i.getTitle(), i.getOrder(), i.isAvailable() ? 'Y' : 'N', ''].join(';'))
    );
    console.groupEnd();
  }

  setContext(context: TContext) {
    if (this.#context !== context) {
      this.#context = context;
      this.#columnHandler.setContext(context);
      this.#rowHandler.setRows();
      this.#eventHandler.notify({
        type: 'context',
        context,
        rows: [...this.#rowHandler.getRows()],
        columns: [...this.#columnHandler.columns],
      });
      if (process.env.NODE_ENV === 'development') {
        this.debugConfig();
      }
    }
  }

  setTitle(title: string | DataGridTitle) {
    const titles = typeof title === 'string' ? { title, tableTitle: title } : title;
    if (titles.title !== this.#titles.title || titles.tableTitle !== this.#titles.tableTitle) {
      this.#titles = titles;
      this.#eventHandler.notify({ type: 'title', titles });
    }
  }

  setLoading(loading: boolean) {
    if (loading !== this.#loading) {
      this.#loading = loading;
      this.#eventHandler.notify({ type: 'loading', loading });
    }
  }

  toggleColumnVisibility(id: string | string[]) {
    this.#columnHandler.toggleVisibility(Array.isArray(id) ? id : [id]);
    this.#rowHandler.setRows();
  }

  getColumnById(id: string) {
    return this.#columnHandler.getById(id);
  }

  setSorting(columnId: string | undefined, ascending: boolean | undefined = undefined) {
    this.#rowHandler.setSorting(columnId, ascending);
  }

  addEventListener<
    ET extends DataGridEvent<TData, TContext>['type'],
    T extends Assignable<ET, DataGridEvent<TData, TContext>['type']>,
    EA extends Extract<DataGridEvent<TData, TContext>, { type: T }>
  >(eventType: ET, listener: (event: EA) => void) {
    return this.#eventHandler.addListener(eventType, listener as never);
  }

  setExportConfig(config: ExportConfig) {
    this.#exportHandler.setConfig(config);
  }

  exportHasDispatchAction(format: ExportFormat) {
    return this.#exportHandler.hasDispatchAction(format);
  }

  export(format: ExportFormat) {
    this.#exportHandler.export(format, this.titles.title, this.titles.title);
  }

  updateColumnIterator<TIterator>(columnId: string, iterator: TIterator[]) {
    this.#columnHandler.updateIterator(columnId, iterator);
    this.#rowHandler.setRows();
    this.#eventHandler.notify({
      type: 'columnsConfig',
      rows: [...this.#rowHandler.getRows()],
      columns: [...this.#columnHandler.columns],
    });
  }
}
