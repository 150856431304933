import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ComponentType } from 'react';

export interface DataFlexSalesActionButtonProps {
  icon: ComponentType;
  label?: string;
  onClick: () => void;
  loading?: boolean;
}

const iconStyles = { verticalAlign: 'middle', padding: 0, with: 18, height: 18 };

const progressProps = { style: { verticalAlign: 'middle' }, size: 14 };

const DataFlexSalesActionButton = ({
  label,
  onClick,
  icon: Icon,
  loading,
}: DataFlexSalesActionButtonProps) => {
  if (loading) return <CircularProgress {...progressProps} />;
  const button = (
    <IconButton size="small" aria-label={label} style={iconStyles} onClick={onClick}>
      <Icon />
    </IconButton>
  );

  if (label) return <Tooltip title={label}>{button}</Tooltip>;
  return button;
};

export default DataFlexSalesActionButton;
