import { Card, CardContent, Typography, Divider } from '@mui/material';
import MasterContent from './MasterContent';

const MasterImport = () => {
  const title = 'Importación de Maestros';

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle2">{title}</Typography>
        <Divider />
        <MasterContent />
      </CardContent>
    </Card>
  );
};

export default MasterImport;
