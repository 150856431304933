import { Reducer } from 'redux';
import {
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

import { Actions, State, ActionType } from './types';

const initialState: State = {
  masterData: remoteResourceInitialState(undefined),
  activeCountries: remoteResourceInitialState([]),
  parameters: remoteResourceInitialState(undefined),
};

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.masterDataRequested:
      return {
        ...state,
        masterData: remoteResourceLoading(undefined),
      };
    case ActionType.masterDataSuccess:
      return {
        ...state,
        masterData: remoteResourceLoadSuccess(action.masterData),
      };
    case ActionType.masterDataFailure:
      return {
        ...state,
        masterData: remoteResourceLoadFailure(undefined, action.error),
      };
    case ActionType.activeCountriesRequested:
      return {
        ...state,
        activeCountries: remoteResourceLoading([]),
      };
    case ActionType.activeCountriesSuccess:
      return {
        ...state,
        activeCountries: remoteResourceLoadSuccess(action.activeCountries),
      };
    case ActionType.activeCountriesFailure:
      return {
        ...state,
        activeCountries: remoteResourceLoadFailure([], action.error),
      };

    case ActionType.parametersRequested:
      return {
        ...state,
        parameters: remoteResourceLoading(undefined),
      };
    case ActionType.parametersSuccess:
      return {
        ...state,
        parameters: remoteResourceLoadSuccess(action.parameters),
      };
    case ActionType.parametersFailure:
      return {
        ...state,
        parameters: remoteResourceLoadFailure(undefined, action.error),
      };

    default:
      return state;
  }
};

export default reducer;
