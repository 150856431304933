import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ReactNode, useCallback, useState } from 'react';
import { ClosingStatus, RemoteResource } from '../../../types';

import IncorporationActions, { IncorporationActionsProps } from './IncorporationActions';
import IncorporationContent, { IncorporationContentProps } from './IncorporationContent';

interface IncorporationCardProps extends Omit<IncorporationActionsProps, 'disabled'> {
  title: ReactNode;
  resource: RemoteResource<ClosingStatus[]>;
  name: IncorporationContentProps['name'];
}

const IncorporationCard = ({
  title,
  resource,
  onChangeIntervalMode,
  onChangeItemMode,
  initValueItemMode,
  initValueIntervalMode,
  name,
}: IncorporationCardProps) => {
  const [itemMode, setItemMode] = useState<IncorporationContentProps['itemMode']>('error');
  const handleChangeItemMode: NonNullable<IncorporationActionsProps['onChangeItemMode']> =
    useCallback(
      value => {
        setItemMode(value);
        if (onChangeItemMode) onChangeItemMode(value);
      },
      [onChangeItemMode]
    );

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle2">{title}</Typography>
        <Divider />
        <IncorporationContent itemMode={itemMode} resource={resource} name={name} />
        <Divider />
        <IncorporationActions
          onChangeIntervalMode={onChangeIntervalMode}
          onChangeItemMode={handleChangeItemMode}
          initValueItemMode={initValueItemMode}
          initValueIntervalMode={initValueIntervalMode}
          disabled={false}
        />
      </CardContent>
    </Card>
  );
};

export default IncorporationCard;
