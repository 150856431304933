import styled from '@emotion/styled';
import { ReportTenderDataGrid, ReportTenderSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const ReportTender = () => (
  <Container>
    <ReportTenderSearch />
    <ReportTenderDataGrid />
  </Container>
);

export default ReportTender;
