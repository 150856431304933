import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  ActionType,
  AccountingParametersEditRequestedAction,
  AccountingParametersGetRequestedAction,
} from './types';
import { SalesApi, FindAccountingParametersResponse } from '../../services';
import {
  accountingParametersGetFailure,
  accountingParametersGetSuccess,
  accountingParametersFindFailure,
  accountingParametersFindSuccess,
  accountingParametersEditSuccess,
  accountingParametersEditFailure,
} from './actionCreators';
import { Country, HttpRequestError } from '../../types';
import {
  accountingParametersEditRequestMap,
  findAccountingParametersResponseMap,
} from '../../helpers';
import { accountingParametersCountrySelector } from '../selectors';

function* findAccountingParameters() {
  const { countryId }: Country = yield select(accountingParametersCountrySelector);
  try {
    const { data }: AxiosResponse<FindAccountingParametersResponse> = yield call(
      SalesApi.findAccountingParameters,
      {
        countryId,
      }
    );

    yield put(accountingParametersFindSuccess(findAccountingParametersResponseMap(data)));
  } catch (error) {
    yield put(accountingParametersFindFailure(error as HttpRequestError));
  }
}

function* getAccountingParameters(action: AccountingParametersGetRequestedAction) {
  try {
    const { data }: AxiosResponse<FindAccountingParametersResponse> = yield call(
      SalesApi.findAccountingParameters,
      {
        countryId: action.countryId,
        journalType: action.journalType,
      }
    );

    yield put(
      accountingParametersGetSuccess(findAccountingParametersResponseMap(data).find(Boolean))
    );
  } catch (error) {
    yield put(accountingParametersGetFailure(error as HttpRequestError));
  }
}

function* editCompanyCode({ payload }: AccountingParametersEditRequestedAction) {
  try {
    yield call(SalesApi.editAccountingParameters, accountingParametersEditRequestMap(payload));
    yield put(accountingParametersEditSuccess());
  } catch (error) {
    yield put(accountingParametersEditFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(ActionType.accountingParametersFindRequested, findAccountingParameters),
  takeLatest(ActionType.accountingParametersGetRequested, getAccountingParameters),
  takeLatest(ActionType.accountingParametersEditRequested, editCompanyCode),
];
