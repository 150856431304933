import styled from '@emotion/styled';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { format } from 'date-fns';
import {
  changeMonth,
  findMonthlySalesRequested,
  dashboardMonthlySalesMonthSelector,
} from '../../../../state';
import { useMuiPickersLocale } from '../../../../hooks';
import { MonthlySalesDataGrid } from './MonthlySalesDataGrid';

const yesterdayDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

const lastMonthLastDay = new Date();
lastMonthLastDay.setDate(0);

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15%;
`;

const GridContainer = styled(Grid)`
  height: 150px;
  width: 100%;
`;

interface ButtonProps {
  onClick: () => void;
  disabled: boolean;
}

interface TitleProps {
  isCurrentMonth: boolean;
  month: Date;
}

const PreviousButton = ({ onClick, disabled }: ButtonProps) => (
  <Button onClick={onClick} disabled={disabled}>
    <NavigateBeforeIcon />
  </Button>
);
const NextButton = ({ onClick, disabled }: ButtonProps) => (
  <Button onClick={onClick} disabled={disabled}>
    <NavigateNextIcon />
  </Button>
);

const Title = ({ isCurrentMonth, month }: TitleProps) => {
  const { t } = useTranslation();
  const locale = useMuiPickersLocale();
  return (
    <Typography variant="subtitle2">
      {isCurrentMonth
        ? t(`dashboard.monthly-sales.title.current-month`, {
            month: format(month, 'MMMM', { locale }),
            date: format(month, 'P', { locale }),
          })
        : t(`dashboard.monthly-sales.title.other-months`, {
            month: format(month, 'MMMM', { locale }),
          })}
    </Typography>
  );
};

const MonthlySalesIncorporation = () => {
  const dispatch = useDispatch();
  const month = useSelector(dashboardMonthlySalesMonthSelector);
  const isCurrentMonth = month.getMonth() === new Date().getMonth();

  const handleChangeMonthPrevious = useCallback(() => {
    const lastMonth = new Date(month.setDate(0));

    dispatch(changeMonth(lastMonth));
    dispatch(findMonthlySalesRequested());
  }, [month, dispatch]);

  const handleChangeMonthNext = useCallback(() => {
    const nextMonthLastDay = new Date(month.getFullYear(), month.getMonth() + 2, 0);
    dispatch(
      changeMonth(
        month.getMonth() === lastMonthLastDay.getMonth() ? yesterdayDate : nextMonthLastDay
      )
    );

    dispatch(findMonthlySalesRequested());
  }, [month, dispatch]);

  return (
    <Card sx={{ height: '225px' }}>
      <CardContent sx={{ height: '100%' }}>
        <TitleContainer>
          <PreviousButton
            onClick={handleChangeMonthPrevious}
            disabled={month <= new Date('2022-02-01')}
          />
          <Title isCurrentMonth={isCurrentMonth} month={month} />
          <NextButton onClick={handleChangeMonthNext} disabled={isCurrentMonth} />
        </TitleContainer>
        <GridContainer>
          <MonthlySalesDataGrid />
        </GridContainer>
      </CardContent>
    </Card>
  );
};

export default MonthlySalesIncorporation;
