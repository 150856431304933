import { HttpRequestError, CompanyCode } from '../../types';
import {
  ActionType,
  CompanyCodesGetFailureAction,
  CompanyCodesGetRequestedAction,
  CompanyCodesGetSuccessAction,
  CompanyCodesChangeFilterCountryAction,
  CompanyCodesFindFailureAction,
  CompanyCodesFindRequestedAction,
  CompanyCodesFindSuccessAction,
  CompanyCodesEditSuccessAction,
  CompanyCodesEditFailureAction,
} from './types';

export const companyCodesChangeFilterCountry = (
  countryId: string
): CompanyCodesChangeFilterCountryAction => ({
  type: ActionType.companyCodesChangeFilterCountry,
  countryId,
});

export const companyCodesFindRequested = (): CompanyCodesFindRequestedAction => ({
  type: ActionType.companyCodesFindRequested,
});

export const companyCodesFindSuccess = (
  companyCodes: CompanyCode[]
): CompanyCodesFindSuccessAction => ({
  type: ActionType.companyCodesFindSuccess,
  companyCodes,
});

export const companyCodesFindFailure = (
  error: HttpRequestError
): CompanyCodesFindFailureAction => ({
  type: ActionType.companyCodesFindFailure,
  error,
});

export const companyCodesGetRequested = (
  countryId: string,
  companyId: number
): CompanyCodesGetRequestedAction => ({
  type: ActionType.companyCodesGetRequested,
  countryId,
  companyId,
});

export const companyCodesGetSuccess = (companyCode: CompanyCode): CompanyCodesGetSuccessAction => ({
  type: ActionType.companyCodesGetSuccess,
  companyCode,
});

export const companyCodesGetFailure = (error: HttpRequestError): CompanyCodesGetFailureAction => ({
  type: ActionType.companyCodesGetFailure,
  error,
});

export const companyCodesEditSuccess = (): CompanyCodesEditSuccessAction => ({
  type: ActionType.companyCodesEditSuccess,
});

export const companyCodesEditFailure = (
  error: HttpRequestError
): CompanyCodesEditFailureAction => ({
  type: ActionType.companyCodesEditFailure,
  error,
});
