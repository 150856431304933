import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  oakBrookOakBrookLoadingSelector,
  oakBrookFindRequested,
  oakBrookOakBrookLoadedSelector,
  oakBrookDownloadRequested,
  oakBrookFilterSelector,
} from '../../state';
import { LoadingButton } from '../buttons';
import { OakBrookFilters } from './OakBrookFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button {
    margin-right: ${props => props.theme.spacing(2)};
  }
  > button:last-child {
    margin-right: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(() => dispatch(oakBrookFindRequested()), [dispatch]);
  const { t } = useTranslation();
  const loading = useSelector(oakBrookOakBrookLoadingSelector);
  const filters = useSelector(oakBrookFilterSelector);
  const disabled = loading || !filters.country || !filters.month.accept;
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={disabled}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const ButtonDownload = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(() => dispatch(oakBrookDownloadRequested()), [dispatch]);
  const { t } = useTranslation();
  const loaded = useSelector(oakBrookOakBrookLoadedSelector);
  return (
    <Button color="primary" variant="contained" onClick={handleClickApply} disabled={!loaded}>
      {t('navigation.buttons.download')}
    </Button>
  );
};

const OakBrookSearch = () => {
  const actions = (
    <ActionContainer>
      <ButtonDownload />
      <ButtonApply />
    </ActionContainer>
  );
  return <OakBrookFilters actions={actions} />;
};

export default OakBrookSearch;
