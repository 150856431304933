import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountingEntriesFilterJournalTypeSelector,
  accountingEntriesChangeFilterJournalType,
} from '../../../state';
import { SelectFlexSales, SimpleSelectProps } from '../../selects';

type StatusFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const types = ['VENTA_PRODUCTO', 'VENTA_NO_PROD'];

const JournalTypeFilter = (props: StatusFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector(accountingEntriesFilterJournalTypeSelector);
  const handleChange = useCallback(
    (value: string | null) => dispatch(accountingEntriesChangeFilterJournalType(value)),
    [dispatch]
  );

  return (
    <SelectFlexSales
      {...props}
      label={t('accountingEntries.columns.journalType')}
      onChange={handleChange}
      value={value}
      options={types}
    />
  );
};

export default JournalTypeFilter;
