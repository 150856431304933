import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  dashboardMastersStatusMastersStatusDetailsSelector,
  findMastersStatusMastersStatusDetailsRequested,
} from '../state';
import { useFlexNavigate } from '../hooks';
import { DialogFlexSales, MastersStatusDataGrid } from '../components';
import { dateResponseMap } from '../helpers';

const DialogContentStyled = styled(DialogContent)`
  width: 55em;
`;

const MissingClosingDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const importDate = searchParams.get('importDate');
  const navigate = useFlexNavigate();
  const pageTitle = t('dashboard.incorporation.titles.mastersStatus');

  const resource = useSelector(dashboardMastersStatusMastersStatusDetailsSelector);

  const handleBack = useCallback(() => {
    navigate(r => r.protectedNestedRoutes.dashboard.index.path);
  }, [navigate]);

  useEffect(() => {
    if (importDate)
      dispatch(findMastersStatusMastersStatusDetailsRequested(dateResponseMap(importDate) as Date));
  }, [dispatch, importDate]);

  return (
    <DialogFlexSales open maxWidth="lg">
      <DialogTitle>{pageTitle}</DialogTitle>
      <DialogContentStyled>
        <MastersStatusDataGrid resource={resource} />
      </DialogContentStyled>
      <DialogActions>
        <Button onClick={handleBack}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default MissingClosingDetail;
