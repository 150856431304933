import { useSelector } from 'react-redux';
import { SimpleSelectOptionFormatter, useSimpleSelectOptionMapper } from '../components';
import { usePermissions } from '../components/Permissions';
import { getCountriesForPermissions } from '../helpers';
import { activeCountriesSelector, userPermissionsDataSelector } from '../state';
import { Country } from '../types';

const countryFormatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'operativeStructure.countries',
  sort: 'asc',
};

const useContrySelect = (propId: keyof Country = 'countryCode') => {
  const { resourcePermissions } = usePermissions();
  const activeCountries = useSelector(activeCountriesSelector);
  const permissions = useSelector(userPermissionsDataSelector);
  const countries = activeCountries.loaded && activeCountries.data ? activeCountries.data : [];

  const countriesForPermissions = getCountriesForPermissions(
    resourcePermissions,
    permissions,
    countries
  );

  const disabled = !activeCountries.loaded || countriesForPermissions.length < 2;

  const options = useSimpleSelectOptionMapper(
    countriesForPermissions,
    propId,
    'countryCode',
    countryFormatter
  );
  return { options, disabled, loading: activeCountries.loading };
};

export default useContrySelect;
