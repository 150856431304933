import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterSegments,
  reportFilterCountryCodeSelector,
  reportFilterSegmentsSelector,
} from '../../../state';
import { PosType } from '../../../types';

import SegmentsSelect from '../../selects/SegmentsSelect';

const SegmentsFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const segmentsSelected = useSelector(reportFilterSegmentsSelector);
  const disabled = !countryCode;
  const handleChange = useCallback(
    (value: PosType[]) => dispatch(changeFilterSegments(value)),
    [dispatch]
  );

  return (
    <SegmentsSelect
      label={t('toolbar.labels.pos-type')}
      value={segmentsSelected}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default SegmentsFilter;
