import * as yup from 'yup';
import setDate from 'date-fns/setDate';
import { CloseMonthForm } from '../../types';

export const minDate = new Date(2015, 0, 1);
export const maxDate = setDate(new Date(), 0);

export const getSchema = (): yup.ObjectSchema<CloseMonthForm> =>
  yup.object().shape({
    country: yup.string().required(),
    createdBy: yup.string().required(),
    accountingMonth: yup.date().required().min(minDate).max(maxDate),
  });
