import { Action } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';
import { HttpErrorBaseAction } from '../../types';
import { httpError, validationErrors } from './actionCreators';

// [{"field": null, "code": "STORE_BUSINESS_DATE_OPEN"}]
function* handleRequestErrors(actions: HttpErrorBaseAction<unknown>) {
  if (actions.error.code === 400 && actions.error.data && Array.isArray(actions.error.data)) {
    const errors = actions.error.data.map(i => ({ field: i.field, code: i.code }));
    yield put(validationErrors(errors));
  } else {
    yield put(httpError(actions.error));
  }
}

export default [takeLatest((action: Action) => /Failure$/i.test(action.type), handleRequestErrors)];
