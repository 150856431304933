import { HttpRequestError, MonthClosing } from '../../types';
import {
  ActionType,
  MonthClosingAnnulFailureAction,
  MonthClosingAnnulSuccessAction,
  MonthClosingChangeAnnulAction,
  MonthClosingChangeFilterCountryAction,
  MonthClosingCloseFailureAction,
  MonthClosingCloseSuccessAction,
  MonthClosingFindFailureAction,
  MonthClosingFindRequestedAction,
  MonthClosingFindSuccessAction,
} from './types';

export const monthClosingChangeFilterCountry = (
  country: string
): MonthClosingChangeFilterCountryAction => ({
  type: ActionType.monthClosingChangeFilterCountry,
  country,
});

export const monthClosingFindRequested = (): MonthClosingFindRequestedAction => ({
  type: ActionType.monthClosingFindRequested,
});

export const monthClosingFindSuccess = (
  monthClosing: MonthClosing[]
): MonthClosingFindSuccessAction => ({
  type: ActionType.monthClosingFindSuccess,
  monthClosing,
});

export const monthClosingFindFailure = (
  error: HttpRequestError
): MonthClosingFindFailureAction => ({
  type: ActionType.monthClosingFindFailure,
  error,
});

export const monthClosingCloseSuccess = (): MonthClosingCloseSuccessAction => ({
  type: ActionType.monthClosingCloseSuccess,
});

export const monthClosingCloseFailure = (
  error: HttpRequestError
): MonthClosingCloseFailureAction => ({
  type: ActionType.monthClosingCloseFailure,
  error,
});

export const monthClosingChangeAnnul = (
  monthClosing: MonthClosing | undefined
): MonthClosingChangeAnnulAction => ({
  type: ActionType.monthClosingChangeAnnul,
  monthClosing,
});

export const monthClosingAnnulSuccess = (): MonthClosingAnnulSuccessAction => ({
  type: ActionType.monthClosingAnnulSuccess,
});

export const monthClosingAnnulFailure = (
  error: HttpRequestError
): MonthClosingAnnulFailureAction => ({
  type: ActionType.monthClosingAnnulFailure,
  error,
});
