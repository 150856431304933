import { ReactNode, useMemo, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { createSearchParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ClosingStatus, RemoteResource } from '../../../types';
import routes from '../../../config/routes';
import { dateRequestMap, handleErrorPercent } from '../../../helpers';
import { activeCountriesSelector, masterDataSelector } from '../../../state';

export interface IncorporationContentProps {
  resource: RemoteResource<ClosingStatus[]>;
  itemMode: 'error' | 'ok';
  name: 'sales' | 'collections';
}

const getPercent = (closeToGet: number, otherClose: number) => {
  const total = closeToGet + otherClose;
  if (total === 0) {
    return 0;
  }
  const result = (closeToGet * 100) / total;
  const rounded = Math.round((result + Number.EPSILON) * 100) / 100;
  return rounded;
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemsContainer = styled(Stack)`
  padding-top: ${prop => prop.theme.spacing(2)};
  padding-bottom: ${prop => prop.theme.spacing(1)};
`;

const CountryContainer = styled.div`
  display: flex;
  align-items: center;
  > a {
    width: 100%;
  }
`;

interface DetailLinkProps {
  children: ReactNode;
  country: string;
  startDate: Date;
  endDate: Date;
  viewMode: string;
}

const DetailLink = ({ country, startDate, endDate, viewMode, children }: DetailLinkProps) => {
  const params = {
    country,
    viewMode,
    startDate: dateRequestMap(startDate) as string,
    endDate: dateRequestMap(endDate) as string,
  };
  const to = {
    pathname: routes.protectedNestedRoutes.missingClosingDetail.index.path,
    search: `?${createSearchParams(params)}`,
  };
  return <StyledLink to={to}>{children}</StyledLink>;
};

const IncorporationSkeleton = () => (
  <>
    <Skeleton />
    <Skeleton />
  </>
);

const IncorporationError = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" color="error" padding={1} spacing={1}>
      <ErrorIcon color="error" />
      <Typography variant="body1" color="error">
        {t('errors.generics.loadingData')}
      </Typography>
    </Stack>
  );
};

interface CountryOptionsProps {
  country: string;
  viewMode: string;
}

const CountryOptions = ({ country, viewMode }: CountryOptionsProps) => {
  const idButton = `${country}-${viewMode}-options-button`;
  const idMenu = `${country}-${viewMode}-options-menu`;
  const menuListProps = {
    'aria-labelledby': idButton,
  };
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick: IconButtonProps['onClick'] = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const toMissingList = useMemo(
    () => ({
      pathname: routes.protectedNestedRoutes.dashboard.children.dashboardMissingClosingDetail.path,
      search: `?${createSearchParams({ country, viewMode })}`,
    }),
    [country, viewMode]
  );

  return (
    <>
      <IconButton
        id={idButton}
        aria-label="more"
        aria-controls={open ? idMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={idMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
      >
        <MenuItem component={Link} to={toMissingList} onClick={handleClose}>
          {t('dashboard.incorporation.titles.missingList')}
        </MenuItem>
      </Menu>
    </>
  );
};

const IncorporationContent = ({ resource, itemMode, name }: IncorporationContentProps) => {
  const { t } = useTranslation();
  const masterDataResource = useSelector(masterDataSelector);
  const activeCountriesResource = useSelector(activeCountriesSelector);
  const { data, loaded, error } = resource;
  const loading = resource.loading || masterDataResource.loading || activeCountriesResource.loading;
  return (
    <ItemsContainer spacing={1}>
      {!loading &&
        !error &&
        loaded &&
        data.map(i => {
          const value = itemMode === 'error' ? i.closeError : i.closeOk;
          const other = itemMode === 'error' ? i.closeOk : i.closeError;
          const percentage = getPercent(value, other);
          return (
            <CountryContainer key={i.country}>
              <CountryOptions country={i.country} viewMode={name} />
              <DetailLink country={i.country} startDate={i.fromDt} endDate={i.toDt} viewMode={name}>
                <div>{t(`operativeStructure.countries.${i.country}`)}</div>
                <Typography variant="body1" color={handleErrorPercent(itemMode, percentage)}>
                  <Box component="span" fontWeight="bold">
                    {value} ({percentage}%)
                  </Box>
                </Typography>
              </DetailLink>
            </CountryContainer>
          );
        })}
      {!loading && error && <IncorporationError />}
      {loading && <IncorporationSkeleton />}
    </ItemsContainer>
  );
};

export default IncorporationContent;
