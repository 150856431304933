import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  masterDataSelector,
  monthClosingFilterCountrySelector,
  monthClosingChangeFilterCountry,
} from '../../../state';
import { CountrySelect, SimpleSelectProps } from '../../selects';

type CountryFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const CountryFilter = (props: CountryFilterProps) => {
  const dispatch = useDispatch();
  const masterData = useSelector(masterDataSelector);
  const countryId = useSelector(monthClosingFilterCountrySelector);

  const handleChange = useCallback(
    (value: string) => dispatch(monthClosingChangeFilterCountry(value)),
    [dispatch]
  );

  useEffect(() => {
    const countries = masterData.loaded && masterData.data ? masterData.data.countries : [];
    if (countries.length === 1) handleChange(countries[0].countryCode);
  }, [handleChange, masterData.data, masterData.loaded]);

  return (
    <CountrySelect
      {...props}
      onChange={handleChange}
      value={countryId}
      propId="countryCode"
      simple
    />
  );
};

export default CountryFilter;
