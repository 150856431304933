import styled from '@emotion/styled';
import {
  Badge,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { OpStructuresFilters } from './OpStructuresFilters';
import {
  changeOpStrucuresFilterAgreement,
  changeOpStrucuresFilterCompany,
  changeOpStrucuresFilterManagement,
  changeOpStrucuresFilterRegion,
  changeOpStrucuresFilterRegionalManagement,
  changeOpStrucuresFilterSupervision,
  reportOpStructuresFilterSelector,
} from '../../state';
import { useReportFilters } from './ReportFiltersProvider';
import DialogFlexSales from '../DialogFlexSales';

const DialogContentStyled = styled(DialogContent)<{ width?: string }>`
  ${({ width }) =>
    width &&
    `
  tbody {
    width: ${width};
  }
  `}
`;

export const resetOpStructuresFilters = (dispatch: Dispatch<AnyAction>) => {
  dispatch(changeOpStrucuresFilterAgreement([]));
  dispatch(changeOpStrucuresFilterRegionalManagement([]));
  dispatch(changeOpStrucuresFilterRegion([]));
  dispatch(changeOpStrucuresFilterManagement([]));
  dispatch(changeOpStrucuresFilterSupervision([]));
  dispatch(changeOpStrucuresFilterCompany([]));
};

const OpStructuresDialog = () => {
  const { t } = useTranslation();
  const { showOpStructureFilters } = useReportFilters();
  const [open, setOpen] = React.useState(false);
  const title = t('dialogs.operativeFilters.title');
  const [numberOfOperativeFilters, setNumberOfOperativeFilters] = useState(0);
  const opStructures = useSelector(reportOpStructuresFilterSelector);

  useEffect(() => {
    let newNumber = 0;
    if (opStructures.agreement.length > 0) {
      newNumber += 1;
    }
    if (opStructures.regionalMgmt.length > 0) {
      newNumber += 1;
    }
    if (opStructures.region.length > 0) {
      newNumber += 1;
    }
    if (opStructures.management.length > 0) {
      newNumber += 1;
    }
    if (opStructures.supervision.length > 0) {
      newNumber += 1;
    }
    if (opStructures.company.length > 0) {
      newNumber += 1;
    }
    setNumberOfOperativeFilters(newNumber);
  }, [
    opStructures.agreement.length,
    opStructures.company.length,
    opStructures.management.length,
    opStructures.region.length,
    opStructures.regionalMgmt.length,
    opStructures.supervision.length,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleRestore = useCallback(() => {
    resetOpStructuresFilters(dispatch);
    setOpen(false);
  }, [dispatch]);

  return showOpStructureFilters ? (
    <>
      <Tooltip title={title}>
        <Badge badgeContent={numberOfOperativeFilters} color="primary">
          <Button color="primary" variant="contained" onClick={handleClickOpen}>
            <DomainIcon />
          </Button>
        </Badge>
      </Tooltip>
      <DialogFlexSales onClose={handleClose} open={open} maxWidth="xs">
        <DialogTitle>{t('dialogs.operativeFilters.title')}</DialogTitle>
        <DialogContentStyled>
          <DialogContentText>{t('dialogs.operativeFilters.contentText')}</DialogContentText>
          <OpStructuresFilters />
        </DialogContentStyled>
        <DialogActions>
          <Tooltip title={t('navigation.buttons.cleanFilters') as string}>
            <Button onClick={handleRestore} variant="contained">
              <FilterListOffIcon />
            </Button>
          </Tooltip>
          <Button onClick={handleClose}>{t('navigation.buttons.close')}</Button>
        </DialogActions>
      </DialogFlexSales>
    </>
  ) : null;
};

export default OpStructuresDialog;
