import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reportFilterCountryCodeSelector } from '../../state';
import { LoadingButton } from '../buttons';
import { useReportFilters } from './ReportFiltersProvider';

interface ApplyButtonProps {
  disabled?: boolean;
}

const ApplyButton = ({ disabled }: ApplyButtonProps) => {
  const dispatch = useDispatch();
  const { loadingSelector, applyAction } = useReportFilters();

  const handleClickApply = useCallback(() => {
    const action = applyAction();
    const actions = Array.isArray(action) ? action : [action];
    actions.forEach(a => dispatch(a));
  }, [dispatch, applyAction]);

  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const hasCountry = Boolean(useSelector(reportFilterCountryCodeSelector));
  const buttonDisabled = disabled ?? !hasCountry;

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={buttonDisabled}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

export default ApplyButton;
