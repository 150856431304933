import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterHourRange,
  reportFilterHourRangeSelector,
  reportFilterTimeBandSelector,
  reportHourRangeSelector,
} from '../../../state';
import { HourRange } from '../../../types';
import { HourRangeSelect } from '../../selects';

const HourRangeFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hourRangeResource = useSelector(reportHourRangeSelector);
  const hourRange = useSelector(reportFilterHourRangeSelector);
  const timeBand = useSelector(reportFilterTimeBandSelector);
  const disabled = !hourRangeResource.loaded || timeBand.length > 0;
  const handleChange = useCallback(
    (value: HourRange[]) => dispatch(changeFilterHourRange(value)),
    [dispatch]
  );

  return (
    <HourRangeSelect
      label={t('toolbar.labels.hour-range')}
      onChange={handleChange}
      value={hourRange}
      resource={hourRangeResource}
      getOptionLabel="hour"
      disabled={disabled}
    />
  );
};

export default HourRangeFilter;
