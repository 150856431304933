import { Global } from '@emotion/react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Layout } from './layout';
import {
  ThemeSwitcherProvider,
  MsalReduxIntegration,
  LocalizationIntegration,
  AppErrorAlert,
  ValidationErrorAlert,
  Notifier,
  DownloadOverlay,
} from './components';
import GlobalStyles from './GlobalStyles';

// import de configs
import { msalInstance } from './config/auth.config';
import './config/i18n.config';
import store from './config/store/store';

const App = () => (
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <MsalReduxIntegration />
      <BrowserRouter>
        <LocalizationIntegration>
          <ThemeSwitcherProvider>
            <SnackbarProvider maxSnack={3}>
              <AppErrorAlert />
              <DownloadOverlay />
              <Notifier />
              <ValidationErrorAlert />
              <CssBaseline />
              <Global styles={GlobalStyles} />
              <Layout />
            </SnackbarProvider>
          </ThemeSwitcherProvider>
        </LocalizationIntegration>
      </BrowserRouter>
    </Provider>
  </MsalProvider>
);

export default App;
