import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  reportFilterCountryCodeSelector,
  reportReliefsGroupBySelector,
  reportReliefsSelector,
} from '../../state';
import handlerFactory, { ReliefsDataGridContext } from './handlerFactory';
import { Relief } from '../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<Relief, ReliefsDataGridContext>();

  const { t } = useTranslation();
  const title = t('reliefs.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const reliefsResource = useSelector(reportReliefsSelector);
  const groupBy = useSelector(reportReliefsGroupBySelector);

  const { data, loading } = reliefsResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
      groupBy,
    }),
    [t, country, groupBy]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);

  return null;
};

const ReliefsDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ReliefsDataGrid;
