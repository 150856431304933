import { Action } from 'redux';
import {
  Agreement,
  Cancellation,
  CausesFilter,
  Closing,
  ClosingFilter,
  ClosingState,
  ClosingViewMode,
  Company,
  Country,
  Discount,
  DiscountsJoin,
  GCsByProduct,
  Gcs,
  GcsByPaymentTypes,
  HourRange,
  HttpErrorBaseAction,
  Management,
  MastersImport,
  MastersImportDetailFilter,
  MissingCauses,
  MissingClosingCausesData,
  MissingClosingCollectionsCauses,
  MissingClosingSalesCauses,
  MissingClosingTotal,
  OpStructuresFilter,
  OperationType,
  PaymentType,
  PettyCashDifference,
  PettyCashDifferenceByPaymentType,
  PodOptionType,
  PosType,
  Region,
  RegionalManagement,
  Relief,
  RemoteRequest,
  RemoteResource,
  ReportCancellationsGroupBy,
  ReportFilter,
  ReportFilterGroupBy,
  ReportFilterTenderGroupBy,
  ReportReliefsGroupBy,
  ReportThirdPartySalesByItemsGroupBy,
  Sale,
  SaleType,
  SalesByProduct,
  SalesJournal,
  SalesJournalFcs,
  SalesJournalGcs,
  SalesJournalNcs,
  SalesJournalThirdParty,
  Stores,
  Supervision,
  TenderReport,
  ThirdPartySalesByItems,
  TimeBand,
} from '../../types';

export interface State {
  filters: ReportFilter;
  filterApplied: (keyof ReportFilter)[];
  opStructuresFilters: OpStructuresFilter;
  mastersImportFilters: MastersImportDetailFilter;
  closingFilters: ClosingFilter;
  posOptions: RemoteResource<string[]>;
  salesTypesOptions: RemoteResource<SaleType[]>;
  hourRangeOptions: RemoteResource<HourRange[]>;
  timeBandOptions: RemoteResource<TimeBand[]>;
  paymentTypeOptions: RemoteResource<PaymentType[]>;
  operationTypeOptions: RemoteResource<OperationType[]>;
  sales: RemoteResource<Sale[]>;
  gcs: RemoteResource<Gcs[]>;
  tender: RemoteResource<TenderReport[]>;
  gcsPaymentTypes: RemoteResource<GcsByPaymentTypes[]>;
  closing: ClosingState;
  causeTypesOptions: RemoteResource<MissingCauses[]>;
  missingClosingCauses: MissingClosingCausesData;
  causesFilters: CausesFilter;
  missingClosingTotal: MissingClosingTotal;
  reliefs: RemoteResource<Relief[]>;
  cancellations: RemoteResource<Cancellation[]>;
  salesByProduct: RemoteResource<SalesByProduct[]>;
  gcsByProduct: RemoteResource<GCsByProduct[]>;
  thirdPartySalesByItems: RemoteResource<ThirdPartySalesByItems[]>;
  saleDiscountOptions: RemoteResource<Discount[]>;
  itemDiscountOptions: RemoteResource<Discount[]>;
  pettyCashDifference: RemoteResource<PettyCashDifference[]>;
  PettyCashDifferenceByPaymentType: RemoteResource<PettyCashDifferenceByPaymentType[]>;
  salesJournal: SalesJournal;
  mastersImport: RemoteResource<MastersImport[]>;
  mastersImportExecution: RemoteRequest;
}

export enum ActionType {
  changeFilterCountry = 'report/changeFilterCountry',
  changeFilterStore = 'report/changeFilterStore',
  changeFilterPos = 'report/changeFilterPos',
  changeFilterSalesTypes = 'report/changeFilterSalesTypes',
  changeFilterPaymentTypes = 'report/changeFilterPaymentTypes',
  changeFilterHourRange = 'report/changeFilterHourRange',
  changeFilterTimeBand = 'report/changeFilterTimeBand',
  changeFilterSaleDiscount = 'report/changeFilterSaleDiscount',
  changeFilterItemDiscount = 'report/changeFilterItemDiscount',
  changeFilterSegments = 'report/changeFilterSegments',
  changeFilterStartBusinessDate = 'report/changeFilterStartBusinessDate',
  changeFilterEndBusinessDate = 'report/changeFilterEndBusinessDate',
  changeFilterStartOperationDate = 'report/changeFilterStartOperationDate',
  changeFilterEndOperationDate = 'report/changeFilterEndOperationDate',
  changeFilterStartAccountingDate = 'report/changeFilterStartAccountingDate',
  changeFilterEndAccountingDate = 'report/changeFilterEndAccountingDate',
  changeFilterOperationType = 'report/changeFilterOperationType',
  changeFilterTenderName = 'report/changeFilterTenderName',
  changeFilterGroupBy = 'report/changeFilterGroupBy',
  changeFilterDifferenceType = 'report/changeFilterDifferenceType',
  changeSaleProductFilterGroupBy = 'report/changeSaleProductFilterGroupBy',
  changeFindThirdPartySalesByItemsFilterGroupBy = 'report/changeFindThirdPartySalesByItemsFilterGroupBy',
  changeFilterDiscountsJoin = 'report/changeFilterDiscountsJoin',
  findPosRequested = 'report/findPosRequested',
  findPosSuccess = 'report/findPosSuccess',
  findPosFailure = 'report/findPosFailure',
  findSaleDiscountsRequested = 'report/findSaleDiscountsRequested',
  findSaleDiscountsSuccess = 'report/findSaleDiscountsSuccess',
  findSaleDiscountsFailure = 'report/findSaleDiscountsFailure',
  findItemDiscountsRequested = 'report/findItemDiscountsRequested',
  findItemDiscountsSuccess = 'report/findItemDiscountsSuccess',
  findItemDiscountsFailure = 'report/findItemDiscountsFailure',
  findSalesTypesRequested = 'report/findSalesTypesRequested',
  findSalesTypesSuccess = 'report/findSalesTypesSuccess',
  findSalesTypesFailure = 'report/findSalesTypesFailure',
  findCauseTypesRequested = 'report/findCauseTypesRequested',
  findCauseTypesSuccess = 'report/findCauseTypesSuccess',
  findCauseTypesFailure = 'report/findCauseTypesFailure',
  findOperationTypesRequested = 'report/findOperationTypesRequested',
  findOperationTypesSuccess = 'report/findOperationTypesSuccess',
  findOperationTypesFailure = 'report/findOperationTypesFailure',
  findPaymentTypeRequested = 'report/findPaymentTypeRequested',
  findPaymentTypeSuccess = 'report/findPaymentTypeSuccess',
  findPaymentTypeFailure = 'report/findPaymentTypeFailure',
  findSalesRequested = 'report/findSalesRequested',
  findSalesSuccess = 'report/findSalesSuccess',
  findSalesFailure = 'report/findSalesFailure',
  findReportTenderRequested = 'report/findReportTenderRequested',
  findReportTenderSuccess = 'report/findReportTenderSuccess',
  findReportTenderFailure = 'report/findReportTenderFailure',
  changeFilterTenderGroupBy = 'report/changeFilterTenderGroupBy',
  findGcsRequested = 'report/findGcsRequested',
  findGcsSuccess = 'report/findGcsSuccess',
  findGcsFailure = 'report/findGcsFailure',
  findGcsByPaymentTypesRequested = 'report/findGcsByPaymentTypesRequested',
  findGcsByPaymentTypesSuccess = 'report/findGcsByPaymentTypesSuccess',
  findGcsByPaymentTypesFailure = 'report/findGcsByPaymentTypesFailure',
  findClosingSalesRequested = 'report/findClosingSalesRequested',
  findClosingSalesSuccess = 'report/findClosingSalesSuccess',
  findClosingSalesFailure = 'report/findClosingSalesFailure',
  findMissingClosingSalesRequested = 'report/findMissingClosingSalesRequested',
  findMissingClosingSalesSuccess = 'report/findMissingClosingSalesSuccess',
  findMissingClosingSalesFailure = 'report/findMissingClosingSalesFailure',
  findClosingCollectionsRequested = 'report/findClosingCollectionsRequested',
  findClosingCollectionsSuccess = 'report/findClosingCollectionsSuccess',
  findClosingCollectionsFailure = 'report/findClosingCollectionsFailure',
  findMissingClosingSalesCausesRequested = 'report/findMissingClosingSalesCausesRequested',
  findMissingClosingSalesCausesSuccess = 'report/findMissingClosingSalesCausesSuccess',
  findMissingClosingSalesCausesFailure = 'report/findMissingClosingSalesCausesFailure',
  findMissingClosingCollectionsCausesRequested = 'report/findMissingClosingCollectionsCausesRequested',
  findMissingClosingCollectionsCausesSuccess = 'report/findMissingClosingCollectionsCausesSuccess',
  findMissingClosingCollectionsCausesFailure = 'report/findMissingClosingCollectionsCausesFailure',
  changeCausesFilterCountry = 'report/changeCausesFilterCountry',
  changeCausesFilterStore = 'report/changeCausesFilterStore',
  changeCausesFilterDate = 'report/changeCausesFilterDate',
  changeCausesFilterCause = 'report/changeCausesFilterCause',
  changeOpStrucuresFilterAgreement = 'report/changeOpStrucuresFilterAgreement',
  changeOpStrucuresFilterRegionalManagement = 'report/changeOpStrucuresFilterRegionalManagement',
  changeOpStrucuresFilterRegion = 'report/changeOpStrucuresFilterRegion',
  changeOpStrucuresFilterManagement = 'report/changeOpStrucuresFilterManagement',
  changeOpStrucuresFilterSupervision = 'report/changeOpStrucuresFilterSupervision',
  changeOpStrucuresFilterCompany = 'report/changeOpStrucuresFilterCompany',
  changeFilterPlu = 'report/changeFilterPlu',
  changeFilterStoreMotherChild = 'report/changeFilterStoreMotherChild',
  changeFilterGetSaleDiscounts = 'report/changeFilterGetSaleDiscounts',
  changeFilterGetItemDiscounts = 'report/changeFilterGetItemDiscounts',
  findMissingClosingTotalRequested = 'report/findMissingClosingTotalRequested',
  findMissingClosingTotalSuccess = 'report/findMissingClosingTotalSuccess',
  findMissingClosingTotalFailure = 'report/findMissingClosingTotalFailure',
  changeMissingClosingTotalFilteredStores = 'report/changeMissingClosingTotalFilteredStores',
  findHourRangeRequested = 'report/findHourRangeRequested',
  findHourRangeSuccess = 'report/findHourRangeSuccess',
  findHourRangeFailure = 'report/findHourRangeFailure',
  findTimeBandRequested = 'report/findTimeBandRequested',
  findTimeBandSuccess = 'report/findTimeBandSuccess',
  findTimeBandFailure = 'report/findTimeBandFailure',
  findReliefsRequested = 'report/findReliefsRequested',
  findReliefsSuccess = 'report/findReliefsSuccess',
  findReliefsFailure = 'report/findReliefsFailure',
  changeReliefsGroupBy = 'report/changeReliefsGroupBy',
  findCancellationsRequested = 'report/findCancellationsRequested',
  findCancellationsSuccess = 'report/findCancellationsSuccess',
  findCancellationsFailure = 'report/findCancellationsFailure',
  changeCancellationsGroupBy = 'report/changeCancellationsGroupBy',
  findSalesByProductRequested = 'report/findSalesByProductRequested',
  findSalesByProductSuccess = 'report/findSalesByProductSuccess',
  findSalesByProductFailure = 'report/findSalesByProductFailure',
  findGCsByProductRequested = 'report/findGCsByProductRequested',
  findGCsByProductSuccess = 'report/findGCsByProductSuccess',
  findGCsByProductFailure = 'report/findGCsByProductFailure',
  findThirdPartySalesByItemsRequested = 'report/findThirdPartySalesByItemsRequested',
  findThirdPartySalesByItemsSuccess = 'report/findThirdPartySalesByItemsSuccess',
  findThirdPartySalesByItemsFailure = 'report/findThirdPartySalesByItemsFailure',
  findPettyCashDifferenceRequested = 'report/findPettyCashDifferenceRequested',
  findPettyCashDifferenceSuccess = 'report/findPettyCashDifferenceSuccess',
  findPettyCashDifferenceFailure = 'report/findPettyCashDifferenceFailure',
  findPettyCashDifferenceByPaymentTypeRequested = 'report/findPettyCashDifferenceByPaymentTypeRequested',
  findPettyCashDifferenceByPaymentTypeSuccess = 'report/findPettyCashDifferenceByPaymentTypeSuccess',
  findPettyCashDifferenceByPaymentTypeFailure = 'report/findPettyCashDifferenceByPaymentTypeFailure',
  findSalesJournalRequested = 'report/findSalesJournalRequested',
  findSalesJournalGcsSuccess = 'report/findSalesJournalGcsSuccess',
  findSalesJournalGcsFailure = 'report/findSalesJournalGcsFailure',
  findSalesJournalNcsSuccess = 'report/findSalesJournalNcsSuccess',
  findSalesJournalNcsFailure = 'report/findSalesJournalNcsFailure',
  findSalesJournalFcsSuccess = 'report/findSalesJournalFcsSuccess',
  findSalesJournalFcsFailure = 'report/findSalesJournalFcsFailure',
  findSalesJournalThirdPartySuccess = 'report/findSalesJournalThirdPartySuccess',
  findSalesJournalThirdPartyFailure = 'report/findSalesJournalThirdPartyFailure',
  changeMasterImportFilterCountry = 'report/changeMasterImportFilterCountry',
  changeMasterImportFilterStartImportDate = 'report/changeMasterImportFilterStartImportDate',
  changeMasterImportFilterEndImportDate = 'report/changeMasterImportFilterEndImportDate',
  changeMasterImportFilterState = 'report/changeMasterImportFilterState',
  changeClosingFilterCountry = 'report/changeClosingFilterCountry',
  changeClosingFilterStore = 'report/changeClosingFilterStore',
  changeClosingFilterStartBusinessDate = 'report/changeClosingFilterStartBusinessDate',
  changeClosingFilterEndBusinessDate = 'report/changeClosingFilterEndBusinessDate',
  changeClosingFilterViewMode = 'report/changeClosingFilterViewMode',
  changeClosingFilterCauseTypes = 'report/changeClosingFilterCauseTypes',
  findMastersImportRequested = 'report/findMastersImportRequested',
  findMastersImportSuccess = 'report/findMastersImportSuccess',
  findMastersImportFailure = 'report/findMastersImportFailure',
  mastersImportExecutionRequested = 'report/mastersImportExecutionRequested',
  mastersImportExecutionSuccess = 'report/mastersImportExecutionSuccess',
  mastersImportExecutionFailure = 'report/mastersImportExecutionFailure',
  changeFilterAccountingEntriesFields = 'report/changeFilterAccountingEntriesFields',
}

export interface ChangeFilterCountryAction extends Action<ActionType.changeFilterCountry> {
  countryCode: string;
}

export interface ChangeFilterStoreAction extends Action<ActionType.changeFilterStore> {
  stores: Stores[];
}

export interface ChangeFilterPosAction extends Action<ActionType.changeFilterPos> {
  pos: string[];
}

export interface ChangeFilterSalesTypesAction extends Action<ActionType.changeFilterSalesTypes> {
  salesTypes: SaleType[];
}

export interface ChangeFilterPaymentTypesAction
  extends Action<ActionType.changeFilterPaymentTypes> {
  paymentTypes: PaymentType[];
}

export interface ChangeFilterOperationTypeAction
  extends Action<ActionType.changeFilterOperationType> {
  operationType: OperationType[];
}

export interface ChangeFilterTenderNameAction extends Action<ActionType.changeFilterTenderName> {
  tenderName: string;
}

export interface ChangeFilterHourRangeAction extends Action<ActionType.changeFilterHourRange> {
  hourRange: HourRange[];
}

export interface ChangeFilterTimeBandAction extends Action<ActionType.changeFilterTimeBand> {
  timeBand: string[];
}

export interface ChangeFilterSaleDiscountAction
  extends Action<ActionType.changeFilterSaleDiscount> {
  discount: Discount[];
}

export interface ChangeFilterItemDiscountAction
  extends Action<ActionType.changeFilterItemDiscount> {
  discount: Discount[];
}

export interface ChangeFilterDifferenceTypeAction
  extends Action<ActionType.changeFilterDifferenceType> {
  differenceType: string;
}

export interface ChangeFilterSegmentsAction extends Action<ActionType.changeFilterSegments> {
  segments: PosType[];
}

export interface ChangeFilterStartBusinessDateAction
  extends Action<ActionType.changeFilterStartBusinessDate> {
  date: Date | null;
}

export interface ChangeFilterEndBusinessDateAction
  extends Action<ActionType.changeFilterEndBusinessDate> {
  date: Date | null;
}

export interface ChangeFilterStartOperationDateAction
  extends Action<ActionType.changeFilterStartOperationDate> {
  date: Date | null;
}

export interface ChangeFilterEndOperationDateAction
  extends Action<ActionType.changeFilterEndOperationDate> {
  date: Date | null;
}

export interface ChangeFilterStartAccountingDateAction
  extends Action<ActionType.changeFilterStartAccountingDate> {
  date: Date | null;
}

export interface ChangeFilterEndAccountingDateAction
  extends Action<ActionType.changeFilterEndAccountingDate> {
  date: Date | null;
}

export interface ChangeFilterPluAction extends Action<ActionType.changeFilterPlu> {
  plu: number[];
}

export interface ChangeFilterStoreMotherChildAction
  extends Action<ActionType.changeFilterStoreMotherChild> {
  pod: PodOptionType[];
}

export interface ChangeFilterGroupByAction extends Action<ActionType.changeFilterGroupBy> {
  groupBy: ReportFilterGroupBy[];
}

export interface ChangeSaleProductFilterGroupByAction
  extends Action<ActionType.changeSaleProductFilterGroupBy> {
  groupBy: ReportFilterGroupBy[];
}

export interface ChangeFindThirdPartySalesByItemsFilterGroupByGroupByAction
  extends Action<ActionType.changeFindThirdPartySalesByItemsFilterGroupBy> {
  groupBy: ReportThirdPartySalesByItemsGroupBy[];
}

export interface ChangeFilterTenderGroupByAction
  extends Action<ActionType.changeFilterTenderGroupBy> {
  groupBy: ReportFilterTenderGroupBy[];
}

export interface ChangeFilterDiscountsJoinAction
  extends Action<ActionType.changeFilterDiscountsJoin> {
  discountsJoin: DiscountsJoin[];
}

export type FindPosRequestedAction = Action<ActionType.findPosRequested>;

export type FindPosSuccessAction = Action<ActionType.findPosSuccess> & {
  posOptions: string[];
};

export type FindPosFailureAction = HttpErrorBaseAction<ActionType.findPosFailure>;

export type FindSaleDiscountsRequestedAction = Action<ActionType.findSaleDiscountsRequested>;

export type FindSaleDiscountsSuccessAction = Action<ActionType.findSaleDiscountsSuccess> & {
  discountOptions: Discount[];
};

export type FindSaleDiscountsFailureAction =
  HttpErrorBaseAction<ActionType.findSaleDiscountsFailure>;

export type FindItemDiscountsRequestedAction = Action<ActionType.findItemDiscountsRequested>;

export type FindItemDiscountsSuccessAction = Action<ActionType.findItemDiscountsSuccess> & {
  discountOptions: Discount[];
};

export type FindItemDiscountsFailureAction =
  HttpErrorBaseAction<ActionType.findItemDiscountsFailure>;

export type FindSalesTypesRequestedAction = Action<ActionType.findSalesTypesRequested>;

export type FindSalesTypesSuccessAction = Action<ActionType.findSalesTypesSuccess> & {
  salesTypesOptions: SaleType[];
};

export type FindSalesTypesFailureAction = HttpErrorBaseAction<ActionType.findSalesTypesFailure>;

export type FindCauseTypesRequestedAction = Action<ActionType.findCauseTypesRequested>;

export type FindCauseTypesSuccessAction = Action<ActionType.findCauseTypesSuccess> & {
  causeTypesOptions: MissingCauses[];
};

export type FindCauseTypesFailureAction = HttpErrorBaseAction<ActionType.findCauseTypesFailure>;

export type FindOperationTypesRequestedAction = Action<ActionType.findOperationTypesRequested>;

export type FindOperationTypesSuccessAction = Action<ActionType.findOperationTypesSuccess> & {
  operationTypes: OperationType[];
};

export type FindOperationTypesFailureAction =
  HttpErrorBaseAction<ActionType.findOperationTypesFailure>;

export type FindPaymentTypeRequestedAction = Action<ActionType.findPaymentTypeRequested>;

export type FindPaymentTypeSuccessAction = Action<ActionType.findPaymentTypeSuccess> & {
  paymentType: PaymentType[];
};

export type FindPaymentTypeFailureAction = HttpErrorBaseAction<ActionType.findPaymentTypeFailure>;

export type FindSalesRequestedAction = Action<ActionType.findSalesRequested> & {
  sentToOnedrive: boolean;
};

export type FindSalesSuccessAction = Action<ActionType.findSalesSuccess> & {
  sales: Sale[];
};

export type FindSalesFailureAction = HttpErrorBaseAction<ActionType.findSalesFailure>;

export type FindReportTenderRequestedAction = Action<ActionType.findReportTenderRequested> & {
  sendToOnedrive: boolean;
};

export type FindReportTenderSuccessAction = Action<ActionType.findReportTenderSuccess> & {
  reportTender: TenderReport[];
};

export type FindReportTenderFailureAction = HttpErrorBaseAction<ActionType.findReportTenderFailure>;

export type FindGcsRequestedAction = Action<ActionType.findGcsRequested> & {
  sendToOndrive: boolean;
};

export type FindGcsSuccessAction = Action<ActionType.findGcsSuccess> & {
  gcs: Gcs[];
};

export type FindGcsFailureAction = HttpErrorBaseAction<ActionType.findGcsFailure>;

export type FindGcsByPaymentTypesRequestedAction =
  Action<ActionType.findGcsByPaymentTypesRequested> & { sendToOndrive: boolean };

export type FindGcsByPaymentTypesSuccessAction = Action<ActionType.findGcsByPaymentTypesSuccess> & {
  gcsPaymentTypes: GcsByPaymentTypes[];
};

export type FindGcsByPaymentTypesFailureAction =
  HttpErrorBaseAction<ActionType.findGcsByPaymentTypesFailure>;

export type FindClosingSalesRequestedAction = Action<ActionType.findClosingSalesRequested> & {
  startDate?: Date;
  endDate?: Date;
};

export type FindClosingSalesSuccessAction = Action<ActionType.findClosingSalesSuccess> & {
  closing: Closing[];
  startDate: Date;
  endDate: Date;
};

export type FindClosingSalesFailureAction = HttpErrorBaseAction<ActionType.findClosingSalesFailure>;

export type FindClosingCollectionsRequestedAction =
  Action<ActionType.findClosingCollectionsRequested> & {
    startDate?: Date;
    endDate?: Date;
  };

export type FindClosingCollectionsSuccessAction =
  Action<ActionType.findClosingCollectionsSuccess> & {
    closing: Closing[];
    startDate: Date;
    endDate: Date;
  };

export type FindClosingCollectionsFailureAction =
  HttpErrorBaseAction<ActionType.findClosingCollectionsFailure>;

export type FindMissingClosingSalesCausesRequestedAction =
  Action<ActionType.findMissingClosingSalesCausesRequested>;

export type FindMissingClosingSalesCausesSuccessAction =
  Action<ActionType.findMissingClosingSalesCausesSuccess> & {
    missingClosingCauses: MissingClosingSalesCauses | undefined;
  };

export type FindMissingClosingSalesCausesFailureAction =
  HttpErrorBaseAction<ActionType.findMissingClosingSalesCausesFailure>;

export type FindMissingClosingCollectionsCausesRequestedAction =
  Action<ActionType.findMissingClosingCollectionsCausesRequested>;

export type FindMissingClosingCollectionsCausesSuccessAction =
  Action<ActionType.findMissingClosingCollectionsCausesSuccess> & {
    missingClosingCauses: MissingClosingCollectionsCauses | undefined;
  };

export type FindMissingClosingCollectionsCausesFailureAction =
  HttpErrorBaseAction<ActionType.findMissingClosingCollectionsCausesFailure>;

export interface ChangeCausesFilterCountryAction
  extends Action<ActionType.changeCausesFilterCountry> {
  country: string;
}

export interface ChangeCausesFilterStoreAction extends Action<ActionType.changeCausesFilterStore> {
  store: string;
}

export interface ChangeCausesFilterDateAction extends Action<ActionType.changeCausesFilterDate> {
  date: Date | null;
}

export interface ChangeCausesFilterCauseAction extends Action<ActionType.changeCausesFilterCause> {
  cause: string;
}

export interface ChangeOpStrucuresFilterAgreementAction
  extends Action<ActionType.changeOpStrucuresFilterAgreement> {
  agreement: Agreement[];
}

export interface ChangeOpStrucuresFilterRegionalManagementAction
  extends Action<ActionType.changeOpStrucuresFilterRegionalManagement> {
  regionalMgmt: RegionalManagement[];
}

export interface ChangeOpStrucuresFilterRegionAction
  extends Action<ActionType.changeOpStrucuresFilterRegion> {
  region: Region[];
}

export interface ChangeOpStrucuresFilterManagementAction
  extends Action<ActionType.changeOpStrucuresFilterManagement> {
  management: Management[];
}

export interface ChangeOpStrucuresFilterSupervisionAction
  extends Action<ActionType.changeOpStrucuresFilterSupervision> {
  supervision: Supervision[];
}

export interface ChangeOpStrucuresFilterCompanyAction
  extends Action<ActionType.changeOpStrucuresFilterCompany> {
  company: Company[];
}

export type FindMissingClosingTotalRequestedAction =
  Action<ActionType.findMissingClosingTotalRequested> & {
    dateFrom: Date;
    dateTo: Date;
    country: string;
    stores: Stores[];
  };

export type FindMissingClosingTotalSuccessAction =
  Action<ActionType.findMissingClosingTotalSuccess> & {
    totals: number;
  };

export type FindMissingClosingTotalFailureAction =
  HttpErrorBaseAction<ActionType.findMissingClosingTotalFailure>;

export type ChangeMissingClosingTotalFilteredStoresAction =
  Action<ActionType.changeMissingClosingTotalFilteredStores> & {
    stores: Stores[];
  };

export type FindHourRangeRequestedAction = Action<ActionType.findHourRangeRequested>;

export type FindHourRangeSuccessAction = Action<ActionType.findHourRangeSuccess> & {
  hourRange: HourRange[];
};

export type FindHourRangeFailureAction = HttpErrorBaseAction<ActionType.findHourRangeFailure>;

export type FindTimeBandRequestedAction = Action<ActionType.findTimeBandRequested>;

export type FindTimeBandSuccessAction = Action<ActionType.findTimeBandSuccess> & {
  timeBand: TimeBand[];
};

export type FindTimeBandFailureAction = HttpErrorBaseAction<ActionType.findTimeBandFailure>;

export type FindReliefsRequestedAction = Action<ActionType.findReliefsRequested>;

export type FindReliefsSuccessAction = Action<ActionType.findReliefsSuccess> & {
  reliefs: Relief[];
};

export type FindReliefsFailureAction = HttpErrorBaseAction<ActionType.findReliefsFailure>;

export interface ChangeReliefsGroupByAction extends Action<ActionType.changeReliefsGroupBy> {
  groupBy: ReportReliefsGroupBy[];
}

export type FindCancellationsRequestedAction = Action<ActionType.findCancellationsRequested>;

export type FindCancellationsSuccessAction = Action<ActionType.findCancellationsSuccess> & {
  cancellations: Cancellation[];
};

export type FindCancellationsFailureAction =
  HttpErrorBaseAction<ActionType.findCancellationsFailure>;

export interface ChangeCancellationsGroupByAction
  extends Action<ActionType.changeCancellationsGroupBy> {
  groupBy: ReportCancellationsGroupBy[];
}

export type FindSalesByProductRequestedAction = Action<ActionType.findSalesByProductRequested> & {
  sendToOndrive: boolean;
};

export type FindSalesByProductSuccessAction = Action<ActionType.findSalesByProductSuccess> & {
  salesByProduct: SalesByProduct[];
};

export type FindSalesByProductFailureAction =
  HttpErrorBaseAction<ActionType.findSalesByProductFailure>;

export type FindGCsByProductRequestedAction = Action<ActionType.findGCsByProductRequested> & {
  sendToOndrive: boolean;
};

export type FindGCsByProductSuccessAction = Action<ActionType.findGCsByProductSuccess> & {
  gcsByProduct: GCsByProduct[];
};

export type FindGCsByProductFailureAction = HttpErrorBaseAction<ActionType.findGCsByProductFailure>;

export type FindThirdPartySalesByItemsRequestedAction =
  Action<ActionType.findThirdPartySalesByItemsRequested> & {
    sendToOndrive: boolean;
  };

export type FindThirdPartySalesByItemsSuccessAction =
  Action<ActionType.findThirdPartySalesByItemsSuccess> & {
    thirdPartySalesByItems: ThirdPartySalesByItems[];
  };

export type FindThirdPartySalesByItemsFailureAction =
  HttpErrorBaseAction<ActionType.findThirdPartySalesByItemsFailure>;

export type FindPettyCashDifferenceRequestedAction =
  Action<ActionType.findPettyCashDifferenceRequested> & {
    sendToOndrive: boolean;
  };

export type FindPettyCashDifferenceSuccessAction =
  Action<ActionType.findPettyCashDifferenceSuccess> & {
    pettyCashDifference: PettyCashDifference[];
  };

export type FindPettyCashDifferenceFailureAction =
  HttpErrorBaseAction<ActionType.findPettyCashDifferenceFailure>;

export type FindPettyCashDifferenceByPaymentTypeRequestedAction =
  Action<ActionType.findPettyCashDifferenceByPaymentTypeRequested> & {
    sendToOndrive: boolean;
  };

export type FindPettyCashDifferenceByPaymentTypeSuccessAction =
  Action<ActionType.findPettyCashDifferenceByPaymentTypeSuccess> & {
    PettyCashDifferenceByPaymentType: PettyCashDifferenceByPaymentType[];
  };

export type FindPettyCashDifferenceByPaymentTypeFailureAction =
  HttpErrorBaseAction<ActionType.findPettyCashDifferenceByPaymentTypeFailure>;

export type findSalesJournalRequestedAction = Action<ActionType.findSalesJournalRequested> & {
  sendToOndrive: boolean;
};

export type FindSalesJournalGcsSuccessAction = Action<ActionType.findSalesJournalGcsSuccess> & {
  gcs: SalesJournalGcs[];
};

export type FindSalesJournalGcsFailureAction =
  HttpErrorBaseAction<ActionType.findSalesJournalGcsFailure>;

export type FindSalesJournalNcsSuccessAction = Action<ActionType.findSalesJournalNcsSuccess> & {
  ncs: SalesJournalNcs[];
};

export type FindSalesJournalNcsFailureAction =
  HttpErrorBaseAction<ActionType.findSalesJournalNcsFailure>;

export type FindSalesJournalFcsSuccessAction = Action<ActionType.findSalesJournalFcsSuccess> & {
  fcs: SalesJournalFcs[];
};

export type FindSalesJournalFcsFailureAction =
  HttpErrorBaseAction<ActionType.findSalesJournalFcsFailure>;

export type FindSalesJournalThirdPartySuccessAction =
  Action<ActionType.findSalesJournalThirdPartySuccess> & {
    thirdparty: SalesJournalThirdParty[];
  };

export type FindSalesJournalThirdPartyFailureAction =
  HttpErrorBaseAction<ActionType.findSalesJournalThirdPartyFailure>;

export interface ChangeMastersImportFilterCountryAction
  extends Action<ActionType.changeMasterImportFilterCountry> {
  countries: Country[];
}

export interface ChangeMastersImportFilterStartImportDateAction
  extends Action<ActionType.changeMasterImportFilterStartImportDate> {
  date: Date | null;
}

export interface ChangeMastersImportFilterEndImportDateAction
  extends Action<ActionType.changeMasterImportFilterEndImportDate> {
  date: Date | null;
}

export interface ChangeMastersImportFilterStateAction
  extends Action<ActionType.changeMasterImportFilterState> {
  state: string[];
}

export interface ChangeClosingFilterCountryAction
  extends Action<ActionType.changeClosingFilterCountry> {
  countries: Country[];
}

export interface ChangeClosingFilterStoreAction
  extends Action<ActionType.changeClosingFilterStore> {
  stores: Stores[];
}

export interface ChangeClosingFilterStartBusinessDateAction
  extends Action<ActionType.changeClosingFilterStartBusinessDate> {
  date: Date | null;
}

export interface ChangeClosingFilterEndBusinessDateAction
  extends Action<ActionType.changeClosingFilterEndBusinessDate> {
  date: Date | null;
}

export interface ChangeClosingFilterViewModeAction
  extends Action<ActionType.changeClosingFilterViewMode> {
  viewMode: ClosingViewMode;
}

export interface ChangeClosingFilterCauseTypesAction
  extends Action<ActionType.changeClosingFilterCauseTypes> {
  causeTypes: MissingCauses[];
}

export type FindMastersImportRequestedAction = Action<ActionType.findMastersImportRequested>;

export type FindMastersImportSuccessAction = Action<ActionType.findMastersImportSuccess> & {
  mastersImport: MastersImport[];
};

export type FindMastersImportFailureAction =
  HttpErrorBaseAction<ActionType.findMastersImportFailure>;

export type MastersImportExecutionRequestedAction =
  Action<ActionType.mastersImportExecutionRequested>;

export type MastersImportExecutionSuccessAction = Action<ActionType.mastersImportExecutionSuccess>;

export type MastersImportExecutionFailureAction =
  HttpErrorBaseAction<ActionType.mastersImportExecutionFailure>;

export type ChangeFilterAccountingEntriesFieldsAction =
  Action<ActionType.changeFilterAccountingEntriesFields>;

export type Actions =
  | ChangeFilterCountryAction
  | ChangeFilterStoreAction
  | ChangeFilterPosAction
  | ChangeFilterSalesTypesAction
  | ChangeFilterPaymentTypesAction
  | ChangeFilterHourRangeAction
  | ChangeFilterTimeBandAction
  | ChangeFilterSaleDiscountAction
  | ChangeFilterItemDiscountAction
  | ChangeFilterSegmentsAction
  | ChangeFilterStartAccountingDateAction
  | ChangeFilterEndAccountingDateAction
  | ChangeFilterGroupByAction
  | ChangeFilterStartBusinessDateAction
  | ChangeFilterEndBusinessDateAction
  | ChangeFilterStartOperationDateAction
  | ChangeFilterEndOperationDateAction
  | ChangeFilterDifferenceTypeAction
  | FindPosRequestedAction
  | FindPosSuccessAction
  | FindPosFailureAction
  | FindSalesTypesRequestedAction
  | FindSalesTypesSuccessAction
  | FindSalesTypesFailureAction
  | FindCauseTypesRequestedAction
  | FindCauseTypesSuccessAction
  | FindCauseTypesFailureAction
  | FindSalesRequestedAction
  | FindSalesSuccessAction
  | FindSalesFailureAction
  | FindGcsRequestedAction
  | FindGcsSuccessAction
  | FindGcsFailureAction
  | FindGcsByPaymentTypesRequestedAction
  | FindGcsByPaymentTypesSuccessAction
  | FindGcsByPaymentTypesFailureAction
  | FindClosingSalesRequestedAction
  | FindClosingSalesSuccessAction
  | FindClosingSalesFailureAction
  | FindClosingCollectionsRequestedAction
  | FindClosingCollectionsSuccessAction
  | FindClosingCollectionsFailureAction
  | FindMissingClosingSalesCausesRequestedAction
  | FindMissingClosingSalesCausesSuccessAction
  | FindMissingClosingSalesCausesFailureAction
  | FindMissingClosingCollectionsCausesRequestedAction
  | FindMissingClosingCollectionsCausesSuccessAction
  | FindMissingClosingCollectionsCausesFailureAction
  | ChangeCausesFilterCountryAction
  | ChangeCausesFilterStoreAction
  | ChangeCausesFilterDateAction
  | ChangeCausesFilterCauseAction
  | ChangeOpStrucuresFilterAgreementAction
  | ChangeOpStrucuresFilterRegionalManagementAction
  | ChangeOpStrucuresFilterRegionAction
  | ChangeOpStrucuresFilterManagementAction
  | ChangeOpStrucuresFilterSupervisionAction
  | ChangeOpStrucuresFilterCompanyAction
  | FindMissingClosingTotalRequestedAction
  | FindMissingClosingTotalSuccessAction
  | FindMissingClosingTotalFailureAction
  | ChangeMissingClosingTotalFilteredStoresAction
  | FindSaleDiscountsRequestedAction
  | FindSaleDiscountsSuccessAction
  | FindSaleDiscountsFailureAction
  | FindItemDiscountsRequestedAction
  | FindItemDiscountsSuccessAction
  | FindItemDiscountsFailureAction
  | FindHourRangeRequestedAction
  | FindHourRangeSuccessAction
  | FindHourRangeFailureAction
  | FindTimeBandRequestedAction
  | FindTimeBandSuccessAction
  | FindTimeBandFailureAction
  | FindReportTenderRequestedAction
  | FindReportTenderSuccessAction
  | FindReportTenderFailureAction
  | ChangeFilterTenderGroupByAction
  | FindReliefsRequestedAction
  | FindReliefsSuccessAction
  | FindReliefsFailureAction
  | ChangeReliefsGroupByAction
  | FindPaymentTypeRequestedAction
  | FindPaymentTypeSuccessAction
  | FindPaymentTypeFailureAction
  | ChangeFilterOperationTypeAction
  | ChangeFilterTenderNameAction
  | FindCancellationsRequestedAction
  | FindCancellationsSuccessAction
  | FindCancellationsFailureAction
  | ChangeCancellationsGroupByAction
  | FindOperationTypesRequestedAction
  | FindOperationTypesSuccessAction
  | FindOperationTypesFailureAction
  | FindSalesByProductRequestedAction
  | FindSalesByProductSuccessAction
  | FindSalesByProductFailureAction
  | ChangeSaleProductFilterGroupByAction
  | FindGCsByProductRequestedAction
  | FindGCsByProductSuccessAction
  | FindGCsByProductFailureAction
  | ChangeFilterPluAction
  | ChangeFilterStoreMotherChildAction
  | FindThirdPartySalesByItemsRequestedAction
  | FindThirdPartySalesByItemsSuccessAction
  | FindThirdPartySalesByItemsFailureAction
  | ChangeFindThirdPartySalesByItemsFilterGroupByGroupByAction
  | FindPettyCashDifferenceRequestedAction
  | FindPettyCashDifferenceSuccessAction
  | FindPettyCashDifferenceFailureAction
  | FindPettyCashDifferenceByPaymentTypeRequestedAction
  | FindPettyCashDifferenceByPaymentTypeSuccessAction
  | FindPettyCashDifferenceByPaymentTypeFailureAction
  | findSalesJournalRequestedAction
  | FindSalesJournalGcsSuccessAction
  | FindSalesJournalGcsFailureAction
  | FindSalesJournalNcsSuccessAction
  | FindSalesJournalNcsFailureAction
  | FindSalesJournalFcsSuccessAction
  | FindSalesJournalFcsFailureAction
  | FindSalesJournalThirdPartySuccessAction
  | ChangeMastersImportFilterCountryAction
  | ChangeMastersImportFilterStartImportDateAction
  | ChangeMastersImportFilterEndImportDateAction
  | ChangeMastersImportFilterStateAction
  | ChangeClosingFilterCountryAction
  | ChangeClosingFilterStoreAction
  | ChangeClosingFilterStartBusinessDateAction
  | ChangeClosingFilterEndBusinessDateAction
  | ChangeClosingFilterViewModeAction
  | ChangeClosingFilterCauseTypesAction
  | FindSalesJournalThirdPartyFailureAction
  | FindMastersImportRequestedAction
  | FindMastersImportSuccessAction
  | FindMastersImportFailureAction
  | ChangeFilterDiscountsJoinAction
  | MastersImportExecutionRequestedAction
  | MastersImportExecutionSuccessAction
  | MastersImportExecutionFailureAction
  | ChangeFilterAccountingEntriesFieldsAction;
