import { useTranslation } from 'react-i18next';
import { SimpleSelectOptionFormatter, useArrayToSimpleSelectOption } from '../selects';
import { SimpleSelectFormInput, SimpleSelectFormInputProps } from '../FlexSalesForm';

export type FrequencySelectFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'>;

const frequencies = ['D', 'M'];

const frequenciesFormatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'accountingParameters.frequencies',
};

const FrequencySelectFormInput = ({ disabled, ...others }: FrequencySelectFormInputProps) => {
  const { t } = useTranslation();
  const options = useArrayToSimpleSelectOption(frequencies, frequenciesFormatter);

  return (
    <SimpleSelectFormInput
      {...others}
      label={t('accountingParameters.columns.frequency')}
      options={options}
      fullWidth
    />
  );
};

export default FrequencySelectFormInput;
