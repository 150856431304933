/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn, useFormState } from 'react-hook-form';

type FlexSalesFormContextType<TFieldValues extends FieldValues = FieldValues, TContext = any> =
  | Pick<UseFormReturn<TFieldValues, TContext>, 'control' | 'setValue' | 'resetField' | 'reset'>
  | undefined;

const initialState: FlexSalesFormContextType = undefined;

const FlexSalesFormContext = createContext<FlexSalesFormContextType>(initialState);

export interface Props<TFieldValues extends FieldValues = FieldValues, TContext = any>
  extends UseFormProps<TFieldValues, TContext> {
  children: ReactNode;
  onValidSubmit: (values: TFieldValues) => void;
}

const FlexSalesForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  children,
  onValidSubmit,
  ...rest
}: Props<TFieldValues, TContext>) => {
  const { handleSubmit, control, setValue, resetField, reset } = useForm<TFieldValues, TContext>({
    mode: 'all',
    ...rest,
  });
  const value = useMemo(
    () => ({ control, setValue, resetField, reset }),
    [control, setValue, resetField, reset]
  );
  return (
    <FlexSalesFormContext.Provider value={value as never} {...rest}>
      <form onSubmit={handleSubmit(onValidSubmit)} noValidate>
        {children}
      </form>
    </FlexSalesFormContext.Provider>
  );
};

export const useFlexSalesForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>() => {
  const context = useContext(FlexSalesFormContext);

  if (context === undefined)
    throw new Error('useFlexSalesForm debe estar dentro de FlexSalesFormContext.Provider');

  return context as NonNullable<FlexSalesFormContextType<TFieldValues, TContext>>;
};

export const useFlexSalesFormState = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>() => {
  const { control } = useFlexSalesForm<TFieldValues, TContext>();
  return useFormState<TFieldValues>({ control });
};

export default FlexSalesForm;
