import { useDispatch } from 'react-redux';

const useListener = <TPayload = unknown, TPayloadResult = unknown>(
  startActionType: string,
  resolveActionType: string,
  rejectActionType: string,
  setPayload?: (payload: TPayload) => TPayloadResult
) => {
  const dispatch = useDispatch();
  return (payload: TPayload) =>
    new Promise((resolve, reject) => {
      const action = {
        type: startActionType,
        payload: setPayload?.(payload) ?? payload,
        meta: {
          final_form_promise: {
            resolve,
            reject,
          },
          final_form_resolve: resolveActionType,
          final_form_reject: rejectActionType,
        },
      };
      dispatch(action);
    });
};

export default useListener;
