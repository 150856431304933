import { useMsal } from '@azure/msal-react';
import { Avatar, Button } from '@mui/material';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MSLogo from '../img/ms-logo.svg';
import { userPermissionsSelector } from '../state';

const LoadingWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarStyled = styled(Avatar)`
  margin: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ProgressAvatar = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
`;

const ButtonStyled = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => (theme.palette.mode === 'light' ? '#FFF' : '#2F2F2F')};
  color: ${({ theme }) => (theme.palette.mode === 'light' ? '#5E5E5E' : '#FFF')};
  border-radius: 0;
  text-transform: none;
  font-size: 15px;
  font-weight: 500;
`;

const SignIn = () => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();
  const { loading: userPermissionsLoading } = useSelector(userPermissionsSelector);
  const loading = inProgress !== 'none' || userPermissionsLoading;

  const login = () => instance.loginRedirect();

  return (
    <>
      <LoadingWrapper>
        {loading && <ProgressAvatar size={48} color="secondary" />}
        <AvatarStyled>
          <LockOutlinedIcon />
        </AvatarStyled>
      </LoadingWrapper>
      <Typography component="h1" variant="h5">
        Flex Ventas
      </Typography>
      <ButtonStyled onClick={login} startIcon={<MSLogo />} variant="outlined" disabled={loading}>
        {t('login.labels.signInWithMS')}
      </ButtonStyled>
    </>
  );
};

export default SignIn;
