import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AnyAction } from 'redux';
import { Dispatch, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ExportFormat } from '../../exports';
import { downloadRequested } from '../../state';
import { Translation } from '../../types';
import { useDataGridFlexSales } from './DataGridFlexSalesProvider';
import { DataGridHandler } from './DataGridHandler';

const transformOrigin = { horizontal: 'right', vertical: 'top' } as const;
const anchorOrigin = { horizontal: 'right', vertical: 'bottom' } as const;

const exportFile = (
  handler: DataGridHandler<unknown, unknown>,
  dispatch: Dispatch<AnyAction>,
  type: ExportFormat,
  t: Translation
) => {
  if (handler.exportHasDispatchAction(type)) {
    handler.export(type);
  } else {
    dispatch(
      downloadRequested({
        downloadHandler: () =>
          new Promise((res, rej) => {
            setTimeout(() => {
              try {
                handler.export(type);
                res();
              } catch (e) {
                rej(e);
              }
            });
          }),
        errorMessage: () => t('errors.exports.body', { type }),
      })
    );
  }
};

const Export = () => {
  const { handler } = useDataGridFlexSales();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleExportExcel = useCallback(
    () => exportFile(handler, dispatch, 'XLSX', t),
    [handler, dispatch, t]
  );
  const handleExportPdf = useCallback(
    () => exportFile(handler, dispatch, 'PDF', t),
    [handler, dispatch, t]
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'report-export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <GetAppIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="report-export-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        <MenuItem onClick={handleExportExcel}>
          {t('toolbarExportButton.labels.xlsxFormat')}
        </MenuItem>
        <MenuItem onClick={handleExportPdf}>{t('toolbarExportButton.labels.pdfFormat')}</MenuItem>
      </Menu>
    </>
  );
};

export default Export;
