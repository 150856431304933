import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Stack } from '@mui/material';
import { DiscountsJoin } from '../../../types';
import DialogFlexSales from '../../DialogFlexSales';
import { ItemDiscountFilter, SaleDiscountFilter } from '../filters';

type Config = Record<DiscountsJoin, boolean>;

export interface DiscountsSelectorProps {
  onClose: () => void;
  onApply: (values: DiscountsJoin[]) => void;
  config: Config;
  initialValues: DiscountsJoin[];
  showJoins?: boolean;
}

const DiscountsSelector = ({
  onClose,
  onApply,
  config,
  initialValues,
  showJoins,
}: DiscountsSelectorProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(initialValues);

  const optionsKeys = Object.keys(config) as DiscountsJoin[];

  const handleToggle = (key: DiscountsJoin) => () => {
    const currentIndex = selected.indexOf(key);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(key);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  useEffect(() => {
    onApply(selected);
  }, [onApply, selected, setSelected]);

  return (
    <DialogFlexSales onClose={onClose} open scroll="paper">
      <DialogTitle>{t('dialogs.discounts.title')}</DialogTitle>
      <DialogContent>
        {showJoins && (
          <>
            <DialogContentText>{t('dialogs.discounts.contentText')}</DialogContentText>
            <List dense>
              {optionsKeys.map(key => {
                const labelId = `checkbox-discounts-${key}`;
                const checked = selected.includes(key);
                const inputProps = { 'aria-labelledby': labelId };
                return (
                  <ListItem key={key} disablePadding dense>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(key)}
                      dense
                      disabled={config[key]}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={inputProps}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={t(`toolbar.discounts.${key}`)} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        <Stack spacing={3}>
          {Object.keys(config).includes('sale') && <SaleDiscountFilter />}
          {Object.keys(config).includes('item') && <ItemDiscountFilter />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('navigation.buttons.close')}</Button>
      </DialogActions>
    </DialogFlexSales>
  );
};

export default DiscountsSelector;
