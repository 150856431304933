import { useTranslation } from 'react-i18next';
import { AccountingEntriesForm, DialogForm } from '../components';

const AccountingEntriesNew = () => {
  const { t } = useTranslation();

  return (
    <DialogForm title={t('accountingEntries.titles.generate')} maxWidth="md">
      <AccountingEntriesForm />
    </DialogForm>
  );
};

export default AccountingEntriesNew;
