import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { AccountingParameter, AppPermissions } from '../../types';
import TableDetail from './TableDetail';
import { BodyTableCellFlexSales } from '../TableFlexSales';
import routes from '../../config/routes';
import { userPermissionsDataSelector } from '../../state';
import { hasPermissions } from '../../helpers';

interface RowProps {
  values: AccountingParameter;
}

interface ButtonEditProps {
  countryIdJournalType: string;
}

const ButtonEdit = ({ countryIdJournalType }: ButtonEditProps) => {
  const navigate = useNavigate();
  const path = `${routes.protectedNestedRoutes.accountingParameters.index.path}/${countryIdJournalType}`;
  const handleClickApply = useCallback(() => navigate(path), [navigate, path]);
  const { t } = useTranslation();
  return (
    <Tooltip title={t('accountingParameters.labels.edit') || ''}>
      <IconButton size="small" onClick={handleClickApply}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

const Row = ({ values }: RowProps) => {
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <BodyTableCellFlexSales>
          <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.countryId}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.journalType}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.source}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.category}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.categoryAlias}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>{values.frequency}</BodyTableCellFlexSales>
        <BodyTableCellFlexSales>
          {hasPermissions(userPermissions, [AppPermissions.ACCOUNTING_PARAMETERS]) && (
            <ButtonEdit countryIdJournalType={`${values.countryId}:${values.journalType}`} />
          )}
        </BodyTableCellFlexSales>
      </TableRow>
      <TableRow>
        <BodyTableCellFlexSales colSpan={7}>
          <TableDetail details={values.details} open={open} />
        </BodyTableCellFlexSales>
      </TableRow>
    </>
  );
};

export default Row;
