import { DiscountsJoin, Gcs, StoreExtraInfoParameters } from '../../types';
import { DataGridHandler, commonColProps } from '../DataGridFlexSales';

export interface GcsDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
  storeExtraInfo: StoreExtraInfoParameters[];
  discountsJoin: DiscountsJoin[];
}

const initialContext: GcsDataGridContext = {
  t: () => '',
  country: '',
  storeExtraInfo: [],
  discountsJoin: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<Gcs, GcsDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('table.columns.country_id'),
    defaultHidden: true,
    ...commonColProps.countryId,
  });

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    defaultHidden: true,
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'storeId',
    type: 'number',
    title: ({ t }) => t('table.columns.store_id'),
    defaultHidden: true,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    name: 'accountingStoreId',
    type: 'string',
    title: ({ t }) => t('table.columns.accounting_store_id'),
    valueGetter: (row, { storeExtraInfo }) =>
      storeExtraInfo.find(i => Number(i.storeId) === Number(row.accountingStoreId))?.name,
    width: { px: 350, pt: 335 },
    available: ({ country }) => ['BR'].includes(country),
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
  });

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
    width: { px: 200, pt: 170 },
  });

  handler.addColumn({
    accessor: 'transactionType',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_type'),
  });

  handler.addColumn({
    accessor: 'endOfSaleTimestamp',
    type: 'dateTime',
    title: ({ t }) => t('table.columns.end_of_sale_timestamp'),
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
  });

  handler.addColumn({
    accessor: 'receiptNum',
    type: 'number',
    title: ({ t }) => t('table.columns.receipt_num'),
  });

  handler.addColumn({
    accessor: 'fiscalReceiptNum',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_receipt_num'),
  });

  handler.addColumn({
    accessor: 'invoiceType',
    type: 'string',
    title: ({ t }) => t('table.columns.invoice_type'),
  });

  handler.addColumn({
    accessor: 'invoiceKind',
    type: 'string',
    title: ({ t }) => t('table.columns.invoice_kind'),
  });

  handler.addColumn({
    accessor: 'fiscalPosCode',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_pos_code'),
  });

  handler.addColumn({
    accessor: 'saleReceipt',
    type: 'number',
    title: ({ t }) => t('table.columns.sale_receipt'),
  });

  handler.addColumn({
    accessor: 'receiptClass',
    type: 'string',
    title: ({ t }) => t('table.columns.receipt_class'),
  });

  handler.addColumn({
    accessor: 'fiscalName1',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_name1'),
  });

  handler.addColumn({
    accessor: 'fiscalName2',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_name2'),
  });

  handler.addColumn({
    accessor: 'fiscalAddress1',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_address1'),
  });

  handler.addColumn({
    accessor: 'fiscalAddress2',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_address2'),
  });

  handler.addColumn({
    accessor: 'fiscalRegisterId',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_register_id'),
  });

  handler.addColumn({
    accessor: 'transactionAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'saleDiscountId',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_id'),
  });

  handler.addColumn({
    accessor: 'saleDiscountDescription',
    type: 'string',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_description'),
  });

  handler.addColumn({
    accessor: 'saleDiscountRate',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_rate'),
  });

  handler.addColumn({
    accessor: 'saleDiscountAmount',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_amount'),
  });

  handler.addColumn({
    accessor: 'discountOnTotalAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.discount_on_total_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'taxAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.tax_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'netAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.net_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'specialSaleOrderId',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_order_id'),
  });

  handler.addColumn({
    accessor: 'specialSaleType',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_type'),
  });

  handler.addColumn({
    accessor: 'specialSaleMethod',
    type: 'string',
    title: ({ t }) => t('table.columns.special_sale_method'),
  });

  handler.addColumn({
    accessor: 'clientMcId',
    type: 'string',
    title: ({ t }) => t('table.columns.client_mc_id'),
  });

  handler.addColumn({
    accessor: 'program',
    type: 'string',
    title: ({ t }) => t('table.columns.program'),
  });

  handler.addColumn({
    accessor: 'digitalChannel',
    type: 'number',
    title: ({ t }) => t('table.columns.digital_channel'),
  });

  handler.addColumn({
    accessor: 'operatorId',
    type: 'number',
    title: ({ t }) => t('table.columns.operator_id'),
  });

  handler.addColumn({
    accessor: 'managerId',
    type: 'number',
    title: ({ t }) => t('table.columns.manager_id'),
  });

  handler.addColumn({
    accessor: 'saleKey',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_key'),
  });

  handler.addColumn({
    accessor: 'plName',
    type: 'string',
    title: ({ t }) => t('table.columns.pl_name'),
  });

  return handler;
};
