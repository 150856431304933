import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { writeFile, utils } from 'xlsx';
import { t } from 'i18next';
import {
  ActionType,
  AccountingEntriesCreateRequestedAction,
  AccountingEntriesReportRequestedAction,
  AccountingEntriesDeleteRequestedAction,
} from './types';
import {
  SalesApi,
  FindAccountingEntriesResponse,
  AccountingEntriesReportResponse,
} from '../../services';
import {
  accountingEntriesFindFailure,
  accountingEntriesFindSuccess,
  accountingEntriesCreateSuccess,
  accountingEntriesCreateFailure,
  accountingEntriesReportSuccess,
  accountingEntriesReportFailure,
  accountingEntriesDeleteSuccess,
  accountingEntriesDeleteFailure,
} from './actionCreators';
import { HttpRequestError, AccountingEntriesFilter, MasterData, Country } from '../../types';
import {
  accountingEntriesCreateRequestMap,
  accountingEntriesDeleteRequestMap,
  findAccountingEntriesRequestMap,
  findAccountingEntriesResponseMap,
} from '../../helpers';
import { accountingEntriesFilterSelector, masterDataDataSelector } from '../selectors';

function* findAccountingEntries() {
  const filters: AccountingEntriesFilter = yield select(accountingEntriesFilterSelector);

  try {
    const { data }: AxiosResponse<FindAccountingEntriesResponse> = yield call(
      SalesApi.findAccountingEntries,
      findAccountingEntriesRequestMap(filters)
    );

    yield put(accountingEntriesFindSuccess(findAccountingEntriesResponseMap(data)));
  } catch (error) {
    yield put(accountingEntriesFindFailure(error as HttpRequestError));
  }
}

function* createAccountingEntries({ payload }: AccountingEntriesCreateRequestedAction) {
  try {
    const { countries }: MasterData = yield select(masterDataDataSelector);
    // TODO: Consultar con Martín si se puede dejar así
    const { countryCode } = countries.find(
      i => i.countryId === String(payload.countryId)
    ) as Country;
    yield call(
      SalesApi.createAccountingEntries,
      accountingEntriesCreateRequestMap(payload, countryCode)
    );
    yield put(accountingEntriesCreateSuccess());
  } catch (error) {
    yield put(accountingEntriesCreateFailure(error as HttpRequestError));
  }
}

function* accountingEntriesReport({ id }: AccountingEntriesReportRequestedAction) {
  try {
    const { countries }: MasterData = yield select(masterDataDataSelector);
    const { data }: AxiosResponse<AccountingEntriesReportResponse> = yield call(
      SalesApi.accountingEntriesReport,
      { id }
    );
    const countriesNamesCache: Record<string, string> = {};

    const getCountryName = (countryId: string) => {
      if (!(countryId in countriesNamesCache)) {
        const countryCode = countries.find(
          i => parseInt(i.countryId, 10) === parseInt(countryId, 10)
        )?.countryCode as string;
        countriesNamesCache[countryId] = t(`operativeStructure.countries.${countryCode}`);
      }
      return countriesNamesCache[countryId];
    };

    const rows = data.map(i => ({
      country: getCountryName(i.country_id),
      journalType: i.journal_type,
      setOfBooksId: i.set_of_books_id,
      currencyCode: i.currency_code,
      accountingDate: i.accounting_date,
      companyId: i.company_id,
      companyName: i.company_name,
      journalSubtype: i.journal_subtype,
      account: i.account,
      Subaccount: i.sub_account,
      costCenter: i.cost_center,
      store: i.store,
      credit: i.credit,
      debit: i.debit,
    }));

    const worksheet = utils.json_to_sheet(rows);
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          t(`accountingEntries.report.country`),
          t(`accountingEntries.report.journalType`),
          t(`accountingEntries.report.setOfBooksId`),
          t(`accountingEntries.report.currency`),
          t(`accountingEntries.report.accountingDate`),
          t(`accountingEntries.report.companyId`),
          t(`accountingEntries.report.companyName`),
          t(`accountingEntries.report.journalSubtype`),
          t(`accountingEntries.report.account`),
          t(`accountingEntries.report.subAccount`),
          t(`accountingEntries.report.costCenter`),
          t(`accountingEntries.report.store`),
          t(`accountingEntries.report.credit`),
          t(`accountingEntries.report.debit`),
        ],
      ],
      { origin: 'A1' }
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'AccountingEntries');
    writeFile(workbook, `accounting_entries_${id}.xlsx`);

    yield put(accountingEntriesReportSuccess());
  } catch (error) {
    yield put(accountingEntriesReportFailure(error as HttpRequestError));
  }
}

function* deleteAccountingEntries({ payload }: AccountingEntriesDeleteRequestedAction) {
  try {
    yield call(SalesApi.deleteAccountingEntries, accountingEntriesDeleteRequestMap(payload));
    yield put(accountingEntriesDeleteSuccess());
  } catch (error) {
    yield put(accountingEntriesDeleteFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(ActionType.accountingEntriesFindRequested, findAccountingEntries),
  takeLatest(ActionType.accountingEntriesCreateRequested, createAccountingEntries),
  takeLatest(ActionType.accountingEntriesReportRequested, accountingEntriesReport),
  takeLatest(ActionType.accountingEntriesDeleteRequested, deleteAccountingEntries),
];
