import DiscountIcon from '@mui/icons-material/Discount';
import { Badge } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DiscountsSelector from './DiscountsSelector';
import { DiscountsJoin } from '../../../types';
import { useReportFilters } from '../ReportFiltersProvider';
import { reportFilterItemDiscountSelector, reportFilterSaleDiscountSelector } from '../../../state';

const DiscountsDialogWrapper = () => {
  const { discountsConfig, loadingSelector } = useReportFilters<DiscountsJoin>();
  if (!discountsConfig) throw new Error('discountsConfig cannot be null or undefined');
  const { action, selector, options, showJoins } = discountsConfig;

  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();

  const handleApply = useCallback(
    (values: DiscountsJoin[]) => dispatch(action(values)),
    [action, dispatch]
  );

  const [badgeContent, setBadgeContent] = useState(0);
  const values = useSelector(selector);
  const saleDiscount = useSelector(reportFilterSaleDiscountSelector);
  const itemDiscount = useSelector(reportFilterItemDiscountSelector);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleClick = useCallback(() => setOpen(true), []);

  useEffect(() => {
    const filterOptions: DiscountsJoin[] = ['sale', 'item'];
    filterOptions.forEach(i => {
      if (!Object.keys(options).includes(i) && values.includes(i))
        values.splice(values.indexOf(i), 1);
    });
    let newBadgeContent = values.length;
    if (saleDiscount.length > 0 && Object.keys(options).includes('sale')) newBadgeContent += 1;
    if (itemDiscount.length > 0 && Object.keys(options).includes('item')) newBadgeContent += 1;
    setBadgeContent(newBadgeContent);
  }, [badgeContent, itemDiscount, options, saleDiscount, values, values.length]);

  return (
    <>
      <Tooltip title={t('toolbar.labels.discounts') as string}>
        <Badge badgeContent={badgeContent} color="primary">
          <Button color="primary" variant="contained" onClick={handleClick} disabled={loading}>
            <DiscountIcon />
          </Button>
        </Badge>
      </Tooltip>
      {open && (
        <DiscountsSelector
          onClose={handleClose}
          onApply={handleApply}
          config={options}
          initialValues={values}
          showJoins={showJoins}
        />
      )}
    </>
  );
};

const DiscountsDialog = () => {
  const { discountsConfig } = useReportFilters<DiscountsJoin>();

  return discountsConfig ? <DiscountsDialogWrapper /> : null;
};

export default DiscountsDialog;
