import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  ListItem,
  ListSubheader,
  SxProps,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { isSidebarOpenSelector } from '../../state';
import { RouteConfig } from '../../config/routes';

interface Item {
  route: RouteConfig;
  icon: JSX.Element;
  tranlationKey: string;
  children?: Item[];
  noLink?: boolean;
}

const ItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const iconStyle: SxProps = {
  mr: 1,
};
interface NestedListProps {
  data: Item[];
  deep?: number;
  subheader?: string;
}

const NestedList = ({ data, deep, subheader }: NestedListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  const calcDeep = () => {
    if (isSidebarOpen) return deep ? deep + 6 : 6;
    return 2;
  };

  const getFatherBgColor = () => {
    if (theme.palette.mode === 'light') return grey[50];
    return grey[700];
  };

  const getBgColor = () => {
    if (theme.palette.mode === 'light') return grey[300];
    return theme.palette.background.default;
  };

  const isCurrentPath = (path: string) => location.pathname === path;

  const isOpen = (path: string) => open[path] === true;

  const handleClick = (key: string) => {
    const newState = { ...open };
    newState[key] = !newState[key];
    setOpen(newState);
  };

  return (
    <List>
      {subheader && <ListSubheader>{isSidebarOpen ? subheader : null}</ListSubheader>}
      {data.map(i => (
        <React.Fragment key={i.route.path}>
          <Tooltip title={isSidebarOpen ? '' : t(i.tranlationKey).toString()} placement="right">
            {i.noLink ? (
              <ItemBox>
                <ListItemButton
                  sx={{
                    pl: deep,
                    backgroundColor: isOpen(i.route.path) ? getFatherBgColor() : undefined,
                  }}
                  onClick={() => handleClick(i.route.path)}
                >
                  <ListItemIcon>{i.icon}</ListItemIcon>
                  <ListItemText primary={t(i.tranlationKey)} />
                  {open[i.route.path] === true ? (
                    <ExpandLess color="action" sx={iconStyle} />
                  ) : (
                    <ExpandMore color="action" sx={iconStyle} />
                  )}
                </ListItemButton>
              </ItemBox>
            ) : (
              <ListItem
                sx={{ px: 0 }}
                secondaryAction={
                  i.children &&
                  isSidebarOpen && (
                    <IconButton onClick={() => handleClick(i.route.path)}>
                      {open[i.route.path] === true ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )
                }
              >
                <ListItemButton
                  component={NavLink}
                  to={i.route.path}
                  selected={isCurrentPath(i.route.path)}
                  sx={{ pl: deep }}
                >
                  <ListItemIcon>{i.icon}</ListItemIcon>
                  <ListItemText primary={t(i.tranlationKey)} />
                </ListItemButton>
              </ListItem>
            )}
          </Tooltip>
          {i.children && (
            <Collapse
              in={open[i.route.path]}
              timeout="auto"
              unmountOnExit
              sx={{ backgroundColor: getBgColor() }}
            >
              <NestedList data={i.children} deep={calcDeep()} />
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default NestedList;
