import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import {
  reportGcsLoadingSelector,
  findGcsRequested,
  reportFilterCountryCodeSelector,
  changeFilterDiscountsJoin,
  reportFilterDiscountsJoinSelector,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  CountryFilter,
  HourRangeFilter,
  PosFilter,
  SegmentsFilter,
  StoreFilter,
  BusinessDateRangeFilter,
  AccountingDateRangeFilter,
  OperationDateRangeFilter,
  PodFilter,
} from './ReportFilters';

import { AppPermissions, DiscountsJoin } from '../types';
import { PermissionsProvider } from './Permissions';
import { DiscountsProps } from './ReportFilters/ReportFiltersProvider';

const dialogFilters = (
  <>
    <OperationDateRangeFilter />
    <AccountingDateRangeFilter />
  </>
);

const onedriveAction = () => findGcsRequested(true);

const GridBr = () => {
  const countryCode = useSelector(reportFilterCountryCodeSelector);

  if (countryCode !== 'BR') return null;

  return (
    <Grid item xs={12} md={3} lg={2} xl={2}>
      <PodFilter />
    </Grid>
  );
};

const discountsConfig: DiscountsProps = {
  options: {
    sale: false,
  } as Record<DiscountsJoin, boolean>,
  action: changeFilterDiscountsJoin,
  selector: reportFilterDiscountsJoinSelector,
  showJoins: true,
};

const GcsSearch = () => (
  <ReportFilters
    loadingSelector={reportGcsLoadingSelector}
    applyAction={findGcsRequested}
    dialogFilters={dialogFilters}
    dialogFiltersApplied={[
      'startOperationDate',
      'endOperationDate',
      'startAccountingDate',
      'endAccountingDate',
    ]}
    onedriveAction={onedriveAction}
    discountsConfig={discountsConfig}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.GCS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <GridBr />
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default GcsSearch;
