import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useCompany from '../../../hooks/useCompany';
import {
  changeOpStrucuresFilterCompany,
  reportFilterCountryCodeSelector,
  reportOpStructuresFilterCompanySelector,
} from '../../../state';
import { Company } from '../../../types';
import { MultiSelect } from '../../selects';

const CompanyFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const companies = useSelector(reportOpStructuresFilterCompanySelector);

  const { options, disabled, loading } = useCompany(countryCode);

  const handleChange = useCallback(
    (value: Company[]) => dispatch(changeOpStrucuresFilterCompany(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.company')}
      options={options}
      propDesc="companyName"
      disabled={disabled}
      value={companies}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default CompanyFilter;
