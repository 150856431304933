import { CircularProgress, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { DelayedStores, RemoteResource } from '../../../../../types';
import TooltipTitle from './TooltipTitle';

interface DelayedStoresCellProps {
  country: string;
  delayedStores: RemoteResource<DelayedStores[]>;
  locale: Locale;
}

const DelayedStoresCell = ({ country, delayedStores, locale }: DelayedStoresCellProps) => {
  const delayedStoresData = useMemo(
    () => delayedStores.data.filter(i => i.country === country),
    [country, delayedStores.data]
  );

  return (
    <div>
      {!delayedStores.loading && delayedStoresData.length === 0 && (
        <div>{delayedStoresData.length}</div>
      )}
      {!delayedStores.loading && delayedStoresData.length > 0 && (
        <Tooltip
          title={<TooltipTitle delayedStores={delayedStoresData} locale={locale} />}
          placement="right-start"
        >
          <div>{delayedStoresData.length}</div>
        </Tooltip>
      )}
      {delayedStores.loading && <CircularProgress size={15} />}
    </div>
  );
};

export default DelayedStoresCell;
