import { useTranslation } from 'react-i18next';
import { SimpleSelectOptionFormatter, useArrayToSimpleSelectOption } from '../selects';
import { SimpleSelectFormInput, SimpleSelectFormInputProps } from '../FlexSalesForm';

export type JournalGroupFormInputProps = Omit<SimpleSelectFormInputProps, 'options' | 'value'>;

const jounarGroups = ['SALES'];

const jounarGroupsFormatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'accountingEntries.jounarGroups',
};

const JournalGroupFormInput = ({ disabled, ...others }: JournalGroupFormInputProps) => {
  const { t } = useTranslation();
  const options = useArrayToSimpleSelectOption(jounarGroups, jounarGroupsFormatter);

  return (
    <SimpleSelectFormInput
      {...others}
      label={t('accountingEntries.labels.journalGroup')}
      options={options}
    />
  );
};

export default JournalGroupFormInput;
