import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { reportFilterCountryCodeSelector } from '../../state';
import { useReportFilters } from './ReportFiltersProvider';

const TooltipWrapper = ({
  title,
  disabled,
  children,
}: {
  title: string;
  disabled: boolean;
  children: ReactElement;
}) => (!disabled ? <Tooltip title={title}>{children}</Tooltip> : children);

const OnedriveButton = () => {
  const dispatch = useDispatch();
  const { loadingSelector, onedriveAction } = useReportFilters();
  const handleClickOnedrive = useCallback(
    () => (onedriveAction ? dispatch(onedriveAction()) : null),
    [dispatch, onedriveAction]
  );
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const hasCountry = Boolean(useSelector(reportFilterCountryCodeSelector));
  const disabled = !hasCountry || loading;
  return onedriveAction ? (
    <TooltipWrapper title={t('toolbar.labels.sendToOnedrive') as string} disabled={disabled}>
      <Button color="primary" variant="contained" onClick={handleClickOnedrive} disabled={disabled}>
        <CloudDownloadIcon />
      </Button>
    </TooltipWrapper>
  ) : null;
};

export default OnedriveButton;
