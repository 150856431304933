import {
  ExportType,
  ExportFormat,
  TableExportPdf,
  TableExportXlsx,
  TablesExportPdf,
  TablesExportXlsx,
} from '../../../exports';
import { ColumnHandler } from './ColumnHandler';
import { RowHandler } from './RowHandler';

type ExportMapper = {
  [K in ExportFormat]: (name: string) => ExportType<K>;
};

export type ExportConfig = Partial<{
  [K in ExportFormat]:
    | boolean
    | { generator: (table: ExportType<K>, fileName: string) => void; isDispatchAction: boolean };
}>;

const exportsDefault: {
  [K in ExportFormat]: (fileName: string) => (table: ExportType<K>) => void;
} = {
  PDF: fileName => table => {
    const tablesExport = new TablesExportPdf();
    tablesExport.addTable('table', 1, table);
    tablesExport.export(fileName);
  },
  XLSX: fileName => table => {
    const tablesExport = new TablesExportXlsx();
    tablesExport.addTable('table', 1, table);
    tablesExport.export(fileName);
  },
};

export class ExportHandler<TData, TContext> {
  #columnHandler: ColumnHandler<TData, TContext>;

  #rowHandler: RowHandler<TData, TContext>;

  #exportConfig: ExportConfig;

  #exportMapper: ExportMapper;

  constructor(
    columnHandler: ColumnHandler<TData, TContext>,
    rowHandler: RowHandler<TData, TContext>,
    exportConfig?: ExportConfig
  ) {
    this.#columnHandler = columnHandler;
    this.#rowHandler = rowHandler;
    this.#exportConfig = exportConfig || {};
    this.#exportMapper = {
      XLSX: (name: string) => this.tableToXlsx(name),
      PDF: (name: string) => this.tableToPdf(name),
    };
  }

  private tableToXlsx(sheetName: string): TableExportXlsx {
    const columns = this.#columnHandler.getVisibleColumns();
    const currentTotals = this.#rowHandler.totals;
    const headers = columns.map(i => i.getTitle());
    const rows: TableExportXlsx['rows'] = this.#rowHandler
      .getRows(true)
      .map(row => row.cells.map(cell => cell.getValue()));

    const totals = columns.map(i => {
      const total = currentTotals.find(c => c.columnId === i.id);
      if (total) return total.value;
      return null;
    });

    return { headers, rows, sheetName, totals };
  }

  private tableToPdf(title: string): TableExportPdf {
    const columns = this.#columnHandler.getVisibleColumns();
    const currentTotals = this.#rowHandler.totals;
    const headers = columns.map(i => i.getTitle());
    const columnConfig = columns.map(i => ({ width: i.getWidth().pt, align: i.align }));
    const totals = columns.map(i => {
      const total = currentTotals.find(c => c.columnId === i.id);
      if (total) return i.getValueFormatted(total.value);
      return '';
    });
    const rows: TableExportPdf['rows'] = this.#rowHandler
      .getRows(true)
      .map(row => row.cells.map(cell => cell.getValueFormatted()));

    return {
      headers,
      rows,
      title,
      columnConfig,
      totals: totals.some(i => i) ? totals : [],
    };
  }

  setConfig(config: ExportConfig) {
    this.#exportConfig = config;
  }

  export(format: ExportFormat, title: string, fileName: string) {
    const exportValue = this.#exportConfig[format];
    if (exportValue === false) throw new Error(`La exportación a ${format} no está habilitada`);

    const table = this.#exportMapper[format](title) as never;

    if (exportValue === undefined || exportValue === true) {
      exportsDefault[format](fileName)(table);
    } else {
      exportValue.generator(table, title);
    }
  }

  hasDispatchAction(format: ExportFormat) {
    const exportValue = this.#exportConfig[format];
    if (!exportValue || exportValue === true) return false;
    return exportValue.isDispatchAction;
  }
}
