import * as yup from 'yup';
import { DeleteAccountingEntriesForm } from '../../types';

export const minDate = new Date(2015, 0, 1);
export const maxDate = new Date();

export const commentsMaxLength = 500;

export const getSchema = (): yup.ObjectSchema<DeleteAccountingEntriesForm> =>
  yup.object().shape({
    countryId: yup.string().required(),
    accountingMonth: yup.date().required(),
    journalGroup: yup.string().required(),
    comments: yup.string().required().max(commentsMaxLength),
  });
