import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import {
  monthClosingFindRequested,
  monthClosingLoadingSelector,
  monthClosingFilterCountrySelector,
} from '../../state';
import { LoadingButton } from '../buttons';
import { MonthClosingFilters } from './MonthClosingFilters';

const ActionContainer = styled.div`
  display: flex;
  justify-content: right;
  > button:first-of-type {
    margin-right: ${props => props.theme.spacing(2)};
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
  }
`;

const ButtonApply = () => {
  const dispatch = useDispatch();
  const handleClickApply = useCallback(() => dispatch(monthClosingFindRequested()), [dispatch]);
  const { t } = useTranslation();
  const loading = useSelector(monthClosingLoadingSelector);
  const hasCountry = Boolean(useSelector(monthClosingFilterCountrySelector));
  const disabled = !hasCountry;
  return (
    <LoadingButton
      color="primary"
      variant="contained"
      onClick={handleClickApply}
      loading={loading}
      disabled={disabled}
    >
      {t('navigation.buttons.apply')}
    </LoadingButton>
  );
};

const ButtonCloseMonth = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = `${routes.protectedNestedRoutes.monthClosing.children.create.path}`;
  const handleClick = useCallback(() => navigate(path), [navigate, path]);

  return (
    <Button color="primary" variant="contained" onClick={handleClick}>
      {t('monthClosing.labels.closeMonth')}
    </Button>
  );
};

const MonthClosingSearch = () => {
  const actions = (
    <ActionContainer>
      <ButtonCloseMonth />
      <ButtonApply />
    </ActionContainer>
  );
  return <MonthClosingFilters actions={actions} />;
};

export default MonthClosingSearch;
