import { Actions, State, ActionType } from './types';

const initialState: State = {
  isModeModal: false,
  isSidebarOpen: false,
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.modeModal:
      return {
        ...state,
        isModeModal: action.isModeModal,
      };
    case ActionType.toggleSidebar:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    default:
      return state;
  }
};

export default reducer;
