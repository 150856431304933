import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { ReactNode } from 'react';
import useSelectFlexSales, { GetOptionLabel, OnChange, RenderOption } from './useSelectFlexSales';

export interface SelectFlexSalesProps<
  TValue,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>,
    | 'autoComplete'
    | 'includeInputInList'
    | 'onChange'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderInput'
  > {
  label: ReactNode;
  onChange: OnChange<TValue, TMultiple, TDisableClearable, TFreeSolo>;
  getOptionLabel?: GetOptionLabel<TValue>;
  groupByCheckboxOption?: TMultiple extends true ? boolean : undefined;
  renderOption?: RenderOption<TValue>;
  renderInput?: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>['renderInput'];
}

const SelectFlexSales = <
  TValue,
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = undefined,
  TFreeSolo extends boolean | undefined = undefined
>({
  onChange,
  label,
  multiple,
  getOptionLabel,
  renderTags,
  renderOption,
  renderGroup,
  groupBy,
  value,
  options,
  groupByCheckboxOption,
  loading,
  renderInput,
  ...others
}: SelectFlexSalesProps<TValue, TMultiple, TDisableClearable, TFreeSolo>) => {
  const {
    handleRenderInput,
    handleOnChange,
    handleGetOptionLabel,
    handleRenderTags,
    handleRenderOption,
    handleRenderGroup,
    propsLoading,
  } = useSelectFlexSales({
    label,
    onChange,
    getOptionLabel,
    renderTags,
    multiple,
    renderOption,
    groupBy,
    options,
    renderGroup,
    value,
    groupByCheckboxOption,
    loading,
  });

  return (
    <Autocomplete<TValue, TMultiple, TDisableClearable, TFreeSolo>
      disableCloseOnSelect
      {...others}
      forcePopupIcon
      multiple={multiple}
      value={value}
      options={options}
      groupBy={groupBy}
      getOptionLabel={handleGetOptionLabel as never}
      renderTags={handleRenderTags}
      onChange={handleOnChange}
      renderInput={renderInput || handleRenderInput}
      renderOption={handleRenderOption}
      renderGroup={handleRenderGroup}
      {...propsLoading}
    />
  );
};

export default SelectFlexSales;
