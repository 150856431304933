import { OakBrook } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface OakBrookDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: OakBrookDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<OakBrook, OakBrookDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'classCode',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.classCode'),
  });

  handler.addColumn({
    accessor: 'countryId',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.countryId'),
    ...commonColProps.countryId,
  });

  handler.addColumn({
    accessor: 'oakStoreId',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.oakStoreId'),
  });

  handler.addColumn({
    accessor: 'companyOakId',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.companyOakId'),
  });

  handler.addColumn({
    title: ({ t }) => t('oak-brook.columns.date'),
    type: 'date',
    accessor: 'date',
    format: 'MMM-yy',
  });

  handler.addColumn({
    accessor: 'sales',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.sales'),
  });

  handler.addColumn({
    accessor: 'gcs',
    type: 'string',
    title: ({ t }) => t('oak-brook.columns.GCS'),
  });

  return handler;
};
