import { HttpRequestError, AccountingParameter, Country } from '../../types';
import {
  ActionType,
  AccountingParametersGetFailureAction,
  AccountingParametersGetRequestedAction,
  AccountingParametersGetSuccessAction,
  AccountingParametersFindFailureAction,
  AccountingParametersFindRequestedAction,
  AccountingParametersFindSuccessAction,
  AccountingParametersEditSuccessAction,
  AccountingParametersEditFailureAction,
  AccountingParametersChangeCountryAction,
} from './types';

export const accountingParametersFindRequested = (): AccountingParametersFindRequestedAction => ({
  type: ActionType.accountingParametersFindRequested,
});

export const accountingParametersFindSuccess = (
  accountingParameters: AccountingParameter[]
): AccountingParametersFindSuccessAction => ({
  type: ActionType.accountingParametersFindSuccess,
  accountingParameters,
});

export const accountingParametersFindFailure = (
  error: HttpRequestError
): AccountingParametersFindFailureAction => ({
  type: ActionType.accountingParametersFindFailure,
  error,
});

export const accountingParametersGetRequested = (
  countryId: string,
  journalType: string
): AccountingParametersGetRequestedAction => ({
  type: ActionType.accountingParametersGetRequested,
  countryId,
  journalType,
});

export const accountingParametersGetSuccess = (
  accountingParameter: AccountingParameter | undefined
): AccountingParametersGetSuccessAction => ({
  type: ActionType.accountingParametersGetSuccess,
  accountingParameter,
});

export const accountingParametersGetFailure = (
  error: HttpRequestError
): AccountingParametersGetFailureAction => ({
  type: ActionType.accountingParametersGetFailure,
  error,
});

export const accountingParametersEditSuccess = (): AccountingParametersEditSuccessAction => ({
  type: ActionType.accountingParametersEditSuccess,
});

export const accountingParametersEditFailure = (
  error: HttpRequestError
): AccountingParametersEditFailureAction => ({
  type: ActionType.accountingParametersEditFailure,
  error,
});

export const accountingParametersChangeCountry = (
  country: Country | null
): AccountingParametersChangeCountryAction => ({
  type: ActionType.accountingParametersChangeCountry,
  country,
});
