import Grid from '@mui/material/Grid';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import routes from '../../config/routes';
import {
  MonthClosingActionType,
  monthClosingFindRequested,
  userInfoNameSelector,
} from '../../state';
import { AppPermissions, CloseMonthForm } from '../../types';
import { PermissionsProvider } from '../Permissions';
import { DatePickerFlexSalesView } from '../DatePickerFlexSales';
import {
  CountrySelectFormInput,
  DatePickerFormInput,
  FlexSalesForm,
  FlexSalesFormActions,
} from '../FlexSalesForm';
import { useListener } from '../../hooks';
import { getSchema, maxDate, minDate } from './schema';

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

const getDefaultValues = (createdBy: string): CloseMonthForm => ({
  country: '',
  accountingMonth: maxDate,
  createdBy,
});

const views: DatePickerFlexSalesView[] = ['year', 'month'];

const MonthClosingForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const resolver = useMemo(() => yupResolver(getSchema()), []);

  const userName = useSelector(userInfoNameSelector);
  const defaultValues = useMemo(() => getDefaultValues(userName as never), [userName]);

  const formListener = useListener(
    MonthClosingActionType.monthClosingCloseRequested,
    MonthClosingActionType.monthClosingCloseSuccess,
    MonthClosingActionType.monthClosingCloseFailure
  );
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.monthClosing.index.path);
  }, [navigate]);

  const onSubmit = async (data: CloseMonthForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('monthClosing.messages.closeSuccess'), messageOptions);
    dispatch(monthClosingFindRequested());
  };

  return (
    <PermissionsProvider resourcePermissions={[AppPermissions.MONTH_CLOSING]}>
      <FlexSalesForm onValidSubmit={onSubmit} defaultValues={defaultValues} resolver={resolver}>
        <Grid container flexDirection="column" rowSpacing={1} minWidth={220}>
          <Grid item xs>
            <CountrySelectFormInput name="country" required propId="countryCode" fullWidth />
          </Grid>
          <Grid item xs>
            <DatePickerFormInput
              views={views}
              name="accountingMonth"
              label={t('accountingEntries.columns.accountingMonth')}
              required
              maxDate={maxDate}
              minDate={minDate}
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('accountingParameters.labels.cancel')}
          submitLabel={t('monthClosing.labels.close')}
        />
      </FlexSalesForm>
    </PermissionsProvider>
  );
};

export default MonthClosingForm;
