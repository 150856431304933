import Grid from '@mui/material/Grid';
import {
  changeCancellationsGroupBy,
  reportCancellationsGroupBySelector,
  reportCancellationsLoadingSelector,
  findCancellationsRequested,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  CountryFilter,
  StoreFilter,
  AccountingDateRangeFilter,
} from './ReportFilters';

import { AppPermissions, ReportCancellationsGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportCancellationsGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    accountingDate: false,
  },
  action: changeCancellationsGroupBy,
  selector: reportCancellationsGroupBySelector,
};

const CancellationsSearch = () => (
  <PermissionsProvider resourcePermissions={[AppPermissions.CANCELLATIONS]}>
    <ReportFilters
      groupByConfig={groupByConfig}
      loadingSelector={reportCancellationsLoadingSelector}
      applyAction={findCancellationsRequested}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={3}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </ReportFilters>
  </PermissionsProvider>
);

export default CancellationsSearch;
