import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  reportFilterCountryCodeSelector,
  reportPettyCashDifferenceByPaymentTypeSelector,
} from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { PettyCashDifferenceByPaymentTypeDataGridContext } from './handlerFactory';
import { PettyCashDifferenceByPaymentType } from '../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<
    PettyCashDifferenceByPaymentType,
    PettyCashDifferenceByPaymentTypeDataGridContext
  >();
  const { t } = useTranslation();
  const title = {
    title: t('petty-cash-difference-by-payment-type.file-title'),
    tableTitle: t('petty-cash-difference-by-payment-type.title'),
  };
  const country = useSelector(reportFilterCountryCodeSelector);
  const { loading, data } = useSelector(reportPettyCashDifferenceByPaymentTypeSelector);
  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setTitle(title);
  handler.setContext(gridContext);
  handler.setLoading(loading);
  handler.setData(data);

  return null;
};

const PettyCashDifferenceByPaymentTypeDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default PettyCashDifferenceByPaymentTypeDataGrid;
