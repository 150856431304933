import styled from '@emotion/styled';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useIsCurrentPath } from '../../hooks';

interface ListExternalLinkButtonProps {
  to: string;
  icon: JSX.Element;
  translationKey: string;
  showTooltip: boolean;
}

const ButtonContainer = styled.a`
  color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : 'black')};
  text-decoration: none;
`;

const ListExternalLinkButton = ({
  to,
  icon,
  translationKey,
  showTooltip,
}: ListExternalLinkButtonProps) => {
  const isCurrentPath = useIsCurrentPath(to);
  const { t } = useTranslation();
  return (
    <Tooltip title={showTooltip ? t(translationKey) : ''} placement="right">
      <ButtonContainer href={to}>
        <ListItemButton selected={isCurrentPath}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={t(translationKey)} />
        </ListItemButton>
      </ButtonContainer>
    </Tooltip>
  );
};

export default ListExternalLinkButton;
