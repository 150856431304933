import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import format from 'date-fns/format';
import LanguageDetector from 'i18next-browser-languagedetector';
import { fr, es, enUS, pt } from 'date-fns/locale';
import { setLocale } from 'yup';
import translations, { SupportedLanguages } from './i18n/translations';

export const supportedLngs = Object.keys(translations);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources: translations,
    fallbackLng: supportedLngs[0],
    nsSeparator: '.',
    debug: false,
    supportedLngs,
    cleanCode: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export const dateLocal: Record<SupportedLanguages, Locale> = { fr, es, en: enUS, pt };

setLocale({
  mixed: {
    required: () => i18n.t('validations.required'),
    notType: ({ type }) => {
      switch (type) {
        case 'date':
          return i18n.t('validations.invalidDateMessage');
        case 'number':
          return i18n.t('validations.number');
        default:
          return 'Invalid type';
      }
    },
  },
  number: {
    integer: () => i18n.t('validations.integer'),
  },
  string: {
    max: ({ max }) => i18n.t('validations.maxLength', { length: max }),
  },
  array: {
    min: () => i18n.t('validations.required'),
  },
  date: {
    min: ({ min }) =>
      i18n.t('validations.minDateMessage', {
        date:
          typeof min === 'string'
            ? min
            : format(min, 'P', { locale: dateLocal[i18n.language as never] }),
      }),
    max: ({ max }) =>
      i18n.t('validations.maxDateMessage', {
        date:
          typeof max === 'string'
            ? max
            : format(max, 'P', { locale: dateLocal[i18n.language as never] }),
      }),
  },
});

export default i18n;
