import { startOfMonth, subMonths } from 'date-fns';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  oakBrookMonthFilterSelector,
  oakBrookChangeFilterMonth,
  filterParametersDataSelector,
} from '../../../state';
import DatePickerFlexSales, { DatePickerFlexSalesView } from '../../DatePickerFlexSales';

const views: DatePickerFlexSalesView[] = ['year', 'month'];

const maxDate = new Date();

const AccountingMonthFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector(oakBrookMonthFilterSelector);
  const filterParameters = useSelector(filterParametersDataSelector);
  const minDate = startOfMonth(subMonths(new Date(), filterParameters?.filtersLimitMonths || 3));

  const handleChange = useCallback(
    (newValue: Date | null) => {
      dispatch(oakBrookChangeFilterMonth(newValue));
    },
    [dispatch]
  );

  return (
    <DatePickerFlexSales
      views={views}
      value={value.accept}
      onChange={handleChange}
      label={t('oak-brook.columns.date')}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default AccountingMonthFilter;
