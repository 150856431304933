import { Action } from 'redux';
import { ThemePreference } from '../../types';

export interface State {
  theme: ThemePreference;
  showSaveDialog: boolean;
}

export enum ActionType {
  changeTheme = 'preferences/changeTheme',
  changeShowSaveDialog = 'preferences/changeShowSaveDialog',
}

export interface ChangeThemeAction extends Action<ActionType.changeTheme> {
  theme: ThemePreference;
}

export interface ChangeShowSaveDialogAction extends Action<ActionType.changeShowSaveDialog> {
  showSaveDialog: boolean;
}

export type Actions = ChangeThemeAction | ChangeShowSaveDialogAction;
