import { Action } from 'redux';
import {
  HttpErrorBaseAction,
  RemoteResource,
  CompanyCodesFilter,
  CompanyCode,
  EditCompanyCodeForm,
} from '../../types';

export interface State {
  filters: CompanyCodesFilter;
  companyCodes: RemoteResource<CompanyCode[]>;
  companyCode: RemoteResource<CompanyCode | undefined>;
}

export enum ActionType {
  companyCodesChangeFilterCountry = 'companyCodes/changeFilterCountry',
  companyCodesFindRequested = 'companyCodes/companyCodesFindRequested',
  companyCodesFindSuccess = 'companyCodes/companyCodesFindSuccess',
  companyCodesFindFailure = 'companyCodes/companyCodesFindFailure',
  companyCodesGetRequested = 'companyCodes/companyCodesGetRequested',
  companyCodesGetSuccess = 'companyCodes/companyCodesGetSuccess',
  companyCodesGetFailure = 'companyCodes/companyCodesGetFailure',
  companyCodesEditRequested = 'companyCodes/companyCodesEditRequested',
  companyCodesEditSuccess = 'companyCodes/companyCodesEditSuccess',
  companyCodesEditFailure = 'companyCodes/companyCodesEditFailure',
}

export interface CompanyCodesChangeFilterCountryAction
  extends Action<ActionType.companyCodesChangeFilterCountry> {
  countryId: string;
}

export type CompanyCodesFindRequestedAction = Action<ActionType.companyCodesFindRequested>;

export type CompanyCodesFindSuccessAction = Action<ActionType.companyCodesFindSuccess> & {
  companyCodes: CompanyCode[];
};

export type CompanyCodesFindFailureAction = HttpErrorBaseAction<ActionType.companyCodesFindFailure>;

export type CompanyCodesGetRequestedAction = Action<ActionType.companyCodesGetRequested> & {
  countryId: string;
  companyId: number;
};

export type CompanyCodesGetSuccessAction = Action<ActionType.companyCodesGetSuccess> & {
  companyCode: CompanyCode;
};

export type CompanyCodesGetFailureAction = HttpErrorBaseAction<ActionType.companyCodesGetFailure>;

export type CompanyCodesEditRequestedAction = Action<ActionType.companyCodesEditRequested> & {
  payload: EditCompanyCodeForm;
};

export type CompanyCodesEditSuccessAction = Action<ActionType.companyCodesEditSuccess>;

export type CompanyCodesEditFailureAction = HttpErrorBaseAction<ActionType.companyCodesEditFailure>;

export type Actions =
  | CompanyCodesChangeFilterCountryAction
  | CompanyCodesFindRequestedAction
  | CompanyCodesFindSuccessAction
  | CompanyCodesFindFailureAction
  | CompanyCodesGetRequestedAction
  | CompanyCodesGetSuccessAction
  | CompanyCodesGetFailureAction
  | CompanyCodesEditRequestedAction
  | CompanyCodesEditSuccessAction
  | CompanyCodesEditFailureAction;
