import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../../DataGridFlexSales';

import {
  reportCausesFilterCauseSelector,
  reportCausesFilterCountrySelector,
  reportCausesFilterDateSelector,
  reportCausesFilterStoresSelector,
} from '../../../state';
import handlerFactory, { HeaderDataGridContext } from './handlerFactory';
import { MissingCauses, MissingClosingCauses } from '../../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<MissingClosingCauses, HeaderDataGridContext>();

  const { t } = useTranslation();

  const country = useSelector(reportCausesFilterCountrySelector);
  const store = useSelector(reportCausesFilterStoresSelector);
  const date = useSelector(reportCausesFilterDateSelector).accept as Date;
  const cause = useSelector(reportCausesFilterCauseSelector) as MissingCauses;
  const data = [{ country, store, date, cause }];

  const gridContext = useMemo(
    () => ({
      t,
    }),
    [t]
  );

  handler.setData(data);
  handler.setContext(gridContext);

  return null;
};

const ErrorHeader = () => (
  <DataGridFlexSales handlerFactory={handlerFactory} hideToolbar>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ErrorHeader;
