import { useMemo, useCallback } from 'react';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useFlexSalesForm } from './FlexSalesForm';
import { SelectFlexSales, SelectFlexSalesProps } from '../selects/SelectFlexSales';

export type MultiSelectFormInputProps<
  TValue,
  TFieldValues extends FieldValues = FieldValues
> = Omit<SelectFlexSalesProps<TValue, true, true, true>, 'multiple' | 'name' | 'onChange'> & {
  validation?: ControllerProps<TFieldValues>['rules'];
  name: Path<TFieldValues>;
  required?: boolean;
};

const MultiSelectFormInput = <TValue, TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  validation,
  required,
  label,
  ...rest
}: MultiSelectFormInputProps<TValue, TFieldValues>) => {
  const { control } = useFlexSalesForm<TFieldValues>();

  const rules = useMemo(
    () => (required ? { required: true, ...validation } : validation),
    [required, validation]
  );

  const render: ControllerProps<TFieldValues>['render'] = useCallback(
    ({ field: { ref, ...restField }, fieldState: { error } }) => (
      <SelectFlexSales
        multiple
        label={label}
        {...restField}
        {...rest}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            required={required}
            error={Boolean(error)}
            helperText={error?.message}
            inputRef={ref}
          />
        )}
      />
    ),
    [label, rest, required]
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as never}
      rules={rules}
      render={render}
    />
  );
};

export default MultiSelectFormInput;
