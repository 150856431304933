import { format } from 'date-fns';
import { put, race, select, take, takeLatest } from 'redux-saga/effects';
import {
  findClosingSalesRequested,
  reportFilterSelector,
  reportOpStructuresFilterSelector,
} from '..';
import { TableExportPdfAdapter, TablesExportPdf } from '../../exports';
import { Closing, OpStructuresFilter, ReportFilter, Translation } from '../../types';
import { enqueueNotification } from '../notifications/actionCreators';
import {
  FindClosingSalesFailureAction,
  FindClosingSalesSuccessAction,
  ActionType as ReportActionType,
} from '../report/types';
import { downloadSuccess, downloadFailure, downloadRequested } from './actionCreators';
import { ActionType, DownloadReportPdfAction, DownloadRequestedAction } from './types';

const getMissingsTableDates = (data: Closing[]) => [
  ...new Set(data.map(i => i.date.toISOString())),
];

const getMissingsData = (data: Closing[], t: Translation) => {
  const dict: Record<string, [string, string][]> = {};
  data.forEach(i => {
    i.cause.forEach(cause => {
      const date = i.date.toISOString();
      if (!dict[date]) dict[date] = [];
      dict[date].push([i.store, t(`missing-closing-detail.causes.${cause}`)]);
    });
  });
  return dict;
};

const addMissings = (
  tablesExport: TablesExportPdf,
  data: Closing[],
  t: Translation,
  locale: Locale
) => {
  const dates = getMissingsTableDates(data);
  const missingsData = getMissingsData(data, t);

  dates.forEach(date => {
    const titleDate = format(new Date(date), 'P', { locale });
    tablesExport.pushTable(date, {
      headers: [t('table.columns.store'), t('table.columns.cause')],
      rows: missingsData[date],
      title: t('shared.pdfExport.missingClosingDate', { date: titleDate }),
      columnConfig: [
        { width: 50, align: 'left' },
        { width: 200, align: 'left' },
      ],
    });
  });
};
/*
const data = [
  {
    header: t('dataGridFlexSales.exports.labels.parameterValue'),
    country: t(`operativeStructure.countries.${filters.countryCode}`),
    store: filters.stores.map(i => i.storeAcronym).join(', '),
    pos: filters.pos.join(', '),
    saleType: filters.salesTypes.map(i => i.saleTypeDescription).join(', '),
    segment: filters.segments.map(i => i.segmentDesc).join(', '),
    timeBand: filters.timeBand.join(', '),
    hourRange: filters.hourRange.join(', '),
    startAccountingDate: filters.startAccountingDate.accept,
    endAccountingDate: filters.endAccountingDate.accept,
    startOperationDate: filters.startOperationDate.accept,
    endOperationDate: filters.endOperationDate.accept,
    startBusinessDate: filters.startBusinessDate.accept,
    endBusinessDate: filters.endBusinessDate.accept,
    regionalMgmt: opsFilters.regionalMgmt.map(i => i.regionalMgmt).join(', '),
    region: opsFilters.region.map(i => i.region).join(', '),
    management: opsFilters.management.map(i => i.mgmt).join(', '),
    supervision: opsFilters.supervision.map(i => i.supervision).join(', '),
    agreement: opsFilters.agreement.map(i => i.agreementType).join(', '),
    company: opsFilters.company.map(i => i.companyName).join(', '),
    groupBy: filters.groupBy.map(i => t(`toolbar.groupBy.${i}`)).join(', '),
  },
];
*/

const getParamtersTable = ({
  t,
  title,
  filters,
  opsFilters,
  locale,
  parameters = [],
  opStructures = [],
}: DownloadReportPdfAction & {
  filters: ReportFilter;
  opsFilters: OpStructuresFilter;
}) => {
  const values = {
    header: t('dataGridFlexSales.exports.labels.parameterValue'),
    countryCode: t(`operativeStructure.countries.${filters.countryCode}`),
    stores: filters.stores.map(i => i.storeAcronym).join(', '),
    pos: filters.pos.join(', '),
    salesTypes: filters.salesTypes.map(i => i.saleTypeDescription).join(', '),
    segments: filters.segments.map(i => i.segmentDesc).join(', '),
    timeBand: filters.timeBand.join(', '),
    hourRange: filters.hourRange.join(', '),
    startAccountingDate: filters.startAccountingDate.accept,
    endAccountingDate: filters.endAccountingDate.accept,
    startOperationDate: filters.startOperationDate.accept,
    endOperationDate: filters.endOperationDate.accept,
    startBusinessDate: filters.startBusinessDate.accept,
    endBusinessDate: filters.endBusinessDate.accept,
    plu: filters.plu.join(', '),
    regionalMgmt: opsFilters.regionalMgmt.map(i => i.regionalMgmt).join(', '),
    region: opsFilters.region.map(i => i.region).join(', '),
    management: opsFilters.management.map(i => i.mgmt).join(', '),
    supervision: opsFilters.supervision.map(i => i.supervision).join(', '),
    agreement: opsFilters.agreement.map(i => i.agreementType).join(', '),
    company: opsFilters.company.map(i => i.companyName).join(', '),
    groupBy: filters.groupBy.map(i => t(`toolbar.groupBy.${i}`)).join(', '),
    salesProductGroupBy: filters.salesProductGroupBy.map(i => t(`toolbar.groupBy.${i}`)).join(', '),
  };

  const adapter = new TableExportPdfAdapter<typeof values>([values], locale);

  adapter.addColumn({
    type: 'string',
    value: 'header',
    title: t('dataGridFlexSales.exports.labels.parameter'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'countryCode',
    title: t('toolbar.labels.country'),
    available: () => parameters.includes('countryCode'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'stores',
    title: t('toolbar.labels.store'),
    available: () => parameters.includes('stores'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'pos',
    title: t('toolbar.labels.pos'),
    available: () => parameters.includes('pos'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'salesTypes',
    title: t('toolbar.labels.sale-type'),
    available: () => parameters.includes('salesTypes'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'segments',
    title: t('toolbar.labels.pos-type'),
    available: () => parameters.includes('segments'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'timeBand',
    title: t('toolbar.labels.time-band'),
    available: () => parameters.includes('timeBand'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'hourRange',
    title: t('toolbar.labels.hour-range'),
    available: () => parameters.includes('hourRange'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'startAccountingDate',
    title: t('toolbar.labels.fromDateAccounting'),
    available: () => parameters.includes('startAccountingDate'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'endAccountingDate',
    title: t('toolbar.labels.toDateAccounting'),
    available: () => parameters.includes('endAccountingDate'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'startOperationDate',
    title: t('toolbar.labels.fromDateOperation'),
    available: () => parameters.includes('startOperationDate'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'endOperationDate',
    title: t('toolbar.labels.toDateOperation'),
    available: () => parameters.includes('endOperationDate'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'startBusinessDate',
    title: t('toolbar.labels.fromDateBusiness'),
    available: () => parameters.includes('startBusinessDate'),
  });
  adapter.addColumn({
    type: 'date',
    value: 'endBusinessDate',
    title: t('toolbar.labels.toDateBusiness'),
    available: () => parameters.includes('endBusinessDate'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'plu',
    title: t('table.columns.plu'),
    available: () => parameters.includes('plu'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'regionalMgmt',
    title: t('toolbar.labels.regionalMgmt'),
    available: () => opStructures.includes('regionalMgmt'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'region',
    title: t('toolbar.labels.region'),
    available: () => opStructures.includes('region'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'management',
    title: t('toolbar.labels.management'),
    available: () => opStructures.includes('management'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'supervision',
    title: t('toolbar.labels.supervision'),
    available: () => opStructures.includes('supervision'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'agreement',
    title: t('toolbar.labels.agreement'),
    available: () => opStructures.includes('agreement'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'company',
    title: t('toolbar.labels.company'),
    available: () => opStructures.includes('company'),
  });
  adapter.addColumn({
    type: 'string',
    value: 'groupBy',
    title: t('toolbar.labels.groupBy'),
    available: () => parameters.includes('groupBy'),
  });

  adapter.addColumn({
    type: 'string',
    value: 'salesProductGroupBy',
    title: t('toolbar.labels.groupBy'),
    available: () => parameters.includes('salesProductGroupBy'),
  });

  return adapter.getTableTranspose({
    title,
    getColumnConfig: headers => headers.map((_, i) => ({ width: i ? 250 : 100, align: 'left' })),
  });
};

function* download({ downloadHandler, errorMessage }: DownloadRequestedAction) {
  try {
    yield downloadHandler();
    yield put(downloadSuccess());
  } catch (error) {
    yield put(downloadFailure({ error }));
    yield put(
      enqueueNotification({
        key: 'downloadError',
        message: errorMessage,
        variant: 'error',
      })
    );
  }
}

function* pushMissingClosingTable({
  tablesExport,
  locale,
  t,
}: DownloadReportPdfAction & { tablesExport: TablesExportPdf }) {
  yield put(findClosingSalesRequested());

  const {
    success,
    failure,
  }: {
    success?: FindClosingSalesSuccessAction;
    failure?: FindClosingSalesFailureAction;
  } = yield race({
    suceess: take(ReportActionType.findClosingSalesSuccess),
    failure: take(ReportActionType.findClosingSalesFailure),
  });

  if (success) addMissings(tablesExport, success.closing, t, locale);
  // eslint-disable-next-line no-console
  if (failure) console.log('Falló el export de missing closing sales', failure);
}

function* downloadReportPdfHandler(action: DownloadReportPdfAction) {
  const { includeMissingClosingTable, title, table, parameters } = action;
  const filters: ReportFilter = yield select(reportFilterSelector);
  const opsFilters: OpStructuresFilter = yield select(reportOpStructuresFilterSelector);
  const tablesExport = new TablesExportPdf();

  if (parameters?.length)
    tablesExport.pushTable(
      'parameters',
      yield getParamtersTable({ filters, opsFilters, ...action })
    );

  if (includeMissingClosingTable) yield pushMissingClosingTable({ tablesExport, ...action });

  tablesExport.pushTable('table', table);
  tablesExport.export(title);
}

function* downloadReportPdf(action: DownloadReportPdfAction) {
  yield put(
    downloadRequested({
      downloadHandler: () => downloadReportPdfHandler(action),
      errorMessage: t => t('errors.exports.body', { type: 'PDF' }),
    })
  );
}

export default [
  takeLatest(ActionType.downloadRequested, download),
  takeLatest(ActionType.downloadReportPdf, downloadReportPdf),
];
