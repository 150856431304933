import { Reprocess } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface ReprocessDataGridContext {
  t: (key: string | string[]) => string;
  countryCode?: string;
}

const initialContext: ReprocessDataGridContext = {
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<Reprocess, ReprocessDataGridContext>(initialContext, locale);

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.country'),
    ...commonColProps.country,
  });
  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.store'),
  });
  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('reprocess.columns.business_date'),
  });
  handler.addColumn({
    name: 'type',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.type'),
    width: { px: 200, pt: 160 },
    valueGetter: (v, { t }) => t(`reprocess.type.${v.type}`),
  });
  handler.addColumn({
    accessor: 'justification',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.justification'),
    width: { px: 250, pt: 210 },
  });
  handler.addColumn({
    accessor: 'executionDate',
    type: 'dateTime',
    title: ({ t }) => t('reprocess.columns.execution_date'),
  });
  handler.addColumn({
    accessor: 'status',
    type: 'string',
    title: ({ t }) => t('reprocess.columns.status'),
  });
  handler.addColumn({
    accessor: 'federativeEntity',
    type: 'string',
    available: ({ countryCode }) => false,
    title: ({ t }) => t('reprocess.columns.federativeEntity'),
  });

  return handler;
};
