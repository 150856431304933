import ErrorIcon from '@mui/icons-material/Error';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import { MissingCauses } from '../../types';

interface CausesButtonProps {
  viewMode: string;
  country: string;
  store: string;
  date: Date;
  cause: MissingCauses;
}

const CausesButton = ({ viewMode, country, store, date, cause }: CausesButtonProps) => {
  const navigate = useNavigate();

  const handleClickApply = useCallback(
    () =>
      navigate(
        `${
          routes.protectedNestedRoutes.missingClosingDetail.children.create.path
        }?viewMode=${viewMode}&country=${country}&store=${store}&date=${format(
          date,
          'yyyy-MM-dd'
        )}&cause=${cause}`
      ),
    [cause, country, date, navigate, store, viewMode]
  );

  const onClick = cause !== MissingCauses.CLOSING_NOT_INFORMED ? handleClickApply : undefined;

  return (
    <ErrorIcon htmlColor="#ca2222" onClick={onClick} cursor={onClick ? 'pointer' : undefined} />
  );
};

export default CausesButton;
