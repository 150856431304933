import { useSelector } from 'react-redux';
import { reportFilterAppliedSelector } from '../state';
import { ReportFilter } from '../types';

type useFilterAppliedProps = (keyof ReportFilter)[];

const useFilterAppliedLength = (filters: useFilterAppliedProps): number => {
  const filterApplied = useSelector(reportFilterAppliedSelector);

  return filterApplied.filter(i => filters.includes(i)).length;
};

export default useFilterAppliedLength;
