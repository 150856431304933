import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IbGroups } from '../../types';
import { useCountry } from '../../hooks';
import { reprocessParametersSelector } from '../../state';
import MultiSelectFormInput, { MultiSelectFormInputProps } from './MultiSelectFormInput';

export type IbGroupSelectFormInputProps = Omit<
  MultiSelectFormInputProps<IbGroups>,
  'options' | 'value'
> & {
  countryCode: string;
};

const isOptionEqualToValue = (ibGroups1: IbGroups, ibGroups2: IbGroups) =>
  ibGroups1.groupId === ibGroups2.groupId;

const IbGroupSelectFormInput = ({
  disabled,
  countryCode,
  ...rest
}: IbGroupSelectFormInputProps) => {
  const { data, loading } = useSelector(reprocessParametersSelector);
  const ibGroups = data?.ibGroups;
  const country = useCountry({ countryCode });
  const isDisabled = loading || !country;

  const ibGroupsFiltered = useMemo(() => {
    if (!ibGroups || !country) return [];

    return ibGroups.filter(i => parseInt(i.countryId, 10) === parseInt(country.countryId, 10));
  }, [ibGroups, country]);

  return (
    <MultiSelectFormInput<IbGroups>
      {...rest}
      options={ibGroupsFiltered}
      getOptionLabel="groupName"
      disabled={isDisabled}
      loading={loading}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default IbGroupSelectFormInput;
