import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { MastersStatusDataGridContext } from './handlerFactory';
import { MastersImport } from '../../types';
import { reportMastersImportSelector } from '../../state';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<MastersImport, MastersStatusDataGridContext>();

  const { t } = useTranslation();
  const title = t('masters-import.title');

  const mastersImportResource = useSelector(reportMastersImportSelector);

  const { loading, data } = mastersImportResource;

  const gridContext = useMemo(
    () => ({
      t,
    }),
    [t]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);

  return null;
};

const MastersImportDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default MastersImportDataGrid;
