import { Permission, HttpRequestError } from '../../types';
import {
  ActionType,
  MsalSigninSuccessAction,
  PermissionsFailureAction,
  PermissionsRequestedAction,
  PermissionsSuccessAction,
} from './types';

export const msalSigninSuccess = (name: string, username: string): MsalSigninSuccessAction => ({
  type: ActionType.msalSignInSuccess,
  name,
  username,
});

export const permissionsRequested = (): PermissionsRequestedAction => ({
  type: ActionType.permissionsRequested,
});

export const permissionsSuccess = (permissions: Permission[]): PermissionsSuccessAction => ({
  type: ActionType.permissionsSuccess,
  permissions,
});

export const permissionsFailure = (error: HttpRequestError): PermissionsFailureAction => ({
  type: ActionType.permissionsFailure,
  error,
});
