import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountingEntriesFilterStatusSelector,
  accountingEntriesChangeFilterStatus,
} from '../../../state';
import { AccountingEntryStatus } from '../../../types';
import { SelectFlexSales, SimpleSelectProps } from '../../selects';

type StatusFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const statuses = [
  AccountingEntryStatus.vigente,
  AccountingEntryStatus.processing,
  AccountingEntryStatus.anulado,
  AccountingEntryStatus.error,
];

const StatusFilter = (props: StatusFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const value = useSelector(accountingEntriesFilterStatusSelector);
  const handleChange = useCallback(
    (value: AccountingEntryStatus | null) => dispatch(accountingEntriesChangeFilterStatus(value)),
    [dispatch]
  );

  return (
    <SelectFlexSales
      {...props}
      label={t('accountingEntries.columns.status')}
      onChange={handleChange}
      value={value}
      options={statuses}
    />
  );
};

export default StatusFilter;
