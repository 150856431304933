import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import saveAs from 'file-saver';
import { format } from 'date-fns';
import { ActionType } from './types';
import { SalesApi, FindOakBrookResponse } from '../../services';
import { oakBrookFindFailure, oakBrookFindSuccess } from './actionCreators';
import { HttpRequestError, OakBrookFilter, OakBrook } from '../../types';
import { findOakBrookRequestMap, findOakBrookResponseMap } from '../../helpers';
import { oakBrookFilterSelector, oakBrookOakBrookDataSelector } from '../selectors';

function* findOakBrook() {
  const filters: OakBrookFilter = yield select(oakBrookFilterSelector);

  try {
    const { data }: AxiosResponse<FindOakBrookResponse> = yield call(
      SalesApi.findOakBrook,
      findOakBrookRequestMap(filters)
    );

    yield put(oakBrookFindSuccess(findOakBrookResponseMap(data)));
  } catch (error) {
    yield put(oakBrookFindFailure(error as HttpRequestError));
  }
}

function* downloadOakBrook() {
  const data: OakBrook[] = yield select(oakBrookOakBrookDataSelector);
  const filters: OakBrookFilter = yield select(oakBrookFilterSelector);

  const pad = (value: string | number | undefined, maxLength: number, fillString?: string) =>
    (value ? value.toString() : '').padStart(maxLength, fillString);
  const lines: string[] = [];
  const totals: Record<string, [number, number]> = {};
  data.forEach(i => {
    const sales = Math.round(i.sales * 100);
    const gcs = Math.round(i.gcs * 100);
    if (!totals[i.classCode]) totals[i.classCode] = [0, 0];
    totals[i.classCode][0] += sales;
    totals[i.classCode][1] += gcs;
    lines.push(
      [
        pad(i.classCode, 10, '0'),
        pad(i.countryId, 3, '0'),
        pad(i.oakStoreId, 4, '0'),
        pad(i.companyOakId, 1, '0'),
        pad('', 2, ' '),
        format(i.date, 'MMyy'),
        pad(sales, 14, '0'),
        pad(gcs, 8, '0'),
        '\n',
      ].join('')
    );
  });

  Object.keys(totals).forEach(key => {
    lines.push(
      [
        pad(key, 10, '0'),
        pad('', 10, ' '),
        format(filters.month.accept as Date, 'MMyy'),
        pad(totals[key][0], 14, '0'),
        pad(totals[key][1], 8, '0'),
        '\n',
      ].join('')
    );
  });

  const fileName = `oak_brook_${filters.country?.countryCode}_${format(
    filters.month.accept as Date,
    'MM_yyyy'
  )}.txt`;

  const file = new File(lines, fileName, { type: 'text/plain;charset=utf-8' });

  saveAs(file);
}

/*

function* oakBrookReport({ id }: OakBrookReportRequestedAction) {
  try {
    const { countries }: MasterData = yield select(masterDataDataSelector);
    const { data }: AxiosResponse<OakBrookReportResponse> = yield call(SalesApi.oakBrookReport, {
      id,
    });
    const countriesNamesCache: Record<string, string> = {};

    const getCountryName = (countryId: string) => {
      if (!(countryId in countriesNamesCache)) {
        const countryCode = countries.find(
          i => parseInt(i.countryId, 10) === parseInt(countryId, 10)
        )?.countryCode as string;
        countriesNamesCache[countryId] = t(`operativeStructure.countries.${countryCode}`);
      }
      return countriesNamesCache[countryId];
    };

    const rows = data.map(i => ({
      country: getCountryName(i.country_id),
      journalType: i.journal_type,
      setOfBooksId: i.set_of_books_id,
      currencyCode: i.currency_code,
      accountingDate: i.accounting_date,
      companyId: i.company_id,
      companyName: i.company_name,
      journalSubtype: i.journal_subtype,
      account: i.account,
      Subaccount: i.sub_account,
      costCenter: i.cost_center,
      store: i.store,
      credit: i.credit,
      debit: i.debit,
    }));

    const worksheet = utils.json_to_sheet(rows);
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          t(`oakBrook.report.country`),
          t(`oakBrook.report.journalType`),
          t(`oakBrook.report.setOfBooksId`),
          t(`oakBrook.report.currency`),
          t(`oakBrook.report.accountingDate`),
          t(`oakBrook.report.companyId`),
          t(`oakBrook.report.companyName`),
          t(`oakBrook.report.journalSubtype`),
          t(`oakBrook.report.account`),
          t(`oakBrook.report.subAccount`),
          t(`oakBrook.report.costCenter`),
          t(`oakBrook.report.store`),
          t(`oakBrook.report.credit`),
          t(`oakBrook.report.debit`),
        ],
      ],
      { origin: 'A1' }
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'OakBrook');
    writeFile(workbook, `accounting_entries_${id}.xlsx`);

    yield put(oakBrookReportSuccess());
  } catch (error) {
    yield put(oakBrookReportFailure(error as HttpRequestError));
  }
}
*/

export default [
  takeLatest(ActionType.oakBrookFindRequested, findOakBrook),
  takeLatest(ActionType.oakBrookDownloadRequested, downloadOakBrook),
];
