import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeClosingFilterCauseTypes,
  reportClosingFilterCauseTypesSelector,
  reportCauseTypesSelector,
  findCauseTypesRequested,
} from '../../../state';
import { MultiSelect } from '../../selects';
import { MissingCauses } from '../../../types';

const CauseTypeFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const causeTypesResource = useSelector(reportCauseTypesSelector);
  const causeTypes = useSelector(reportClosingFilterCauseTypesSelector);
  const disabled = !causeTypesResource.loaded;
  const causeTypesOptions = causeTypesResource.data || [];

  const handleChange = useCallback(
    (value: MissingCauses[]) => dispatch(changeClosingFilterCauseTypes(value)),
    [dispatch]
  );

  useEffect(() => {
    if (!causeTypesResource.loaded && !causeTypesResource.loading && !causeTypesResource.failure) {
      dispatch(findCauseTypesRequested());
    }
  }, [dispatch, causeTypesResource]);

  return (
    <MultiSelect<MissingCauses>
      label={t('missing-closing-detail.filters.causes')}
      options={causeTypesOptions}
      translationKey="missing-closing-detail.causes"
      disabled={disabled}
      value={causeTypes}
      onChange={handleChange}
      loading={causeTypesResource.loading}
      selectAll
    />
  );
};

export default CauseTypeFilter;
