import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import { isModeModalSelector, modeModal } from '../../state';
import Header from './Header';
import Sidebar from './Sidebar';

const Container = styled.div`
  display: flex;
  height: 100%;
  padding-top: 56px;

  ${props => `${props.theme.breakpoints.up('xs')} and (orientation: landscape)`} {
    padding-top: 48px;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: 64px;
  }
`;

const Main = styled.main`
  flex-grow: 1;
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing(3)};
`;

const routesModeModal = Object.values(routes.protectedRoutes)
  .filter(i => i.modeModal)
  .map(i => i.path);

const ModeModalHandle = () => {
  const isModeModal = useSelector(isModeModalSelector);
  const location = useLocation();
  const shouldShowModeModal = routesModeModal.includes(location.pathname);
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldShowModeModal !== isModeModal) dispatch(modeModal(shouldShowModeModal));
  }, [shouldShowModeModal, isModeModal, dispatch]);
  return null;
};

const PrivateLayout = () => (
  <Container>
    <ModeModalHandle />
    <Header />
    <Sidebar />
    <Main>
      <Outlet />
    </Main>
  </Container>
);

export default PrivateLayout;
