import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import Copyright from './Copyright';

const ContainerMain = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PublicLayout = () => (
  <Container component="main" maxWidth="xs">
    <ContainerMain>
      <Outlet />
    </ContainerMain>
    <Box mt={8}>
      <Copyright />
    </Box>
  </Container>
);

export default PublicLayout;
