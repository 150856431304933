import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge } from '@mui/material';
import FiltersDialogSelector from './FiltersDialogSelector';
import { useReportFilters } from '../ReportFiltersProvider';
import { reportFilterCountryCodeSelector } from '../../../state';
import { useFilterAppliedLength } from '../../../hooks';

const FilterDialogWrapper = () => {
  const { t } = useTranslation();
  const hasCountry = Boolean(useSelector(reportFilterCountryCodeSelector));
  const { loadingSelector, dialogFilters, dialogFiltersApplied } = useReportFilters();
  const badgeContent = useFilterAppliedLength(dialogFiltersApplied || []);
  const disabled = useSelector(loadingSelector) || !hasCountry;

  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleClick = useCallback(() => setOpen(true), []);
  const handleClickApply = useCallback(() => {
    handleClose();
  }, [handleClose]);
  return (
    <>
      <Tooltip title={t('toolbar.labels.filter') as string}>
        <Badge badgeContent={badgeContent} color="primary">
          <Button color="primary" variant="contained" onClick={handleClick} disabled={disabled}>
            <FilterListIcon />
          </Button>
        </Badge>
      </Tooltip>
      {open && (
        <FiltersDialogSelector onApply={handleClickApply} onClose={handleClose}>
          {dialogFilters}
        </FiltersDialogSelector>
      )}
    </>
  );
};

const FilterDialog = () => {
  const { dialogFilters } = useReportFilters();

  return dialogFilters ? <FilterDialogWrapper /> : null;
};

export default FilterDialog;
