import { useEffect } from 'react';
import { useCountriesSelect } from '../../../hooks';
import { Country } from '../../../types';
import MultiSelect, { MultiSelectProps } from '../MultiSelect';

export interface CountryMultiSelectProps
  extends Omit<MultiSelectProps<Country>, 'options' | 'propDesc'> {
  propId?: keyof Country;
  translationKey?: string;
  selectAll?: boolean;
}

const CountryMultiSelect = ({
  propId,
  translationKey,
  onChange,
  value,
  selectAll,
  ...others
}: CountryMultiSelectProps) => {
  const { options, loading, disabled } = useCountriesSelect();

  useEffect(() => {
    if (options.length === 1 && !value?.find(i => i.countryCode === options[0].countryCode)) {
      onChange(options);
    }
  }, [value, onChange, options]);

  return (
    <MultiSelect<Country>
      {...others}
      options={options}
      disabled={disabled}
      value={value}
      loading={loading}
      onChange={onChange}
      propDesc={propId}
      translationKey={translationKey}
      selectAll={selectAll}
    />
  );
};

export default CountryMultiSelect;
