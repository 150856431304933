import { Actions, State, ActionType } from './types';
import {
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  filters: {
    country: '',
  },
  monthClosing: remoteResourceInitialState([]),
  annul: undefined,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.monthClosingChangeFilterCountry:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            country: action.country,
          },
        },
      };
    case ActionType.monthClosingFindRequested:
      return {
        ...state,
        monthClosing: remoteResourceLoading([]),
      };
    case ActionType.monthClosingFindSuccess:
      return {
        ...state,
        monthClosing: remoteResourceLoadSuccess(action.monthClosing),
      };
    case ActionType.monthClosingFindFailure:
      return {
        ...state,
        monthClosing: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.monthClosingChangeAnnul:
      return {
        ...state,
        annul: action.monthClosing,
      };
    default:
      return state;
  }
};

export default reducer;
