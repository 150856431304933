import { ExportFormat, ExportType } from './types';

export abstract class TablesExportBase<T = ExportFormat> {
  #tables: Record<string, ExportType<T> & { order: number }> = {};

  #format: T;

  constructor(format: T) {
    this.#format = format;
  }

  get tables() {
    return Object.values(this.#tables).sort((a, b) => a.order - b.order);
  }

  isFormat(format: ExportFormat) {
    return (this.#format as never) === (format as never);
  }

  addTable(id: string, order: number, table: ExportType<T>) {
    this.#tables[id] = { order, ...table };
  }

  pushTable(id: string, table: ExportType<T>) {
    this.#tables[id] = { order: Object.keys(this.#tables).length + 1, ...table };
  }

  abstract export(filename: string): void;
}
