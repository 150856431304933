import { Navigate, useSearchParams } from 'react-router-dom';

interface Props {
  otherwise: string;
}

const NavigateFromReturnUrl = ({ otherwise }: Props) => {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');
  const to = returnUrl || otherwise;

  return <Navigate to={to} />;
};

export default NavigateFromReturnUrl;
