import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../../state';
import MultiSelect, { MultiSelectProps } from './MultiSelect';
import { PosType } from '../../types';

type SegmentsSelectProps = Omit<MultiSelectProps<PosType>, 'options' | 'propDesc'>;

const posTypesSort = (a: PosType, b: PosType) => (a.segmentDesc > b.segmentDesc ? 1 : -1);

const SegmentsSelect = (props: SegmentsSelectProps) => {
  const masterData = useSelector(masterDataSelector);

  const posTypesOptions = useMemo(() => {
    const posTypes = masterData.loaded && masterData.data ? masterData.data.posTypes : [];
    return posTypes.sort(posTypesSort);
  }, [masterData]);

  return (
    <MultiSelect<PosType>
      {...props}
      options={posTypesOptions}
      propDesc="segmentDesc"
      loading={masterData.loading}
    />
  );
};

export default SegmentsSelect;
