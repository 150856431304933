import { Actions, State, ActionType } from './types';
import {
  getDateValue,
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  filters: {
    country: null,
    accountingMonth: getDateValue(null),
    journalType: null,
    status: null,
  },
  accountingEntries: remoteResourceInitialState([]),
  downloadingReportId: undefined,
  delete: undefined,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionType.accountingEntriesChangeFilterCountry:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            country: action.country,
          },
        },
      };
    case ActionType.accountingEntriesChangeFilterAccountingMonth:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            accountingMonth: getDateValue(action.accountingMonth),
          },
        },
      };
    case ActionType.accountingEntriesChangeFilterJournalType:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            journalType: action.journalType,
          },
        },
      };
    case ActionType.accountingEntriesChangeFilterStatus:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            status: action.status,
          },
        },
      };
    case ActionType.accountingEntriesFindRequested:
      return {
        ...state,
        accountingEntries: remoteResourceLoading([]),
      };
    case ActionType.accountingEntriesFindSuccess:
      return {
        ...state,
        accountingEntries: remoteResourceLoadSuccess(action.accountingEntries),
      };
    case ActionType.accountingEntriesFindFailure:
      return {
        ...state,
        accountingEntries: remoteResourceLoadFailure([], action.error),
      };
    case ActionType.accountingEntriesReportRequested:
      return {
        ...state,
        downloadingReportId: action.id,
      };
    case ActionType.accountingEntriesReportSuccess:
      return {
        ...state,
        downloadingReportId: undefined,
      };
    case ActionType.accountingEntriesReportFailure:
      return {
        ...state,
        downloadingReportId: undefined,
      };
    case ActionType.accountingEntriesChangeDelete:
      return {
        ...state,
        delete: action.accountingEntry,
      };
    default:
      return state;
  }
};

export default reducer;
