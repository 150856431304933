import styled from '@emotion/styled';
import { ReactNode } from 'react';

const StyledDiv = styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(81, 81, 81, 1);
  text-align: left;
  padding: 7px 7px 7px 24px;
  color: white;
`;

interface DataGridFlexSalesHeaderProps {
  children: ReactNode;
}

const DataGridFlexSalesHeader = ({ children }: DataGridFlexSalesHeaderProps) => (
  <StyledDiv>{children}</StyledDiv>
);

export default DataGridFlexSalesHeader;
