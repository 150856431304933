import Grid from '@mui/material/Grid';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  MonthClosingActionType,
  monthClosingChangeAnnul,
  monthClosingFindRequested,
  userInfoNameSelector,
} from '../../state';
import { AnnulMonthClosingForm, MonthClosing } from '../../types';
import DialogForm from '../DialogForm';
import { FlexSalesForm, FlexSalesFormActions, TextFieldFormInput } from '../FlexSalesForm';
import { getSchema, justificationMaxLength } from './schema';
import { useListener } from '../../hooks';

const inputProps = { maxLength: justificationMaxLength };

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

interface MonthClosingAnnulFormProps {
  monthClosing: MonthClosing;
}

const MonthClosingAnnulForm = ({ monthClosing }: MonthClosingAnnulFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const userName = useSelector(userInfoNameSelector);
  const defaultValues: AnnulMonthClosingForm = useMemo(
    () => ({
      ...monthClosing,
      updatedBy: userName as string,
      justification: '',
    }),
    [monthClosing, userName]
  );

  const formListener = useListener(
    MonthClosingActionType.monthClosingAnnulRequested,
    MonthClosingActionType.monthClosingAnnulSuccess,
    MonthClosingActionType.monthClosingAnnulFailure
  );
  const dispatch = useDispatch();
  const resolver = useMemo(() => yupResolver(getSchema()), []);
  const handleCancel = useCallback(() => {
    dispatch(monthClosingChangeAnnul(undefined));
  }, [dispatch]);

  const onSubmit = async (data: AnnulMonthClosingForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('monthClosing.messages.annulSuccess'), messageOptions);
    dispatch(monthClosingFindRequested());
  };

  return (
    <DialogForm title={t('monthClosing.titles.annul')} maxWidth="md">
      <FlexSalesForm onValidSubmit={onSubmit} defaultValues={defaultValues} resolver={resolver}>
        <Grid container flexDirection="column">
          <Grid item xs>
            <TextFieldFormInput
              name="justification"
              type="text"
              variant="standard"
              label={t('monthClosing.labels.justification')}
              required
              inputProps={inputProps}
              rows={3}
              multiline
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('companyCodes.labels.cancel')}
          submitLabel={t('monthClosing.labels.annul')}
        />
      </FlexSalesForm>
    </DialogForm>
  );
};

export default MonthClosingAnnulForm;
