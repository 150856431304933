import styled from '@emotion/styled';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';

export const GridRoot = styled(Grid.Root)`
  width: 100%;
  height: 100%;
`;

export const VirtualTabledRow = styled(VirtualTable.Row)`
  :hover {
    background-color: ${props => props.theme.palette.action.hover};
  }
`;

export const TableHeaderRowRow = styled(TableHeaderRow.Row)`
  background-color: ${props => props.theme.palette.primary.main};
`;
