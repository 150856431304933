import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { masterDataSelector } from '../state';
import { Country } from '../types';

type useCountryProps =
  | {
      countryId: string;
    }
  | { countryCode: string };

type Predicate = (c: Country) => boolean;

const useCountry = (props: useCountryProps): Country | undefined => {
  const masterData = useSelector(masterDataSelector);

  const country = useMemo(() => {
    const predicate: Predicate = c =>
      'countryCode' in props
        ? c.countryCode === props.countryCode
        : parseInt(c.countryId, 10) === parseInt(props.countryId, 10);

    const countries = masterData.loaded && masterData.data ? masterData.data.countries : [];
    return countries.find(predicate);
  }, [masterData, props]);

  return country;
};

export default useCountry;
