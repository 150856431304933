import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useManagement from '../../../hooks/useManagement';
import {
  changeOpStrucuresFilterManagement,
  reportFilterCountryCodeSelector,
  reportOpStructuresFilterManagementSelector,
  reportOpStructuresFilterRegionalManagementSelector,
  reportOpStructuresFilterRegionSelector,
  reportOpStructuresFilterSupervisionSelector,
} from '../../../state';
import { Management } from '../../../types';
import { MultiSelect } from '../../selects';

const ManagementFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const countryCode = useSelector(reportFilterCountryCodeSelector);
  const managements = useSelector(reportOpStructuresFilterManagementSelector);
  const regionalManagement = useSelector(reportOpStructuresFilterRegionalManagementSelector);
  const regions = useSelector(reportOpStructuresFilterRegionSelector);
  const supervisions = useSelector(reportOpStructuresFilterSupervisionSelector);
  const { options, disabled, loading } = useManagement(
    countryCode,
    regionalManagement,
    regions,
    supervisions
  );

  const handleChange = useCallback(
    (value: Management[]) => dispatch(changeOpStrucuresFilterManagement(value)),
    [dispatch]
  );

  return (
    <MultiSelect
      label={t('toolbar.labels.management')}
      options={options}
      propDesc="label"
      disabled={disabled}
      value={managements}
      onChange={handleChange}
      loading={loading}
    />
  );
};

export default ManagementFilter;
