import Grid from '@mui/material/Grid';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import routes from '../../config/routes';
import { AccountingEntriesActionType, accountingEntriesFindRequested } from '../../state';
import { AppPermissions, CreateAccountingEntriesForm } from '../../types';
import JournalGroupFormInput from './JournalGroupFormInput';
import { PermissionsProvider } from '../Permissions';
import { DatePickerFlexSalesView } from '../DatePickerFlexSales';
import {
  CountrySelectFormInput,
  DatePickerFormInput,
  FlexSalesForm,
  FlexSalesFormActions,
} from '../FlexSalesForm';
import { useListener } from '../../hooks';
import { getSchema, maxDate, minDate } from './schema';

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

const initialValues: CreateAccountingEntriesForm = {
  accountingMonth: null,
  countryId: '',
  journalGroup: '',
};

const views: DatePickerFlexSalesView[] = ['year', 'month'];

const AccountingEntriesForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const resolver = useMemo(() => yupResolver(getSchema()), []);

  const formListener = useListener(
    AccountingEntriesActionType.accountingEntriesCreateRequested,
    AccountingEntriesActionType.accountingEntriesCreateSuccess,
    AccountingEntriesActionType.accountingEntriesCreateFailure
  );
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.accountingEntries.index.path);
  }, [navigate]);

  const onSubmit = async (data: CreateAccountingEntriesForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('accountingEntries.messages.createSuccess'), messageOptions);
    dispatch(accountingEntriesFindRequested());
  };

  return (
    <PermissionsProvider
      resourcePermissions={[
        AppPermissions.ACCOUNTING_ENTRIES_COMPLETE,
        AppPermissions.ACCOUNTING_ENTRIES_EXECUTION,
      ]}
    >
      <FlexSalesForm defaultValues={initialValues} onValidSubmit={onSubmit} resolver={resolver}>
        <Grid container flexDirection="column" rowSpacing={1}>
          <Grid item xs>
            <CountrySelectFormInput name="countryId" required propId="countryId" fullWidth />
          </Grid>
          <Grid item xs>
            <DatePickerFormInput
              views={views}
              name="accountingMonth"
              label={t('accountingEntries.columns.accountingMonth')}
              required
              maxDate={maxDate}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs>
            <JournalGroupFormInput name="journalGroup" required fullWidth />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('accountingParameters.labels.cancel')}
          submitLabel={t('accountingEntries.labels.createAccountingEntries')}
        />
      </FlexSalesForm>
    </PermissionsProvider>
  );
};

export default AccountingEntriesForm;
