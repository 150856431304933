import { Action } from 'redux';
import {
  CreateReprocessForm,
  HttpErrorBaseAction,
  RemoteResource,
  Reprocess,
  ReprocessFilter,
  ReprocessParameters,
} from '../../types';

export interface State {
  parameters: RemoteResource<ReprocessParameters | undefined>;
  filters: ReprocessFilter;
  reprocess: RemoteResource<Reprocess[]>;
}

export enum ActionType {
  parametersRequested = 'reprocess/parametersRequested',
  parametersSuccess = 'reprocess/parametersSuccess',
  parametersFailure = 'reprocess/parametersFailure',
  reprocessChangeFilterCountry = 'reprocess/changeFilterCountry',
  reprocessChangeFilterStore = 'reprocess/changeFilterStore',
  reprocessChangeFilterFederativeEntity = 'reprocess/changeFilterFederativeEntity',
  reprocessChangeFilterBusinessDate = 'reprocess/changeFilterBusinessDate',
  reprocessChangeFilterStatus = 'reprocess/changeFilterStatus',
  reprocessChangeFilterReprocessType = 'reprocess/changeFilterReprocessType',
  reprocessFindRequested = 'reprocess/reprocessFindRequested',
  reprocessFindSuccess = 'reprocess/reprocessFindSuccess',
  reprocessFindFailure = 'reprocess/reprocessFindFailure',
  reprocessCreateRequested = 'reprocess/reprocessCreateRequested',
  reprocessCreateSuccess = 'reprocess/reprocessCreateSuccess',
  reprocessCreateFailure = 'reprocess/reprocessCreateFailure',
}

export type ReprocessParametersRequestedAction = Action<ActionType.parametersRequested>;

export type ReprocessParametersSuccessAction = Action<ActionType.parametersSuccess> & {
  parameters: ReprocessParameters;
};

export type ReprocessParametersFailureAction = HttpErrorBaseAction<ActionType.parametersFailure>;

export interface ReprocessChangeFilterCountryAction
  extends Action<ActionType.reprocessChangeFilterCountry> {
  countryCode: string;
}

export interface ReprocessChangeFilterStoreAction
  extends Action<ActionType.reprocessChangeFilterStore> {
  storeAcronym: string;
}

export interface ReprocessChangeFilterFederativeEntityAction
  extends Action<ActionType.reprocessChangeFilterFederativeEntity> {
  federativeEntity: string;
}

export interface ReprocessChangeFilterBusinessDateAction
  extends Action<ActionType.reprocessChangeFilterBusinessDate> {
  businessDate: Date | null;
}

export interface ReprocessChangeFilterStatusAction
  extends Action<ActionType.reprocessChangeFilterStatus> {
  status: string;
}

export interface ReprocessChangeFilterReprocessTypeAction
  extends Action<ActionType.reprocessChangeFilterReprocessType> {
  reprocessType: string;
}

export type ReprocessFindRequestedAction = Action<ActionType.reprocessFindRequested>;

export type ReprocessFindSuccessAction = Action<ActionType.reprocessFindSuccess> & {
  reprocess: Reprocess[];
};

export type ReprocessFindFailureAction = HttpErrorBaseAction<ActionType.reprocessFindFailure>;

export interface ReprocessCreateRequestedAction
  extends Action<ActionType.reprocessCreateRequested> {
  payload: CreateReprocessForm;
}

export type ReprocessCreateSuccessAction = Action<ActionType.reprocessCreateSuccess>;

export type ReprocessCreateFailureAction = HttpErrorBaseAction<ActionType.reprocessCreateFailure>;

export type Actions =
  | ReprocessParametersRequestedAction
  | ReprocessParametersSuccessAction
  | ReprocessParametersFailureAction
  | ReprocessChangeFilterCountryAction
  | ReprocessChangeFilterStoreAction
  | ReprocessChangeFilterFederativeEntityAction
  | ReprocessChangeFilterBusinessDateAction
  | ReprocessChangeFilterStatusAction
  | ReprocessChangeFilterReprocessTypeAction
  | ReprocessFindRequestedAction
  | ReprocessFindSuccessAction
  | ReprocessFindFailureAction
  | ReprocessCreateRequestedAction
  | ReprocessCreateSuccessAction
  | ReprocessCreateFailureAction;
