import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stores } from '../../types';
import { masterDataSelector } from '../../state';
import MultiSelectFormInput, { MultiSelectFormInputProps } from './MultiSelectFormInput';

export type StoreMultiSelectFormInputProps = Omit<
  MultiSelectFormInputProps<Stores>,
  'options' | 'value'
> & {
  countryCode: string;
};

const storesSort = (a: Stores, b: Stores) => (a.storeAcronym > b.storeAcronym ? 1 : -1);
const isOptionEqualToValue = (store1: Stores, store2: Stores) =>
  store1.storeAcronym === store2.storeAcronym;

const StoreMultiSelectFormInput = ({
  disabled,
  countryCode,
  ...rest
}: StoreMultiSelectFormInputProps) => {
  const masterData = useSelector(masterDataSelector);

  const storesForDivisonCountry = useMemo(() => {
    const stores = masterData.loaded && masterData.data ? masterData.data.stores : [];
    return stores.filter(i => i.countryCode === countryCode).sort(storesSort);
  }, [masterData, countryCode]);

  const isDisabled = disabled || !countryCode;

  return (
    <MultiSelectFormInput<Stores>
      {...rest}
      options={storesForDivisonCountry}
      getOptionLabel="storeAcronym"
      disabled={isDisabled}
      loading={masterData.loading}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default StoreMultiSelectFormInput;
