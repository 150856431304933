import * as yup from 'yup';
import { EditAccountingParameterForm } from '../../types';

export const getSchema = (): yup.ObjectSchema<EditAccountingParameterForm> =>
  yup.object().shape({
    countryId: yup.string().required(),
    journalType: yup.string().required(),
    frequency: yup.string().required(),
    details: yup
      .array()
      .of(
        yup.object().shape({
          journalSubtype: yup.string().required(),
          account: yup.string().required(),
          subAccount: yup.string().required(),
        })
      )
      .required(),
  });
