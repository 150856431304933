import Button, { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type GoHomeButtonProps = Omit<ButtonProps, 'onClick'>;

const GoHomeButton = ({ children, ...others }: GoHomeButtonProps) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const label = children || t('components.buttons.GoHomeButton.label');
  const handleClick = () => navigation('/');
  return (
    <Button {...others} onClick={handleClick}>
      {label}
    </Button>
  );
};
export default GoHomeButton;
