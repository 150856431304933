import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  AccountingEntriesSearch,
  AccountingEntriesDataGrid,
  AccountingEntryDeleteForm,
} from '../components';
import { accountingEntriesDeleteSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const AccountingEntries = () => {
  const accountingEntryDelete = useSelector(accountingEntriesDeleteSelector);
  return (
    <>
      <Container>
        <AccountingEntriesSearch />
        <AccountingEntriesDataGrid />
        {accountingEntryDelete && (
          <AccountingEntryDeleteForm accountingEntry={accountingEntryDelete} />
        )}
      </Container>
      <Outlet />
    </>
  );
};

export default AccountingEntries;
