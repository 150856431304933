import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { AccountingDetailParameter } from '../../types';
import { HeaderRowTableFlexSales, TableFlexSales } from '../TableFlexSales';

interface RowProps {
  details: AccountingDetailParameter[];
  open: boolean;
}

const TableDetail = ({ details, open }: RowProps) => {
  const { t } = useTranslation();

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <TableFlexSales title={t('accountingParameters.titles.tableDetail')}>
          <TableHead>
            <HeaderRowTableFlexSales>
              <TableCell>{t('accountingParameters.columns.journalSubType')}</TableCell>
              <TableCell>{t('accountingParameters.columns.account')}</TableCell>
              <TableCell>{t('accountingParameters.columns.subAccount')}</TableCell>
            </HeaderRowTableFlexSales>
          </TableHead>
          <TableBody>
            {details.map(item => (
              <TableRow key={item.journalSubtype}>
                <TableCell>{item.journalSubtype}</TableCell>
                <TableCell>{item.account}</TableCell>
                <TableCell>{item.subAccount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableFlexSales>
      </Box>
    </Collapse>
  );
};

export default TableDetail;
