import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PodOptionType } from '../../../types';
import { changeFilterStoreMotherChild, reportFilterStoreMotherChildSelector } from '../../../state';
import { SelectFlexSales } from '../../selects';

const options: PodOptionType[] = ['CNPJ', 'MOTHER'];

const StoreMotherChildFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector(reportFilterStoreMotherChildSelector);

  const handleChange = useCallback(
    (value: PodOptionType[]) => dispatch(changeFilterStoreMotherChild(value)),
    [dispatch]
  );

  const getOptionLabel = useCallback((option: PodOptionType) => t(`filters.pod.${option}`), [t]);

  return (
    <SelectFlexSales
      label={t('toolbar.labels.pod')}
      multiple
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={handleChange}
    />
  );
};

export default StoreMotherChildFilter;
