import { Actions, State, ActionType } from './types';
import { AppStorage } from '../../services';

const initialState: State = AppStorage.getUserPreferences() || {
  theme: 'system',
  showSaveDialog: true,
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.changeTheme:
      return { ...state, theme: action.theme };
    case ActionType.changeShowSaveDialog:
      return { ...state, showSaveDialog: action.showSaveDialog };
    default:
      return state;
  }
};

export default reducer;
