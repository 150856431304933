import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../config/routes';

interface GcsSwitchProps {
  checked?: boolean;
}

const GcsSwitch = ({ checked }: GcsSwitchProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () =>
    navigate(
      checked ? routes.protectedRoutes.gcs.path : routes.protectedRoutes.gcsPaymentTypes.path
    );
  return (
    <FormGroup>
      <FormControlLabel
        onClick={handleClick}
        control={<Switch checked={checked} />}
        label={t('gcs-payment-type.title') as string}
      />
    </FormGroup>
  );
};

export default GcsSwitch;
