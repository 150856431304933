import styled from '@emotion/styled';
import { SalesJournalDataGrid, SalesJournalSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiToolbar-root {
    margin-top: ${props => props.theme.spacing(3)};
  }
`;

const SaleJournal = () => (
  <Container>
    <SalesJournalSearch />
    <SalesJournalDataGrid />
  </Container>
);

export default SaleJournal;
