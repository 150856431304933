import { HttpRequestError, AccountingEntry, Country, AccountingEntryStatus } from '../../types';
import {
  ActionType,
  AccountingEntriesChangeFilterCountryAction,
  AccountingEntriesFindFailureAction,
  AccountingEntriesFindRequestedAction,
  AccountingEntriesFindSuccessAction,
  AccountingEntriesCreateSuccessAction,
  AccountingEntriesCreateFailureAction,
  AccountingEntriesChangeFilterAccountingMonthAction,
  AccountingEntriesChangeFilterJournalTypeAction,
  AccountingEntriesChangeFilterStatusAction,
  AccountingEntriesReportRequestedAction,
  AccountingEntriesReportSuccessAction,
  AccountingEntriesReportFailureAction,
  AccountingEntriesChangeDeleteAction,
  AccountingEntriesDeleteSuccessAction,
  AccountingEntriesDeleteFailureAction,
} from './types';

export const accountingEntriesChangeFilterCountry = (
  country: Country | null
): AccountingEntriesChangeFilterCountryAction => ({
  type: ActionType.accountingEntriesChangeFilterCountry,
  country,
});

export const accountingEntriesChangeFilterAccountingMonth = (
  accountingMonth: Date | null
): AccountingEntriesChangeFilterAccountingMonthAction => ({
  type: ActionType.accountingEntriesChangeFilterAccountingMonth,
  accountingMonth,
});

export const accountingEntriesChangeFilterJournalType = (
  journalType: string | null
): AccountingEntriesChangeFilterJournalTypeAction => ({
  type: ActionType.accountingEntriesChangeFilterJournalType,
  journalType,
});

export const accountingEntriesChangeFilterStatus = (
  status: AccountingEntryStatus | null
): AccountingEntriesChangeFilterStatusAction => ({
  type: ActionType.accountingEntriesChangeFilterStatus,
  status,
});

export const accountingEntriesFindRequested = (): AccountingEntriesFindRequestedAction => ({
  type: ActionType.accountingEntriesFindRequested,
});

export const accountingEntriesFindSuccess = (
  accountingEntries: AccountingEntry[]
): AccountingEntriesFindSuccessAction => ({
  type: ActionType.accountingEntriesFindSuccess,
  accountingEntries,
});

export const accountingEntriesFindFailure = (
  error: HttpRequestError
): AccountingEntriesFindFailureAction => ({
  type: ActionType.accountingEntriesFindFailure,
  error,
});

export const accountingEntriesCreateSuccess = (): AccountingEntriesCreateSuccessAction => ({
  type: ActionType.accountingEntriesCreateSuccess,
});

export const accountingEntriesCreateFailure = (
  error: HttpRequestError
): AccountingEntriesCreateFailureAction => ({
  type: ActionType.accountingEntriesCreateFailure,
  error,
});

export const accountingEntriesReportRequested = (
  id: number
): AccountingEntriesReportRequestedAction => ({
  type: ActionType.accountingEntriesReportRequested,
  id,
});

export const accountingEntriesReportSuccess = (): AccountingEntriesReportSuccessAction => ({
  type: ActionType.accountingEntriesReportSuccess,
});

export const accountingEntriesReportFailure = (
  error: HttpRequestError
): AccountingEntriesReportFailureAction => ({
  type: ActionType.accountingEntriesReportFailure,
  error,
});

export const accountingEntriesChangeDelete = (
  accountingEntry: AccountingEntry | undefined
): AccountingEntriesChangeDeleteAction => ({
  type: ActionType.accountingEntriesChangeDelete,
  accountingEntry,
});

export const accountingEntriesDeleteSuccess = (): AccountingEntriesDeleteSuccessAction => ({
  type: ActionType.accountingEntriesDeleteSuccess,
});

export const accountingEntriesDeleteFailure = (
  error: HttpRequestError
): AccountingEntriesDeleteFailureAction => ({
  type: ActionType.accountingEntriesDeleteFailure,
  error,
});
