import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import routes from '../../config/routes';
import { hasPermissions } from '../../helpers';
import { AppPermissions } from '../../types';
import { isAuthenticatedSelector, userPermissionsDataSelector } from '../../state';
import NavigateWithReturnUrl from './NavigateWithReturnUrl';

interface PrivateRouteProps {
  component: FunctionComponent;
  permissions?: AppPermissions[];
}

const PrivateRoute = ({
  component: Component,
  permissions: routePermissions,
}: PrivateRouteProps) => {
  const { forbiddenComponent: Forbidden } = routes;
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  if (!isAuthenticated) return <NavigateWithReturnUrl path={routes.signinRoute.path} />;
  const isAuthorized = !routePermissions || hasPermissions(userPermissions, routePermissions);
  return isAuthorized ? <Component /> : <Forbidden />;
};

export default PrivateRoute;
