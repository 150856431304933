import Grid from '@mui/material/Grid';
import {
  reportGCsByProductLoadingSelector,
  findGCsByProductRequested,
  changeFilterDiscountsJoin,
  reportFilterDiscountsJoinSelector,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  CountryFilter,
  HourRangeFilter,
  PosFilter,
  SegmentsFilter,
  StoreFilter,
  SalesTypesFilter,
  BusinessDateRangeFilter,
  AccountingDateRangeFilter,
  OperationDateRangeFilter,
  PluFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilter } from '../types';
import { PermissionsProvider } from './Permissions';
import { DiscountsProps } from './ReportFilters/ReportFiltersProvider';

const dialogFilters = (
  <>
    <OperationDateRangeFilter />
    <AccountingDateRangeFilter />
  </>
);

const onedriveAction = () => findGCsByProductRequested(true);

const dialogFiltersApplied: (keyof ReportFilter)[] = [
  'startOperationDate',
  'endOperationDate',
  'startAccountingDate',
  'endAccountingDate',
];

const discountsConfig: DiscountsProps = {
  options: {
    sale: false,
    item: false,
  },
  action: changeFilterDiscountsJoin,
  selector: reportFilterDiscountsJoinSelector,
  showJoins: true,
};

const GCsByProductSearch = () => (
  <ReportFilters
    loadingSelector={reportGCsByProductLoadingSelector}
    applyAction={findGCsByProductRequested}
    onedriveAction={onedriveAction}
    dialogFilters={dialogFilters}
    dialogFiltersApplied={dialogFiltersApplied}
    discountsConfig={discountsConfig}
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={4} xl={2}>
          <SalesTypesFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <PluFilter />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default GCsByProductSearch;
