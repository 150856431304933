import styled from '@emotion/styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material';
import { addSeconds, format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../../helpers';
import { hasPermissions } from '../../helpers/utls';
import {
  activeCountriesSelector,
  changeAutoUpdateTimer,
  dashboardAutoUpdateTimerSelector,
  filterParametersDataSelector,
  findAllRequest,
  userPermissionsDataSelector,
} from '../../state';
import Countdown from './Countdown';
import Dashboards from './Dashboards';

const MainPannel = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
});

const PannelContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  justifyContent: 'end',
});

const PannelInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  opacity: 0.8,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginInline: theme.spacing(0.5),
}));

function getFormattedTime(time: number) {
  const result = Math.round((time - new Date().getTime()) / 1000);
  return result;
}

const DashboardData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [initialDispatch, setInitialDispatch] = useState(false);

  const activeCountriesResource = useSelector(activeCountriesSelector);
  const userPermissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const autoUpdate = useSelector(dashboardAutoUpdateTimerSelector);
  const appParameters = useSelector(filterParametersDataSelector);
  const dashboardTimer = appParameters?.dashboardTimer || constants.defaultDashboardTimer;

  const [formattedTime, setFormattedTime] = useState(getFormattedTime(autoUpdate.time));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resources = Dashboards.map(i => useSelector(i.resource));

  const loading = useMemo(() => resources.some(i => i.loading), [resources]);
  const loaded = useMemo(() => resources.every(i => i.loaded), [resources]);

  const updateTime = (updateState?: boolean) => {
    if (updateState) {
      dispatch(changeAutoUpdateTimer({ ...autoUpdate, time: autoUpdate.time - 1 }));
    } else {
      setFormattedTime(prev => prev - 1);
    }
  };

  const handleRefresh = () => {
    const newTime = new Date().getTime() + dashboardTimer * 60000;
    dispatch(changeAutoUpdateTimer({ time: newTime, lastUpdate: new Date() }));
    setFormattedTime(getFormattedTime(newTime));
    dispatch(findAllRequest());
  };

  const renderPannel = (title: string, value: string) => (
    <PannelInfo marginLeft={2} marginY={1}>
      <Typography variant="caption" sx={{ fontWeight: 500, marginTop: '1px' }}>
        {title}
      </Typography>
      <Typography variant="caption" sx={{ marginLeft: 1, marginTop: '1px' }}>
        {value}
      </Typography>
    </PannelInfo>
  );

  const countdownTimer = (counter: number) => {
    const formattedTime = format(addSeconds(new Date(0), counter), 'mm:ss');
    return renderPannel(t('dashboard.nextUpdate'), formattedTime);
  };

  useEffect(() => {
    if (activeCountriesResource.loaded && !loaded && !initialDispatch) {
      dispatch(findAllRequest());
      setInitialDispatch(true);
    }
  }, [activeCountriesResource, dispatch, loaded, initialDispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainPannel>
          <PannelContent component={Paper}>
            {renderPannel(
              t('dashboard.lastUpdate'),
              format(autoUpdate.lastUpdate, 'yyyy-MM-dd HH:mm:ss')
            )}
            <Countdown
              time={formattedTime}
              updateTime={updateTime}
              onComplete={handleRefresh}
              render={countdownTimer}
            />
            <StyledIconButton
              aria-label="refresh"
              size="small"
              onClick={handleRefresh}
              disabled={loading}
            >
              {!loading && <RefreshIcon color="primary" />}
              {loading && <CircularProgress size={15} />}
            </StyledIconButton>
          </PannelContent>
        </MainPannel>
      </Grid>
      {Dashboards.map(dashboard =>
        hasPermissions(userPermissions, dashboard.permissions) ? (
          <Grid key={dashboard.name} item {...dashboard.gridProps}>
            {dashboard.component}
          </Grid>
        ) : null
      )}
    </Grid>
  );
};

export default DashboardData;
