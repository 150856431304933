import { Actions, State, ActionType } from './types';
import {
  getDateValue,
  remoteResourceInitialState,
  remoteResourceLoadFailure,
  remoteResourceLoading,
  remoteResourceLoadSuccess,
} from '../../helpers';

const initialState: State = {
  parameters: remoteResourceInitialState(undefined),
  filters: {
    businessDate: getDateValue(null),
    countryCode: '',
    status: 'PENDING',
    storeAcronym: '',
    reprocessType: '',
    federativeEntity: '',
  },
  reprocess: remoteResourceInitialState([]),
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case ActionType.parametersRequested:
      return {
        ...state,
        parameters: remoteResourceLoading(undefined),
      };
    case ActionType.parametersSuccess:
      return {
        ...state,
        parameters: remoteResourceLoadSuccess(action.parameters),
      };
    case ActionType.parametersFailure:
      return {
        ...state,
        parameters: remoteResourceLoadFailure(undefined, action.error),
      };
    case ActionType.reprocessChangeFilterCountry:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            countryCode: action.countryCode,
            storeAcronym: '',
            federativeEntity: '',
          },
        },
      };
    case ActionType.reprocessChangeFilterStore:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            storeAcronym: action.storeAcronym,
          },
        },
      };
    case ActionType.reprocessChangeFilterFederativeEntity:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            federativeEntity: action.federativeEntity,
          },
        },
      };
    case ActionType.reprocessChangeFilterBusinessDate:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            businessDate: getDateValue(action.businessDate),
          },
        },
      };
    case ActionType.reprocessChangeFilterStatus:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            status: action.status,
          },
        },
      };
    case ActionType.reprocessChangeFilterReprocessType:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...{
            reprocessType: action.reprocessType,
          },
        },
      };
    case ActionType.reprocessFindRequested:
      return {
        ...state,
        reprocess: remoteResourceLoading([]),
      };
    case ActionType.reprocessFindSuccess:
      return {
        ...state,
        reprocess: remoteResourceLoadSuccess(action.reprocess),
      };
    case ActionType.reprocessFindFailure:
      return {
        ...state,
        reprocess: remoteResourceLoadFailure([], action.error),
      };
    default:
      return state;
  }
};

export default reducer;
