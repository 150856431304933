import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import {
  ActionType,
  CompanyCodesEditRequestedAction,
  CompanyCodesGetRequestedAction,
} from './types';
import { SalesApi, FindCompanyCodesResponse, GetCompanyCodeResponse } from '../../services';
import {
  companyCodesGetFailure,
  companyCodesGetSuccess,
  companyCodesFindFailure,
  companyCodesFindSuccess,
  companyCodesEditSuccess,
  companyCodesEditFailure,
} from './actionCreators';
import {
  HttpRequestError,
  CompanyCodesFilter,
  MasterData,
  CompanyCode,
  Company,
} from '../../types';
import {
  companyCodesEditRequestMap,
  findCompanyCodesRequestMap,
  findCompanyCodesResponseMap,
} from '../../helpers';
import { companyCodesFilterSelector, masterDataDataSelector } from '../selectors';

function* findCompanyCodes() {
  const filters: CompanyCodesFilter = yield select(companyCodesFilterSelector);
  const { companies }: MasterData = yield select(masterDataDataSelector);
  const companiesCountry = companies.filter(i =>
    i.countries.some(j => j.countryId === filters.countryId)
  );

  try {
    const { data }: AxiosResponse<FindCompanyCodesResponse> = yield call(
      SalesApi.findCompanyCodes,
      findCompanyCodesRequestMap(filters)
    );

    const companiesData = findCompanyCodesResponseMap(data);
    companiesCountry.forEach(i => {
      if (!companiesData.some(j => j.codeWh === i.companyId))
        companiesData.push({
          countryId: filters.countryId,
          codeWh: i.companyId,
          name: i.companyName,
        });
    });

    yield put(companyCodesFindSuccess(companiesData));
  } catch (error) {
    yield put(companyCodesFindFailure(error as HttpRequestError));
  }
}

function* getCompanyCodes(action: CompanyCodesGetRequestedAction) {
  try {
    const { data }: AxiosResponse<GetCompanyCodeResponse> = yield call(SalesApi.getCompanyCode, {
      countryId: action.countryId,
      companyCodeWh: action.companyId,
    });

    const { companies }: MasterData = yield select(masterDataDataSelector);

    const companiesData = findCompanyCodesResponseMap(data);

    let company = companiesData.find(i => i.codeWh === action.companyId);

    if (!company) {
      const companyAux = companies.find(
        i =>
          i.countries.some(j => j.countryId === action.countryId) &&
          i.companyId === action.companyId
      ) as Company;

      company = {
        codeWh: companyAux.companyId,
        name: companyAux.companyName,
        countryId: action.countryId,
      };
    }

    yield put(companyCodesGetSuccess(company as CompanyCode));
  } catch (error) {
    yield put(companyCodesGetFailure(error as HttpRequestError));
  }
}

function* editCompanyCode({ payload }: CompanyCodesEditRequestedAction) {
  try {
    yield call(SalesApi.editCompanyCodes, companyCodesEditRequestMap(payload));
    yield put(companyCodesEditSuccess());
  } catch (error) {
    yield put(companyCodesEditFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(ActionType.companyCodesFindRequested, findCompanyCodes),
  takeLatest(ActionType.companyCodesGetRequested, getCompanyCodes),
  takeLatest(ActionType.companyCodesEditRequested, editCompanyCode),
];
