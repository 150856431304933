import { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppPermissions, CreateReprocessForm } from '../../types';
import {
  ResetWhenFieldChanges,
  Condition,
  CountrySelectFormInput,
  DatePickerFormInput,
  FlexSalesForm,
  IbGroupSelectFormInput,
  ReprocessTypeFormInput,
  StoreMultiSelectFormInput,
  StoreSelectFormInput,
  TextFieldFormInput,
  useFlexSalesForm,
  FlexSalesFormActions,
  FederativeEntitySelectFormInput,
} from '../FlexSalesForm';

import routes from '../../config/routes';
import { PermissionsProvider } from '../Permissions';
import { useListener } from '../../hooks';
import { ReprocessActionType } from '../../state';

import {
  getSchema,
  maxDate,
  minDate,
  typesWithDate,
  typesWithDateRange,
  typesWithIbGroupsMultiple,
  typesWithStore,
  typesWithStoreMultiple,
  typesWithFederativeEntity,
} from './schema';

const initialValues: CreateReprocessForm = {
  country: '',
  store: '',
  stores: [],
  groupIds: [],
  businessDate: null,
  type: '',
  justification: '',
  businessDateFrom: null,
  businessDateTo: null,
};

const resourcePermissions = [AppPermissions.REPROCESS];

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

const StoreFormInput = () => {
  const { control } = useFlexSalesForm();
  const country = useWatch({ control, name: 'country' });
  return <StoreSelectFormInput name="store" countryCode={country} required fullWidth />;
};

const IbGroupFormInput = () => {
  const { t } = useTranslation();
  const { control } = useFlexSalesForm();
  const country = useWatch({ control, name: 'country' });
  return (
    <IbGroupSelectFormInput
      label={t('reprocess.labels.ibGroup')}
      name="groupIds"
      countryCode={country}
      required
    />
  );
};

const StoreMultiFormInput = () => {
  const { t } = useTranslation();
  const { control } = useFlexSalesForm();
  const country = useWatch({ control, name: 'country' });
  return (
    <StoreMultiSelectFormInput
      name="stores"
      countryCode={country}
      label={t('toolbar.labels.store')}
      required
    />
  );
};

const FederativeEntityFormInput = () => {
  const { control } = useFlexSalesForm();
  const country = useWatch({ control, name: 'country' });
  return (
    <FederativeEntitySelectFormInput
      name="federativeEntity"
      countryCode={country}
      required
      fullWidth
    />
  );
};

const ReprocessForm = () => {
  const { t } = useTranslation();
  const resolver = useMemo(() => yupResolver(getSchema()), []);
  const formListener = useListener(
    ReprocessActionType.reprocessCreateRequested,
    ReprocessActionType.reprocessCreateSuccess,
    ReprocessActionType.reprocessCreateFailure
  );
  const navigate = useNavigate();
  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.reprocess.index.path);
  }, [navigate]);

  const onSubmit = async (data: CreateReprocessForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('reprocess.messages.success'), messageOptions);
  };
  return (
    <PermissionsProvider resourcePermissions={resourcePermissions}>
      <FlexSalesForm
        onValidSubmit={onSubmit}
        shouldUnregister
        resolver={resolver}
        defaultValues={initialValues}
      >
        <ResetWhenFieldChanges field="country" fieldToReset="store" />
        <ResetWhenFieldChanges field="country" fieldToReset="stores" />
        <ResetWhenFieldChanges field="country" fieldToReset="groupIds" />
        <Grid container flexDirection="column" rowSpacing={1}>
          <Grid item xs>
            <ReprocessTypeFormInput name="type" fullWidth required />
          </Grid>
          <Grid item xs>
            <CountrySelectFormInput name="country" fullWidth required />
          </Grid>
          <Condition when="type" is={typesWithFederativeEntity}>
            <Grid item xs>
              <FederativeEntityFormInput />
            </Grid>
          </Condition>
          <Condition when="type" is={typesWithStore}>
            <Grid item xs>
              <StoreFormInput />
            </Grid>
          </Condition>
          <Condition when="type" is={typesWithIbGroupsMultiple}>
            <Grid item xs>
              <IbGroupFormInput />
            </Grid>
          </Condition>
          <Condition when="type" is={typesWithStoreMultiple}>
            <Grid item xs>
              <StoreMultiFormInput />
            </Grid>
          </Condition>
          <Condition when="type" is={typesWithDate}>
            <Grid item xs>
              <DatePickerFormInput
                name="businessDate"
                label={t('reprocess.labels.business_date')}
                required
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
          </Condition>
          <Condition when="type" is={typesWithDateRange}>
            <Grid item xs>
              <DatePickerFormInput
                name="businessDateFrom"
                label={t('reprocess.labels.since')}
                minDate={minDate}
                required
              />
            </Grid>
            <Grid item xs>
              <DatePickerFormInput
                name="businessDateTo"
                label={t('reprocess.labels.until')}
                required
                maxDate={maxDate}
              />
            </Grid>
          </Condition>
          <Grid item xs>
            <TextFieldFormInput
              name="justification"
              variant="standard"
              label={t('reprocess.labels.justification')}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('reprocess.labels.cancel')}
          submitLabel={t('reprocess.labels.create')}
        />
      </FlexSalesForm>
    </PermissionsProvider>
  );
};

export default ReprocessForm;
