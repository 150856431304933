import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { nameToInitials } from '../helpers';
import { userInfoSelector } from '../state';

const Container = styled.div`
  text-align: center;
`;

const AvatarStyled = styled(Avatar)`
  width: 130px;
  height: 130px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  font-size: xxx-large;
  margin: auto;
`;

const TypographyUser = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const UserAppInfo = () => {
  const { username, name } = useSelector(userInfoSelector) || { name: '', username: '' };
  const initials = nameToInitials(name);
  return (
    <Container>
      <AvatarStyled>{initials}</AvatarStyled>
      <Typography variant="h6">{name}</Typography>
      <TypographyUser variant="body2">{username}</TypographyUser>
      <TypographyUser variant="body2">{`Flex Ventas v${process.env.VERSION}`}</TypographyUser>
    </Container>
  );
};

export default UserAppInfo;
