import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useMuiPickersLocale } from '../../hooks';
import { DataGridHandler } from './DataGridHandler';

interface DataGridFlexSalesContextType<TData = unknown, TContext = unknown> {
  handler: DataGridHandler<TData, TContext>;
}

const initialState: DataGridFlexSalesContextType = {
  handler: new DataGridHandler(Object(), Object()),
};

const DataGridFlexSalesContext = createContext<DataGridFlexSalesContextType>(initialState);

export interface Props<TData, TContext> {
  handlerFactory: (locale: Locale) => DataGridHandler<TData, TContext>;
  children: ReactNode;
}

const DataGridFlexSalesProvider = <TData, TContext>({
  handlerFactory,
  ...others
}: Props<TData, TContext>) => {
  const locale = useMuiPickersLocale();
  const value = useMemo(
    () => ({ handler: handlerFactory(locale) as DataGridHandler<unknown, unknown> }),
    [handlerFactory, locale]
  );

  return <DataGridFlexSalesContext.Provider value={value} {...others} />;
};

export const useDataGridFlexSales = <TData, TContext>() => {
  const context = useContext(DataGridFlexSalesContext);

  if (context === undefined)
    throw new Error(
      'useDeprecatedDataGridFlexSales debe estar dentro de DataGridFlexSalesContext.Provider'
    );

  return context as DataGridFlexSalesContextType<TData, TContext>;
};

export default DataGridFlexSalesProvider;
