import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { exportsDownloadingSelector } from '../state';

const sx: SxProps<Theme> = { color: '#fff', zIndex: theme => theme.zIndex.drawer + 10 };

const DownloadOverlay = () => {
  const loading = useSelector(exportsDownloadingSelector);
  return (
    <Backdrop sx={sx} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default DownloadOverlay;
