import { Action } from 'redux';
import {
  HttpErrorBaseAction,
  RemoteResource,
  MonthClosingFilter,
  MonthClosing,
  CloseMonthForm,
  AnnulMonthClosingForm,
} from '../../types';

export interface State {
  filters: MonthClosingFilter;
  monthClosing: RemoteResource<MonthClosing[]>;
  annul: MonthClosing | undefined;
}

export enum ActionType {
  monthClosingChangeFilterCountry = 'monthClosing/changeFilterCountry',
  monthClosingFindRequested = 'monthClosing/findRequested',
  monthClosingFindSuccess = 'monthClosing/findSuccess',
  monthClosingFindFailure = 'monthClosing/findFailure',
  monthClosingCloseRequested = 'monthClosing/closeRequested',
  monthClosingCloseSuccess = 'monthClosing/closeSuccess',
  monthClosingCloseFailure = 'monthClosing/closeFailure',
  monthClosingChangeAnnul = 'monthClosing/changeAnnul',
  monthClosingAnnulRequested = 'monthClosing/annulRequested',
  monthClosingAnnulSuccess = 'monthClosing/annulSuccess',
  monthClosingAnnulFailure = 'monthClosing/annulFailure',
}

export interface MonthClosingChangeFilterCountryAction
  extends Action<ActionType.monthClosingChangeFilterCountry> {
  country: string;
}

export type MonthClosingFindRequestedAction = Action<ActionType.monthClosingFindRequested>;
export type MonthClosingFindSuccessAction = Action<ActionType.monthClosingFindSuccess> & {
  monthClosing: MonthClosing[];
};
export type MonthClosingFindFailureAction = HttpErrorBaseAction<ActionType.monthClosingFindFailure>;

export type MonthClosingCloseRequestedAction = Action<ActionType.monthClosingCloseRequested> & {
  payload: CloseMonthForm;
};
export type MonthClosingCloseSuccessAction = Action<ActionType.monthClosingCloseSuccess>;
export type MonthClosingCloseFailureAction =
  HttpErrorBaseAction<ActionType.monthClosingCloseFailure>;

export interface MonthClosingChangeAnnulAction extends Action<ActionType.monthClosingChangeAnnul> {
  monthClosing: MonthClosing | undefined;
}

export type MonthClosingAnnulRequestedAction = Action<ActionType.monthClosingAnnulRequested> & {
  payload: AnnulMonthClosingForm;
};
export type MonthClosingAnnulSuccessAction = Action<ActionType.monthClosingAnnulSuccess>;
export type MonthClosingAnnulFailureAction =
  HttpErrorBaseAction<ActionType.monthClosingAnnulFailure>;

export type Actions =
  | MonthClosingChangeFilterCountryAction
  | MonthClosingFindRequestedAction
  | MonthClosingFindSuccessAction
  | MonthClosingFindFailureAction
  | MonthClosingCloseRequestedAction
  | MonthClosingCloseSuccessAction
  | MonthClosingCloseFailureAction
  | MonthClosingChangeAnnulAction
  | MonthClosingAnnulRequestedAction
  | MonthClosingAnnulSuccessAction
  | MonthClosingAnnulFailureAction;
