import styled from '@emotion/styled';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  VirtualTableProps,
} from '@devexpress/dx-react-grid-material-ui';
import { useDataGridFlexSales } from '../DataGridFlexSalesProvider';

/* 
  Estas constantes son necesarias para poder utilizar VirtualTable. 
  Si no matchean al scrollear se va visualizar mal.
  Al cambiar CELL_PADDING hay que ver que valor toma el alto de la fila (para establecer ESTIMATED_ROW_HEIGHT).
  Como ESTIMATED_ROW_HEIGHT toma valores en px, el padding (CellStyled y GridHeaderCell) tambien se estableció en px
*/
const CELL_PADDING = 7;
export const ESTIMATED_ROW_HEIGHT = 35;

export const GridRootStyled = styled(Grid.Root)`
  width: 100%;
  height: 100%;
`;

export const VirtualTableCellStyled = styled(VirtualTable.Cell)`
  padding: 0;
  overflow: visible !important;
  height: ${ESTIMATED_ROW_HEIGHT}px;
  *:first-of-type {
    vertical-align: middle;
  }
`;

const CellDataContainer = styled.div`
  height: ${ESTIMATED_ROW_HEIGHT - 1}px;
  vertical-align: middle;
  padding: ${CELL_PADDING}px;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    max-width: unset;
    overflow: visible;
  }
`;

export const VirtualTableCell: VirtualTableProps['cellComponent'] = ({ column, row, ...rest }) => {
  const { handler } = useDataGridFlexSales();
  const col = handler.getColumnById(column.name);
  return (
    <VirtualTableCellStyled column={column} row={row} {...rest}>
      <CellDataContainer>{col.getCell(row.ORIGINAL_ITEM)}</CellDataContainer>
    </VirtualTableCellStyled>
  );
};

export const TableHeaderRowCell = styled(TableHeaderRow.Cell)`
  padding: ${CELL_PADDING}px;
  color: white;

  span.ResizingControl-sortLabelRoot {
    color: inherit;
    svg.MuiTableSortLabel-icon {
      color: inherit;
    }
  }

  span.ResizingControl-sortLabelRoot:hover {
    color: inherit;
  }
`;
